import React, {Fragment, useState} from 'react';
import {createStyles, makeStyles, Table, TableBody, TableCell, TableRow, Theme, Typography} from "@material-ui/core";
import {BankAccountBalances} from "../../../models/bankTransaction";
import {BankAccountConsumer} from "../../../stores/bankAccount";
import numeral from "numeral";
import {UserStoreConsumer} from "../../../stores/user";
import {MuiLink} from "../../atoms/MuiLink";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            marginTop: theme.spacing(4)
        },
        tableCell: {
            borderBottom: 0
        },
        row: {
            '&:hover' : {
                background: theme.palette.grey["50"],
            }
        },
        accountLink: {
            color: "inherit"
        }
    }),
);

interface Props {
    accountBalances?: BankAccountBalances;
    selectedAccountId?: string;

}

const BankAccountBalanceView = (props: Props) => {

    const classes = useStyles();

    return (
        <UserStoreConsumer>
            {user => <BankAccountConsumer>
                {accounts => <Fragment>
                    {user && user.bankAccountId && (props.accountBalances ? <Fragment>
                        <Typography
                            align={"center"}>{`${accounts.bankAccounts[user.bankAccountId].title} - ${accounts.bankAccounts[user.bankAccountId].id}`}</Typography>
                        <Typography variant={"h4"} align={"center"}>{
                            props.accountBalances[user.bankAccountId].balance ? numeral(props.accountBalances[user.bankAccountId].balance).format("0.00$") : "Nicht verfügbar."
                        }</Typography>
                    </Fragment> : <Typography>Not loaded yet.</Typography>)}
                    <Table size={"small"} className={classes.table}>
                        <TableBody>

                            {props.accountBalances && Object.entries(props.accountBalances)
                                .map(value => <TableRow className={classes.row}>
                                        <TableCell className={classes.tableCell}><MuiLink className={classes.accountLink} to={`/banking/${value[0]}`}>{value[1].accountName}</MuiLink></TableCell>
                                        <TableCell className={classes.tableCell}>{value[1].externalId}</TableCell>
                                        <TableCell className={classes.tableCell}>{value[1].balance ? numeral(value[1].balance).format("0.00$") : "n/a"}</TableCell>
                                </TableRow>)}

                        </TableBody>
                    </Table>
                </Fragment>}
            </BankAccountConsumer>}
        </UserStoreConsumer>
    );
};

export default BankAccountBalanceView;