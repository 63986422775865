export function debounce(fn : any, delay: number) {
    var timer : any = null;
    return function() {
            let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
            fn(args);
        }, delay);
    };
}
