import React, {ReactNode, useMemo} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles, TextField, Theme} from "@material-ui/core";
import BankTransactionAllocationTable, {BankTransactionAllocationTableProps} from "./bankTransactionAllocationTable";
import InvoiceDataTable from "../invoiceContentArea/invoiceDataTable";
import numeral from "numeral";
import {taxAmountFromGrossAmount} from "../../../util/taxFunctions";
import {sum} from "ramda";
import {Simulate} from "react-dom/test-utils";


export interface BankTransactionContentAreaProps extends BankTransactionAllocationTableProps{
    transactionReference: string;
    valueDate: string;
    iban: string;
    bic: string;
    amount: number;
    notice: string;
    children?: ReactNode;
    bankTransactionNoticeModifiedCallback: (newValue: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        padding: theme.spacing()
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    table: {
        minWidth: 700,
    },
    fileSelect: {
        paddingLeft: "14px",
        paddingRight: "25px",
        height: "44px"
    },
    fileSelectIcon: {
        color: "rgba(0, 0, 0, 0.54)",
        cursor: "pointer"
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    }
}));




const BankTransactionContentArea = (props : BankTransactionContentAreaProps) => {

    const classes = useStyles();

    const summedAmount = sum(props.accountingTransactions.map(at => at.amount));
    const transactionDifference = Math.abs(props.amount) - summedAmount;



    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={1} className={classes.grid} justify={"space-between"}>
                <Grid item xs={12}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        value={props.transactionReference}
                        label={"Referenz"}
                        variant="outlined"
                        placeholder="Titel"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Werstellungsdatum"
                        type="date"
                        variant="outlined"
                        value={props.valueDate}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        value={props.iban}
                        label={"IBAN"}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        value={props.bic}
                        label={"BIC"}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        value={numeral(props.amount).format("0.00$")}
                        label={"Betrag"}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <BankTransactionAllocationTable
                        accountingTransactionAddedCallback={props.accountingTransactionAddedCallback}
                        accountingTransactions={props.accountingTransactions}
                        accountingTransactionModifiedCallback={props.accountingTransactionModifiedCallback}
                        accountingTransactionRemovedCallback={props.accountingTransactionRemovedCallback}
                    />
                </Grid>

                <Grid container justify={"flex-end"}>

                    {Math.abs(transactionDifference) >= 0.0001 && <Grid item>
                        <TextField
                            label="Betragsdifferenz"
                            className={classes.textField}
                            value={numeral(transactionDifference).format("0.00$")}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                                inputProps: { tabIndex: -1 }
                            }}
                            variant="standard"
                        />
                    </Grid>}

                    <Grid item>
                        <TextField
                            label="enthaltene MwSt."
                            className={classes.textField}
                            margin="normal"
                            value={numeral(props.accountingTransactions.map(item => taxAmountFromGrossAmount(item.amount, item.taxRate)).reduce((a, b) => a + b, 0)).format("0.00$")}
                            InputProps={{
                                readOnly: true,
                                inputProps: { tabIndex: -1 }
                            }}
                            variant="filled"
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Gesamtbetrag"
                            className={classes.textField}
                            value={numeral(sum(props.accountingTransactions.map(item => item.amount))).format("0.00$")}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                                inputProps: { tabIndex: -1 }
                            }}
                            variant="filled"
                        />
                    </Grid>
                    {props.children}
                </Grid>
                <Grid item>
                    <TextField
                        label="Notiz"
                        placeholder={"Notiz"}
                        className={classes.textField}
                        value={props.notice}
                        onChange={e => props.bankTransactionNoticeModifiedCallback(e.target.value)}
                        InputProps={{
                            readOnly: false,
                            inputProps: { tabIndex: -1 }
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default React.memo(BankTransactionContentArea);