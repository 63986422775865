import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {IconButton, InputAdornment, makeStyles, PropTypes, TextField, Theme} from "@material-ui/core";
import {BookingAccountConsumer} from "../../../stores/bookingAccount";
import isNonEmptyString from "../../../util/isNonEmptyString";
import {Edit} from "@material-ui/icons";
import {BookingAccount} from "../../../models/masterData";

interface Props {
    bookingAccountId?: string;
    bookingAccountIdChangedCallback?: (id?: string ) => void;
    emptyText?: string;


    readonly? : boolean;
    variant?: any;
    fullWidth?: boolean;
    label?: string;
    margin?: PropTypes.Margin;
    disabled?: boolean;
    className?: string;
    inputRef?: any;
}

interface InternalProps extends Props {
    bookingAccounts: {
        [id: string]: BookingAccount
    };
    openModal?: (func?: (id: string) => void) => void;
}

const BookingAccountInternal = (props : InternalProps) => {

    useEffect(() => {
        if (!props.readonly && props.bookingAccountIdChangedCallback === undefined) {
            console.error("You did not pass a prop called bookingAccountIdChangedCallback when in editable mode.");
        }
    }, [props.readonly, props.bookingAccountIdChangedCallback]);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [tempValue, setTempValue] = useState<string>("");
    const [error, setError] = useState<boolean>(false);


    const handleChange = useCallback((e) => {
        setEditMode(true);
        setTempValue(e.target.value);
    }, []);


    const handleBlur = useCallback(() => {
        if(editMode) {
            let account = Object.entries(props.bookingAccounts).find(value => value[1].id === tempValue);

            setError(false);
            setEditMode(false);

            if(account) {

                if(props.bookingAccountIdChangedCallback)
                    props.bookingAccountIdChangedCallback(account[0]);
            }


        }

    }, [props.bookingAccounts, props.bookingAccountIdChangedCallback, tempValue, setError, editMode]);

    const handleKeyPress = useCallback((e) => {
        if(e.key === 'Enter') {
            if(isNonEmptyString(tempValue)) {
                let account = Object.entries(props.bookingAccounts).find(value => value[1].id === tempValue);

                if(account) {
                    setError(false);
                    setEditMode(false);
                    if(props.bookingAccountIdChangedCallback)
                        props.bookingAccountIdChangedCallback(account[0]);
                } else {
                    setError(true);
                }
            } else {
                if(props.bookingAccountIdChangedCallback) {
                    props.bookingAccountIdChangedCallback(undefined)
                }
            }
        }
    }, [props.bookingAccounts, props.bookingAccountIdChangedCallback, tempValue, setError, editMode]);


    return (
        <Fragment>
            <TextField
                fullWidth={props.fullWidth}
                inputRef={props.inputRef}
                className={props.className}
                disabled={props.disabled}
                variant={props.variant}
                label={props.label}
                margin={props.margin}
                onChange={props.readonly ? undefined : handleChange}
                onKeyPress={props.readonly ? undefined : handleKeyPress}
                onBlur={props.readonly ? undefined : handleBlur}
                error={error}
                value={editMode ? tempValue : (props.bookingAccountId ? (props.bookingAccounts[(props.bookingAccountId)] ? props.bookingAccounts[(props.bookingAccountId)].title : props.bookingAccountId) : (props.emptyText ? props.emptyText : "Keine Kostenart gewählt"))}
                InputProps={{
                    startAdornment: isNonEmptyString(props.bookingAccountId)?<InputAdornment
                        //@ts-ignore
                        position="start">{(props.bookingAccounts[(props.bookingAccountId)] ? props.bookingAccounts[(props.bookingAccountId)].externalId : "")}</InputAdornment> :undefined,
                    endAdornment: props.readonly ? undefined : <InputAdornment position={"end"}>
                        <IconButton aria-label="Kostenart ändern" tabIndex={-1} onClick={() => (props.openModal)? props.openModal(props.bookingAccountIdChangedCallback) : {}}>
                            <Edit/>
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </Fragment>
    );
};

const BookingAccountField = (props : Props) => <BookingAccountConsumer>
    {({bookingAccounts, openModal}) => <BookingAccountInternal
        bookingAccounts={bookingAccounts}
        openModal={openModal}
        {...props}/>}
</BookingAccountConsumer>

export default BookingAccountField;