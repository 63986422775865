import React, {useCallback, useEffect, useState} from 'react';
import {BankAccountConsumer} from "../../../../../stores/bankAccount";
import BankTransactionDetailsTemplate from "../../../../../components/templates/bankTransactionDetails";
import {useAPI} from "../../../../../hooks/useAPI";
import {BankTransaction} from "../../../../../models/bankTransaction";
import {BankingApi} from "../../../../../api/banking";
import {BankTransactionAccountingTransaction} from "../../../../../models/accountingTransaction";
import {listWithoutItemWithId} from "../../../../../util/listWithoutItemWithId";
import generateUuid from "../../../../../util/generateUuid";
import {findIndex, propEq, sum} from "ramda";
import isNonEmptyString from "../../../../../util/isNonEmptyString";
import { NotificationConsumer } from '../../../../../components/molecules/notification';
import { Notification } from '../../../../../components/molecules/notification/types';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props {
    accountId: string;
    transactionId: string;
}

interface PropsInternal extends Props {
    addNotification: (notification: Notification) => void;
}

const TransactionIdSceneInternal = (props: PropsInternal & RouteComponentProps) => {

    const [bankTransaction, loading, error, retry] = useAPI<BankTransaction>(BankingApi.getTransactionDetails, props.transactionId);
    const [accountingTransactions, setAccountingTransactions] = useState<BankTransactionAccountingTransaction[]>([]);
    const [notice, setNotice] = useState<string>("");

    const [isEdited, setIsEdited] = useState(false);


    //Whenever the BankTransaction Changes, we want to reset the accounting transactions and the edit state.
    useEffect(() => {
        if (bankTransaction !== undefined) {
            if (bankTransaction.bankTransactionStatus === "IMPORTED") {
                setAccountingTransactions([createNewTransactionAllocation(bankTransaction)]);
            } else {
                setAccountingTransactions(bankTransaction.transactions);
                setNotice(bankTransaction.notice);
            }
        } else {
            setAccountingTransactions([]);
        }

        setIsEdited(false);

    }, [bankTransaction, setAccountingTransactions]);

    const removeAccountingTransaction = useCallback((id: string) => {
        setAccountingTransactions(listWithoutItemWithId(id, accountingTransactions));
        setIsEdited(true);
    }, [accountingTransactions, setAccountingTransactions]);

    const addAccountingTransaction = useCallback(() => {
        setAccountingTransactions([...accountingTransactions, createNewTransactionAllocation()])
        setIsEdited(true);
    }, [accountingTransactions, setAccountingTransactions]);

    const modifyAccountingTransaction = useCallback((accountingTransactionId, field, newValue) => {
        setAccountingTransactions([...modifyFieldInTransaction(accountingTransactionId, field, newValue, accountingTransactions)]);
        setIsEdited(true);
    }, [accountingTransactions, setAccountingTransactions]);

     const modifyBankTransactionNotice = useCallback((newValue: string) => {
             setNotice(newValue);
             setIsEdited(true);
         }, [notice, setNotice]);

    const saveAllocations = useCallback(() => {
        BankingApi.updateAccountingTransactions(props.transactionId, accountingTransactions)
            .then(() => {
                props.addNotification({
                    type: "SUCCESS",
                    title: "Erfolgreich verbucht."
                });
                if(bankTransaction !== undefined) {
                    BankingApi.updateBankTransaction(props.transactionId, {...bankTransaction!, notice: notice, transactions: []})
                        .then(() => {
                            props.addNotification({
                                type: "SUCCESS",
                                title: "Erfolgreich verbucht."
                            });
                            // go to previous location if possible
                            // @ts-ignore
                            if (props.location.state && props.location.state.prevPath) {
                                props.history.goBack();
                            }
                        })
                        .catch(() => props.addNotification({
                            type: "ERROR",
                            title: "Fehler bei der Buchung der Banktransaktion."
                        }))
                        .finally(() => setIsEdited(false));
                }
                // go to previous location if possible
                // @ts-ignore
                else if(props.location.state && props.location.state.prevPath) {
                    props.history.goBack();
                }
            })
            .catch(() => props.addNotification({
                type: "ERROR",
                title: "Fehler bei der Buchung der Positionen der Banktransaktion."
            }))
            .finally(() => setIsEdited(false));
    }, [props.transactionId, accountingTransactions, notice]);

    const amount = (bankTransaction && bankTransaction.amount) || 0;
    const iban = (bankTransaction && bankTransaction.fromIban) || "";
    const bic = (bankTransaction && bankTransaction.fromBic) || "";
    const valueDate = (bankTransaction && bankTransaction.valueDate) || "";
    const reference = (bankTransaction && bankTransaction.reference) || "";


    return (
        <BankAccountConsumer>
            {({bankAccounts}) => <BankTransactionDetailsTemplate
                bankAccountId={props.accountId}
                bankAccount={bankAccounts[props.accountId]}
                bankTransactionId={props.transactionId}
                isEdited={isEdited}
                transactionDetailsLoading={loading}
                transactionDetailsFetchError={error}
                bankTransaction={bankTransaction}
                amount={amount}
                iban={iban}
                bic={bic}
                notice={notice}
                valueDate={valueDate}
                transactionReference={reference}
                accountingTransactions={accountingTransactions}
                accountingTransactionAddedCallback={addAccountingTransaction}
                accountingTransactionModifiedCallback={modifyAccountingTransaction}
                accountingTransactionRemovedCallback={removeAccountingTransaction}
                canSaveAllocations={checkTransactionAllocationConsistency(amount, accountingTransactions)}
                bankTransactionNoticeModifiedCallback={modifyBankTransactionNotice}
                saveAllocationsCallback={saveAllocations}
            />}
        </BankAccountConsumer>
    );
};

function createNewTransactionAllocation(bankTransaction?: BankTransaction): BankTransactionAccountingTransaction {
    return {
        id: generateUuid(),
        title: bankTransaction ? bankTransaction.reference : "Position",
        amount: bankTransaction ? Math.abs(bankTransaction.amount) : 0,
        taxRate: "A"
    }
}

function modifyFieldInTransaction(id: string, field: string, newValue: any, items: BankTransactionAccountingTransaction[]): BankTransactionAccountingTransaction[] {

    const itemIdx = findIndex(propEq('id', id), items);

    if (itemIdx !== -1) {

        // @ts-ignore
        items[itemIdx][field] = newValue;

        return items;
    } else {
        console.error(`could not find element with id ${id} in array:`, items);
        return items;
    }
}

function checkTransactionAllocationConsistency(amount: number, allocations: BankTransactionAccountingTransaction[]) {

    for (const allocation of allocations) {
        if (!isNonEmptyString(allocation.title) || allocation.contraAccountId === undefined || allocation.costCenterId === undefined) {
            return false;
        } else if (Math.abs(sum(allocations.map(at => at.amount)) - Math.abs(amount)) >= 0.0001) {
            return false;
        }
    }

    return true;
}

const TransactionIdScene = withRouter((props: Props & RouteComponentProps) => <NotificationConsumer>
    {({addNotification}) => <TransactionIdSceneInternal accountId={props.accountId}
        transactionId={props.transactionId} addNotification={addNotification}
        location={props.location} history={props.history} match={props.match} />}
</NotificationConsumer>);

export default TransactionIdScene;