import axios from "axios";
import {adalApiFetch} from "../adalConfig";
import {DatatableOptions} from "../models/datatableOptions";

type MasterDataBankAccountColumns = "TITLE" | "EXTERNAL_ID";
type MasterDataCostCenterColumns = "TITLE" | "EXTERNAL_ID";
type MasterDataBookingAccountColumns = "TITLE" | "EXTERNAL_ID";
export const MasterDataApi = {

    getPaginatedBankAccounts: async (datatableOptions: DatatableOptions<MasterDataBankAccountColumns>) => {
        let res : any = await adalApiFetch(axios, `/api/md/bankaccount/`, {params: {
                page: datatableOptions.page,
                size: datatableOptions.size
            }});
        return res.data;
    },

    getPaginatedCostCenters: async (datatableOptions: DatatableOptions<MasterDataCostCenterColumns>) => {
        let res : any = await adalApiFetch(axios, `/api/md/costcenter/`, {params: {
                page: datatableOptions.page,
                size: datatableOptions.size
            }});
        return res.data;
    },

    getPaginatedBookingAccounts: async (datatableOptions: DatatableOptions<MasterDataBookingAccountColumns>) => {
        let res : any = await adalApiFetch(axios, `/api/md/bookingaccount/`, {params: {
                page: datatableOptions.page,
                size: datatableOptions.size
            }});
        return res.data;
    }
};