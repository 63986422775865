import {ColumnVisibilityProps} from "./types";
import {TableFooter, TablePagination, TableRow, TableCell} from "@material-ui/core";
import React from "react";
import numeral from "numeral";


/**
 * InvoiceTableFooter
 */
interface InvoiceTableFooterProps extends ColumnVisibilityProps {
    totalElements: number;
    page: number;
    pageChangedCallback: (newPage: number) => void;
    rowsPerPage: number;
    rowsPerPageChangedCallback: (newRowsPerPage: number) => void;
    totalAmount: number;
    totalTax: number;
}

const InvoiceTableFooter = (props: InvoiceTableFooterProps) => {

    return <TableFooter>
        <TableRow>
            <TableCell>Insgesamt: { numeral(props.totalAmount).format("0.00$") }, davon { numeral(props.totalTax).format("0.00$") } MwSt.</TableCell>
            <TablePagination
                colSpan={props.visibleColumns.length + 1}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                count={props.totalElements}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onPageChange={(event1, page) => props.pageChangedCallback(page)}
                onChangeRowsPerPage={(event => props.rowsPerPageChangedCallback(parseInt(event.target.value)))}
            />
        </TableRow>
    </TableFooter>
};

export default InvoiceTableFooter;