//Workaround for Typescript
import React, {Component, Fragment} from 'react';
import {LinkProps} from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {Link} from "@material-ui/core";
import { LocationDescriptor } from 'history';

interface LinkButtonProps extends LinkProps {
    to: string | LocationDescriptor;
    replace?: boolean;
}
export const MuiLink = (props: LinkButtonProps) => (
    <Link {...props} component={RouterLink as any} />
)