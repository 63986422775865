import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {InputAdornment, TableBody, TextField, Theme, makeStyles} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import createStyles from "@material-ui/core/styles/createStyles";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import BookingAccount from "../../molecules/bookingAccountField";
import TaxRate from "../../atoms/taxRate";
import TaxComponent from "../../atoms/taxField";
import FormattedInput from "../../atoms/formattedInput";
import {InvoiceAccountingTransaction} from "../../../models/accountingTransaction";
import CostCenterSelection from "../../molecules/costCenterField";
import NumberInput from '../../atoms/NumberInput';
import BadgeField from "../../molecules/badgeField";

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        //minWidth: 700,
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    validAddButton: {
        cursor: "pointer"
    },
    invalidAddButton: {
      color: theme.palette.grey.A100
    }
}));

export interface InvoiceDataTableProps {
    accountingTransactions: InvoiceAccountingTransaction[];
    accountingTransactionAddedCallback: () => void;
    accountingTransactionRemovedCallback: (accountingTransactionId: string) => void;
    accountingTransactionModifiedCallback: (accountingTransactionId: string, field : string, newValue: any) => void;
}

const DEFAULT_TITLE = "";
const DEFAULT_TAX_RATE = "A";

const InvoiceDataTable = (props: InvoiceDataTableProps) => {

    const classes = useStyles();

    return (
        <Table className={classes.table} size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell>Kostenart (Sollkonto)</TableCell>
                    <TableCell>Bruttobetrag</TableCell>
                    <TableCell>Steuersatz</TableCell>
                    <TableCell>enthaltene MwSt.</TableCell>
                    <TableCell>Kostenstellenzuweisung</TableCell>
                    <TableCell>Plaketten</TableCell>
                    <TableCell onClick={props.accountingTransactionAddedCallback}><AddCircleOutlineIcon/></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.accountingTransactions.map(item =>
                    <TableRow>
                        <TableCell><Input value={item.title} onChange={(e) => props.accountingTransactionModifiedCallback(item.id, "title", e.target.value)}/></TableCell>
                        <TableCell><BookingAccount bookingAccountId={item.contraAccountId} bookingAccountIdChangedCallback={(newBookingAccountId) => props.accountingTransactionModifiedCallback(item.id, "contraAccountId", newBookingAccountId)} /></TableCell>
                        
                        <TableCell><NumberInput loading={item.amount <= 0} defaultValue={item.amount} error={!validateAmount(item.amount)}
                                valueChangedCallback={(newValue => props.accountingTransactionModifiedCallback(item.id, "amount", newValue))}>

                            {({getInternalProps}) => <TextField
                                {...getInternalProps()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                            />}
                        </NumberInput></TableCell>

                        <TableCell><TaxRate taxRate={item.taxRate} taxRateChangedCallback={(newTaxRate) => props.accountingTransactionModifiedCallback(item.id, "taxRate", newTaxRate)}/></TableCell>
                        <TableCell><TaxComponent taxRate={item.taxRate}
                                                 grossAmount={item.amount}>
                            {({getInternalProps}) => <TextField {...getInternalProps()} inputProps={{readOnly: true}}/>}
                        </TaxComponent></TableCell>
                        <TableCell><CostCenterSelection costCenterId={item.costCenterId} costCenterIdChangedCallback={(newCostCenterId) => props.accountingTransactionModifiedCallback(item.id, "costCenterId", newCostCenterId)} /></TableCell>
                        <TableCell>
                            <BadgeField
                                selectedBadges={item.badges}
                                badgesChangedCallback={ (newBadges) => {
                                    props.accountingTransactionModifiedCallback(item.id, "badges", newBadges)
                                }}
                            />
                        </TableCell>
                        <TableCell><span style={{cursor: "pointer"}} onClick={() => props.accountingTransactionRemovedCallback(item.id)}><RemoveCircleOutlineIcon/></span></TableCell>
                    </TableRow>)}
            </TableBody>
        </Table>
    );
};

function validateAmount(amount: number) {
    return (!isNaN(amount));
}

export default InvoiceDataTable;