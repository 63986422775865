import React, {ReactElement} from 'react';
import BreadCrumbItem from './breadCrumbItem';
import {dropLast, last} from 'ramda';
import {createStyles, Theme, WithStyles, Breadcrumbs} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme : Theme) => createStyles({
    breadCrumb: {
        //paddingBottom: "1em"
    }
});

interface Props extends WithStyles<typeof styles> {
    children: ReactElement<BreadCrumbItem> | ReactElement<BreadCrumbItem>[],
}



const BreadCrumb = (props: Props) => {

    let itemArray;
    if(!Array.isArray(props.children)) {
        itemArray = [props.children]
    } else {
        itemArray = props.children;
    }

    return (
        <Breadcrumbs aria-label="Breadcrumb" className={props.classes.breadCrumb}>
            {dropLast(1, itemArray)}
            {React.cloneElement(itemArray[itemArray.length-1] as React.ReactElement<any>, {current: true})}
        </Breadcrumbs>
    );
};

export default withStyles(styles)(BreadCrumb);