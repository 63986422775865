import React, {Fragment} from 'react';
import {createStyles, makeStyles, Theme, Typography} from "@material-ui/core";
import {BookingAccountConsumer} from "../../../stores/bookingAccount";


interface Props {
    bookingAccountId?: string;
}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        externalId: {
            color: theme.palette.grey["600"]
        }
    }),
);


const BookingAccountLabel = (props: Props) => {

    const classes = useStyles();
    return (
        <BookingAccountConsumer>
            {({bookingAccounts}) => (props.bookingAccountId ? <Fragment>
                {bookingAccounts[props.bookingAccountId] ? <Typography><span className={classes.externalId}>{bookingAccounts[props.bookingAccountId].id}</span>&nbsp;&nbsp;{bookingAccounts[props.bookingAccountId].title}</Typography> : <Typography>Keine Infos zu Buchungskonto</Typography>}
            </Fragment> : <Typography>Kein Buchungskonto</Typography>)}
        </BookingAccountConsumer>
    );
};

export default BookingAccountLabel;