import {AuthenticationContext, adalFetch, AdalConfig} from 'react-adal';
import {CLIENT_ID, REDIRECT_URI, TENANT} from "./environment";


export const adalConfig: AdalConfig = {
    tenant: TENANT,
    clientId: CLIENT_ID,
    endpoints: {
        api: CLIENT_ID,
        graph: "https://graph.microsoft.com"
    },
    cacheLocation: 'localStorage',
    redirectUri: REDIRECT_URI
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch: any, url: any, options: any) =>
    adalFetch(authContext, CLIENT_ID, fetch, url, options);

export const graphFetch = (fetch: any, url: any, options: any) =>
    adalFetch(authContext, "https://graph.microsoft.com",fetch, url, options);

export function authenticateToken() {
    if (authContext.getCachedUser()) {
        // If we have a cached login, use it
        return true
    }

    if (authContext.isCallback(window.location.hash)) {
        // This happens after the AD login screen,
        // handleWindowCallback will use the hash to
        // complete the login
        authContext.handleWindowCallback()
        return true
    }

    // Not logged in
    return false
}
