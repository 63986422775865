export type Tax = "A" | "A_CORONA" | "B" | "B_CORONA" | "C" | "D" | "E" | "F" | "G" | "H" | "Z";
export const taxRates : {[key in Tax] : {key: string, label: string, title: string, rate: number, active: boolean}} = {
    "A" : {
        key: "A",
        label: "19%",
        title: "Voller Steuersatz",
        rate: 0.19,
        active: true
    },
    "A_CORONA" : {
        key: "A_CORONA",
        label: "16%",
        title: "Voller Steuersatz 01.07.2020 bis 31.12.2020",
        rate: 0.16,
        active: true
    },
    "B" : {
        key: "B",
        label: "7%",
        title: "Ermäßigter Steuersatz",
        rate: 0.07,
        active: true
    },
    "B_CORONA" : {
        key: "B_CORONA",
        label: "5%",
        title: "Ermäßigter Steuersatz 01.07.2020 bis 31.12.2020",
        rate: 0.05,
        active: true
    },
    "C" : {
        key: "C",
        label: "16%",
        title: "Voller Steuersatz Vorjahr",
        rate: 0.16,
        active: false
    },
    "D": {
        key: "D",
        label: "7%",
        title: "ermäßigter Steuersatz bei innergemeinschaftlichem Erwerb",
        rate: 0.07,
        active: false
    },
    "E" : {
        key: "E",
        label: "19%",
        title: "Voller Steuersatz bei innergemeinschaftlichem Erwerb",
        rate: 0.19,
        active: false
    },
    "F" : {
        key: "F",
        label: "16%",
        title: "Voller Steuersatz Vorjahr bei innergemeinschaftlichem Erwerb",
        rate: 0.19,
        active: false
    },
    "G" : {
        key: "G",
        label: "9,5%",
        title: "Hälfte des vollen Steuersatzes",
        rate: 0.095,
        active: false
    },
    "H" : {
        key: "H",
        label: "9%",
        title: "Freier Steuersatz",
        rate: 0.09,
        active: false
    },
    "Z" : {
        key: "Z",
        label: "0%",
        title: "Keine Berechnung der Vor- bzw. Umsatzsteuer",
        rate: 0,
        active: true
    },
};
export const taxRateArray = Object.values(taxRates);