import React, {Component, Fragment, ReactNode} from 'react';
import {BrowserRouter, Route, Switch, RouteComponentProps} from "react-router-dom";
import HomeScene from "./scenes/index";
import InvoiceScene from "./scenes/invoice";
import NewInvoiceScene from "./scenes/invoice/new/index";
import EditInvoiceScene from "./scenes/invoice/edit/index";
import BankingScene from "./scenes/banking/index";
import BankingDetailsScene from "./scenes/banking/_accountId";
import BankingBatchScene from "./scenes/banking/_accountId/batch";
import DataTransferScene from "./scenes/datatransfer";
import MasterDataScene from "./scenes/masterdata";
import ExportScene from './scenes/datatransfer/export';
import ImportScene from './scenes/datatransfer/import';
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCaretDown, faCaretRight} from '@fortawesome/free-solid-svg-icons'
import {createMuiTheme, Theme, Typography, WithStyles, withStyles} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles"
import CssBaseline from '@material-ui/core/CssBaseline';
import createStyles from "@material-ui/core/styles/createStyles";
import {blue} from "@material-ui/core/colors";
import "numeral/locales/de";
import numeral from "numeral";
import {CostCenterConsumer, CostCenterProvider} from "./stores/costCenter";
import {UserStoreConsumer, UserStoreProvider} from "./stores/user";
import {BookingAccountConsumer, BookingAccountProvider} from "./stores/bookingAccount";
import {BankAccountConsumer, BankAccountProvider} from "./stores/bankAccount";
import {BadgeConsumer, BadgeProvider} from "./stores/badge";
//@ts-ignore
import {pdfjs} from "react-pdf";
import {PartnerConsumer, PartnerProvider} from "./stores/partner";
import {FetchState} from "./models/fetchState";
import LoadingBaseData from "./components/organisms/loadingBaseData";
import NavbarV3 from "./components/organisms/navbarv3/navbarV3";
import TransactionIdScene from "./scenes/banking/_accountId/transaction/_transactionId";
import { NotificationProvider } from './components/molecules/notification';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


numeral.locale("de");
library.add(faCaretRight, faCaretDown);

const theme = createMuiTheme({
    typography: {
        fontSize: 12,
        //fontFamily: '"Open Sans", "Arial"'
    },
    palette: {
        primary: {
            light: "#63a4ff",
            main: "#1976d2",
            dark: "#004ba0",
        },
        secondary: {
            light: blue[300],
            main: blue[500],
            dark: blue[700],
        },

    },
});

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        height: '100vh',
        overflow: 'auto',
    },
});

interface Props extends WithStyles<typeof styles> {

}

interface BankingDetailMatchParams {
    bankAccountId: string;
}
interface BankingDetailMatchProps extends RouteComponentProps<BankingDetailMatchParams> {

}

interface BankingTransactionIdMatchParams {
    bankAccountId: string;
    transactionId: string;
}
interface BankingTransactionIdMatchProps extends RouteComponentProps<BankingTransactionIdMatchParams> {

}


interface InvoiceMatchParams {
    invoiceId: string;
}
interface InvoiceMatchProps extends RouteComponentProps<InvoiceMatchParams> {
    
}

class App extends Component<Props> {

    render() {

        const {classes} = this.props;

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <NotificationProvider>
                    <div className={classes.root}>
                        <BrowserRouter>
                            <BaseDataProvider fetched={<Fragment>
                                <NavbarV3/>
                                <main className={classes.content}>
                                    <div className={classes.appBarSpacer}/>
                                    <Switch>
                                        <Route exact path="/" component={() => <HomeScene/>}/>
                                        <Route exact path="/invoice" component={() => <InvoiceScene/>}/>
                                        <Route exact path="/invoice/new" component={() => <NewInvoiceScene/>}/>
                                        <Route exact path="/invoice/edit/:invoiceId"
                                                component={({match}: InvoiceMatchProps) => <EditInvoiceScene
                                                    invoiceId={match.params.invoiceId}/>}/>
                                        <Route exact path="/banking" component={() => <BankingScene/>}/>
                                        <Route exact path="/banking/:bankAccountId"
                                            component={({match}: BankingDetailMatchProps) => <BankingDetailsScene
                                                accountId={match.params.bankAccountId}/>}/>
                                        <Route exact path="/banking/:bankAccountId/transaction/:transactionId"
                                            component={({match}: BankingTransactionIdMatchProps) => <TransactionIdScene
                                                accountId={match.params.bankAccountId}
                                                transactionId={match.params.transactionId}/>}/>
                                        <Route exact path="/banking/:bankAccountId/batch"
                                            component={({match}: BankingDetailMatchProps) => <BankingBatchScene
                                                accountId={match.params.bankAccountId}/>}/>
                                        <Route exact path="/datatransfer" component={() => <DataTransferScene/>}/>
                                        <Route exact path="/datatransfer/export" component={() => <ExportScene/>}/>
                                        <Route exact path="/datatransfer/import" component={() => <ImportScene/>}/>
                                        <Route exact path="/masterdata" component={() => <MasterDataScene/>}/>
                                    </Switch>
                                </main>
                            </Fragment>} loading={<LoadingBaseData />}/>
                        </BrowserRouter>
                    </div>
                </NotificationProvider>
            </ThemeProvider>
        );
    }
}


function allDataFetched(states: { [id: string]: FetchState }) {
    return Object.values(states).filter(state => state !== 'READY').length === 0;
}

interface BaseDataProviderProps {
    loading: ReactNode,
    fetched: ReactNode
}

const BaseDataProvider = (props: BaseDataProviderProps) => <UserStoreProvider>
    <PartnerProvider>
    <BankAccountProvider>
    <BookingAccountProvider>
    <CostCenterProvider>
    <UserStoreProvider>
    <BadgeProvider>
        <BadgeConsumer>
            {({state: badgeState}) => <UserStoreConsumer>
            {({state: userState}) => <BankAccountConsumer>
            {({state: accountStoreState}) => <BookingAccountConsumer key={accountStoreState}>
            {({state: bookingAccountState}) => <CostCenterConsumer key={bookingAccountState}>
            {({state: costCenterState}) => <PartnerConsumer>
                {({state: partnerState}) => allDataFetched({
                    userState,
                    partnerState,
                    accountStoreState,
                    bookingAccountState,
                    costCenterState,
                    badgeState
                }) ? props.fetched : props.loading}
            </PartnerConsumer>}
            </CostCenterConsumer>}
            </BookingAccountConsumer>}
            </BankAccountConsumer>}
            </UserStoreConsumer>}
        </BadgeConsumer>
    </BadgeProvider>
    </UserStoreProvider>
    </CostCenterProvider>
    </BookingAccountProvider>
    </BankAccountProvider>
    </PartnerProvider>
</UserStoreProvider>

export default withStyles(styles)(App);
