import React, {Component, Fragment} from 'react';

interface ColumnProps {
    identifier?: string,
    title?: string,
    children?: (renderParams:{
                    cellData: any,
                    rowData: {
                        [key: string] : any
                    },
                    expanded?: boolean,
                    expansionCallback?: (expanded : boolean) => void
                }) => React.ReactElement<any, any>,
}

class Column extends Component<ColumnProps> {
    render() {
        return (
            <Fragment />
        );
    }
}

export default Column;
