import React, {Component, Fragment} from 'react';
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme, WithStyles, withStyles, Paper, Typography, IconButton } from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from "react-router-dom";

import BreadCrumb from "../../components/molecules/breadCrumb";
import BreadCrumbItem from "../../components/molecules/breadCrumb/breadCrumbItem";

import TabsArea from "../../components/organisms/tabsArea/tabsArea";
import Area from "../../components/organisms/tabsArea/area";

import Datatable from "../../components/organisms/datatable/datatablev2";
import Column from "../../components/organisms/datatable/column";

import { MasterDataApi } from '../../api/masterdata';

const styles = (theme: Theme) => createStyles({
    paper: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(),
        paddingTop: theme.spacing()    
    },

    link: {
        width: theme.spacing(),
        height: theme.spacing(),
    }
});

interface Props extends WithStyles<typeof styles> {
}

interface State {
}

class Index extends Component<Props, State> {


    render() {

        const {classes} = this.props;

        return (
            <Fragment>
                <main className="section">
                    <div className="container">
                        <BreadCrumb>
                            <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                            <BreadCrumbItem to={"/masterdata"} title={"Stammdaten"}/>
                        </BreadCrumb>

                        <Paper className={classes.paper}>
                            <TabsArea>
                                <Area label="Bankkonten">
                                    <Datatable endpoint={MasterDataApi.getPaginatedBankAccounts}>
                                        <Column />
                                        <Column identifier="id" title="Kontonummer" />
                                        <Column identifier="title" title="Titel"></Column>
                                        <Column>
                                        {
                                            (renderParams) => <Link to={"/banking/"+renderParams.rowData.id}>
                                                <IconButton color="primary" className={classes.link}>
                                                    <ArrowRightIcon />
                                                </IconButton>                                                        
                                            </Link>
                                        }
                                        </Column>
                                    </Datatable>
                                </Area>
                                <Area label="Kostenstellen">
                                    <Datatable endpoint={MasterDataApi.getPaginatedCostCenters}>
                                        <Column />
                                        <Column identifier="id" title="Kostenstellen-Nummer" />
                                        <Column identifier="title" title="Titel" />
                                    </Datatable>
                                </Area>
                                <Area label="Buchungskonten">
                                    <Datatable endpoint={MasterDataApi.getPaginatedBookingAccounts}>
                                        <Column />
                                        <Column identifier="id" title="Buchungskonto-Nummer" />
                                        <Column identifier="title" title="Titel" />
                                    </Datatable>
                                </Area>
                            </TabsArea>
                        </Paper> 
                    </div>
                </main>
            </Fragment>
        );
    }
}

export default withStyles(styles)(Index);