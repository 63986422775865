import React, {ReactElement, useEffect, useState} from 'react';
import Column from "./column";
import {DatatableOptions, DatatableResponse} from "../../../models/datatableOptions";
import Datatablev2View from "./datatablev2View";


interface Props {
    children: ReactElement<Column> | ReactElement<Column>[];
    endpoint: (options: DatatableOptions<any>) => Promise<DatatableResponse<object>>;
}

const Datatablev2 = (props: Props) => {

    //Request Params
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);

    //Datatable state
    const [loading, setLoading] = useState(false);

    //Response Values
    const [elements, setElements] = useState<{ [id: string]: object }>({});
    const [totalElements, setTotalElements] = useState(0);
    const [error, setError] = useState<object | undefined>(undefined);

    useEffect(() => {

        setLoading(true);
        setError(undefined);

        props.endpoint({page: page, size: size})
            .then(dtResponse => {
                setElements(dtResponse.elements);
                setTotalElements(dtResponse.totalElements);
                setLoading(false);
            })
            .catch(reason => {
                setLoading(false);
                setError(reason);
            });

    }, [page, size, props.endpoint]);

    return (
        <Datatablev2View
            loading={loading}
            elements={elements}
            size={size}
            page={page}
            pageChangedCallback={setPage}
            sizeChangedCallback={setSize}
            totalElements={totalElements}
            error={error}
        >
            {props.children}
        </Datatablev2View>
    );
};

export default Datatablev2;