import React, { Fragment, FormEvent } from 'react';
import { SizeMe } from 'react-sizeme'
import { Document, Page } from 'react-pdf';

import {
    Grid,
    IconButton,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import { ZoomOut, ZoomIn } from '@material-ui/icons';

import FormattedInput from '../../atoms/formattedInput';
import Pagination from '../pagination';
import { Doc } from '../../../models/doc';
import { CSSProperties } from '@material-ui/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        documentSelectWrapper: {
            width: '100%',
            height: '50px',
            textAlign: 'center'
        },
        documentSelect: {
            width: '300px',
            margin: '5px 10px'
        },
        documentWrapper: {
            height: '50vh',
            width: '100%',
            overflow: 'auto',
            marginTop: '10px'
        },
        paginationWrapper: {
            height: '50px',
            marginTop: '10px'
        },
        zoomInput: {
            width: '80px',
            margin: '5px 10px'
        }
    })
);

export interface DocumentViewProps {
    documents: Doc[];
    shownDocumentIndex?: number;
    shownDocumentIndexChanged?: (value: number) => void;
}

interface Props extends DocumentViewProps {
    fileRoot?: string;
    documentWidth?:  number;
    documentWrapperStyle?: CSSProperties;
    zoom?: number;
    zoomChanged?: (value: number) => void;
}

const DocumentView = (props: Props) => {

    const classes = useStyles();
    const { fileRoot, documents, documentWidth, documentWrapperStyle } = props;
    const finalFileRoot = fileRoot ? fileRoot : '/api/document/file/';

    const [documentIndex, setDocumentIndex] = React.useState(0);

    const onCurrentFileChanged = (event: any) => {

        if(event.target.value !== null) {
            setDocumentIndex(event.target.value);
        }
    };

    const [totalPages, setTotalPages] = React.useState(1);
    const [currentPage, setCurrentPage] = React.useState(0);

    const [zoom, setZoom] = React.useState(1);

    const decrementCurrentZoom = () => {
        const finalZoom = zoom <= 0.1 ? 0.1 : zoom - 0.1;
        setZoom(finalZoom);
    };

    const incrementCurrentZoom = () => {
        const finalZoom = zoom >= 2.4 ? 2.5 : zoom + 0.1;
        setZoom(finalZoom);
    };

    return (
        <Fragment>
            <div className={classes.documentSelectWrapper}>
                <Select className={classes.documentSelect} onChange={onCurrentFileChanged} value={documentIndex}>
                    {documents.map((document, index) =>
                        <MenuItem value={index}>{document.originalFilename}</MenuItem>
                    )}
                </Select>
            </div>
            <div className={classes.documentWrapper} style={documentWrapperStyle}>
                <SizeMe>
                    {({ size }) => {
                        const finalWidth = documentWidth ? documentWidth : size.width;
                        return finalWidth && (
                            <Document file={finalFileRoot + documents[documentIndex].id}
                                      onLoadError={console.log}
                                      onLoadSuccess={({ numPages }) => setTotalPages(numPages)}>

                                <Page pageNumber={currentPage + 1} width={finalWidth * zoom} />
                            </Document>
                        );
                    }}
                </SizeMe>
            </div>

            <Grid container direction="row" justify="space-evenly" alignItems="center"
                  className={classes.paginationWrapper}>
                <Grid item>
                    <IconButton onClick={decrementCurrentZoom}><ZoomOut /></IconButton>
                    <FormattedInput
                        valueChangedCallback={value => setZoom(Math.round(+value) / 100)}
                        defaultValue={`${Math.round(zoom * 100)}`}
                        validator={value => (!isNaN(+value) && +value > 0 && +value < 250 )}>

                        {({getInternalProps}) => <TextField className={classes.zoomInput}
                                                            {...getInternalProps()}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                            }}
                        />}
                    </FormattedInput>
                    <IconButton onClick={incrementCurrentZoom}><ZoomIn /></IconButton>
                </Grid>
                <Grid item>
                    <Pagination currentPage={currentPage} totalPages={totalPages} pageSpan={2}
                                pageChangeCallback={setCurrentPage} />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default DocumentView;
