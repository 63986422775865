import React, {Component, Fragment} from 'react';
import createStyles from "@material-ui/core/styles/createStyles";
import InvoiceTable from "../../components/organisms/invoiceTable";
import BreadCrumb from "../../components/molecules/breadCrumb";
import BreadCrumbItem from "../../components/molecules/breadCrumb/breadCrumbItem";
import {Fab, Paper, Theme, WithStyles, withStyles} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";

const styles = (theme: Theme) => createStyles({
    button: {
        margin: theme.spacing(),
    },
    fab: {
        margin: theme.spacing(),
    },
    invoiceTable: {
        marginTop: theme.spacing(1)
    },
    invoiceTablePaper: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(),
        paddingTop: theme.spacing()
    }
});

interface Props extends WithStyles<typeof styles> {

}

interface State {
}

class Index extends Component<Props, State> {



    render() {

        const {classes} = this.props;

        return (
            <Fragment>
                <main className="section">
                    <div className="container">
                        <BreadCrumb>
                            <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                            <BreadCrumbItem to={"/invoice"} title={"Meine Belege"}/>
                        </BreadCrumb>

                        <Paper className={classes.invoiceTablePaper}>
                            <InvoiceTable />
                        </Paper>


                        <Link to="/invoice/new">
                            <Fab color="primary" aria-label="add" className={classes.fab} >
                                <AddIcon />
                            </Fab>
                        </Link>                        
                    </div>
                </main>
            </Fragment>
        );
    }
}

export default withStyles(styles)(Index);