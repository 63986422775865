import React, {Fragment} from 'react';
import {createStyles, makeStyles, Theme, Typography} from "@material-ui/core";
import {PartnerConsumer} from "../../../stores/partner";


interface Props {
    partnerId?: string;
}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        externalId: {
            color: theme.palette.grey["600"]
        }
    }),
);


const PartnerLabel = (props: Props) => {

    const classes = useStyles();
    return (
        <PartnerConsumer>
            {({partners}) => (props.partnerId ? <Fragment>
                {partners[props.partnerId] ? <Typography><span className={classes.externalId}>{partners[props.partnerId].applicantNumber}</span>&nbsp;&nbsp;{partners[props.partnerId].partnerName}</Typography> : <Typography>Keine Infos zu Gesellschafter</Typography>}
            </Fragment> : <Typography>Kein Gesellschafter</Typography>)}
        </PartnerConsumer>
    );
};

export default PartnerLabel;