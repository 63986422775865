
import {Tax, taxRates} from "../models/tax";

export function taxAmountFromGrossAmount(grossAmount: number, taxRate: Tax) {
    // Bruttobetrag / (1+Mwst-Satz) = Nettobetrag
    // Nettobetrag * Mwst-Satz = Mwst-Betrag
    // --> Bruttobetrag / (1+Mwst-Satz) * Mwst-Satz = Mwst-Betrag
    // --> BruttoBetrag * (1-1/(1+Mwst-Satz)) = Mwst-Betrag
    // weil: b/(1+p)*p = bp/(1+p) = (b+bp-b)/(1+p) = (b(1+p)-b)/(1+p) = b(1+p)/(1+p)-b/(1+p) = b-b/(1+p) = b*(1-1/(1+p))

    return grossAmount / (1 + taxRates[taxRate].rate) * taxRates[taxRate].rate;
    // return grossAmount * (1 - 1 / (1 + taxRate));
}