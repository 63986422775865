import React from 'react';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {useDropzone} from "react-dropzone";
import BankAccountInput from "../../molecules/bankAccountFieldV2";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {BankTransactionImportResponse} from "../../../models/bankTransaction";
import * as R from "ramda";


interface Props {

    selectedFiles: { [key: string]: File };
    filesAddedCallback: (newFiles: File[]) => void;
    fileRemovedCallback: (fileId: string) => void;

    selectedAccounts: { [key: string]: string };
    accountSelectionChangedCallback: (selectedAccountId: string, fileKey: string) => void;

    canUploadFiles: boolean;
    importResult: { [key: string]: BankTransactionImportResponse};
}

const BankTransactionImport = (props: Props) => {

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: props.filesAddedCallback
    });


    return (
        <div>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>Datei</TableCell>
                        <TableCell>Zugewiesenes Bankkonto</TableCell>
                        <TableCell>Transaktionen importiert</TableCell>
                        <TableCell>Davon verbucht</TableCell>
                        <TableCell><span {...getRootProps()}><AddIcon/></span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(props.selectedFiles).map(entry =>
                        <TableRow key={entry[0]}>
                            <TableCell>{entry[1].name}</TableCell>
                            <TableCell><BankAccountInput
                                bankAccountId={props.selectedAccounts[entry[0]]}
                                bankAccountIdChangedCallback={(accountId) => props.accountSelectionChangedCallback(accountId, entry[0])}/></TableCell>
                            <TableCell>{!R.isEmpty(props.importResult) && props.importResult[entry[1].name].imported}</TableCell>
                            <TableCell>{!R.isEmpty(props.importResult) && props.importResult[entry[1].name].rulesApplyed}</TableCell>
                            <TableCell><span onClick={() => props.fileRemovedCallback(entry[0])}><RemoveIcon/></span></TableCell>
                        </TableRow>)}

                    {Object.keys(props.selectedFiles).length === 0 && <TableRow>
                        <TableCell align={"center"} colSpan={3}>
                            <Button
                                {...getRootProps()}
                                color={"primary"}
                                variant="contained"
                            >
                                Dateien hinzufügen
                            </Button>
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
            <input {...getInputProps()} />
        </div>
    );
};

export default BankTransactionImport;