import React, {useCallback} from 'react';
import {createStyles, IconButton, makeStyles, TableCell, TableRow, TextField, Theme} from "@material-ui/core";
import {ColumnVisibilityProps} from "./types";
import {Tax} from "../../../models/tax";
import {InvoiceStatus} from "../../../models/invoice";
import {Clear} from "@material-ui/icons";
import TaxRate from "../../atoms/taxRate";
import BookingAccountField from "../../molecules/bookingAccountField";
import CostCenterField from "../../molecules/costCenterField";
import PartnerField from "../../molecules/partnerField";
import {availableColumns} from "./index";


/**
 * Invoice Table Filter Row
 */
const useInvoiceTableFilterRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
        },
    }),
);

interface BankTransactionFilterRowProps extends ColumnVisibilityProps {

    filterTitle: string;
    setFitlerTitle: (newFilterTitle: string) => void;

    filterDate: undefined | [string] | [string, string];
    setFilterDate: (newDate: (undefined | [string] | [string, string])) => void;

    filterAmount: undefined | [string] | [string, string];
    setFilterAmount: (newAmount: undefined | [string] | [string, string]) => void;

    filterTaxRate: undefined | Tax;
    setFilterTaxRate: (newTaxRateFilter: undefined | Tax) => void;

    filterCostType: undefined | string;
    setFilterCostType: (newFilterCostType: undefined | string) => void;

    filterCostCenter: undefined | string;
    setFilterCostCenter: (newFilterCostCenter: undefined | string) => void;

    filterPartner: undefined | string;
    setFilterPartner: (newFilterPartner: undefined | string) => void;

    filterIban: string;
    setFilterIban: (newFilterIban: string) => void;

    filterBic: string;
    setFilterBic: (newFilterBic: string) => void;

    filterFromName: string;
    setFilterFromName: (newFilterFromName: string) => void;
}

const BankTransactionFilters = (props : BankTransactionFilterRowProps) => {

    const clearAllFilters = useCallback(() => {
        props.setFitlerTitle("");
        props.setFilterFromName("");
        props.setFilterDate(undefined);
        props.setFilterAmount(undefined);
        props.setFilterTaxRate(undefined);
        props.setFilterCostType(undefined);
        props.setFilterCostCenter(undefined);
        props.setFilterPartner(undefined);
        props.setFilterIban("");
        props.setFilterBic("");
    }, [
        props.setFitlerTitle,
        props.setFilterFromName,
        props.setFilterDate,
        props.setFilterAmount,
        props.setFilterTaxRate,
        props.setFilterCostType,
        props.setFilterCostCenter,
        props.setFilterPartner,
        props.setFilterIban,
        props.setFilterBic
    ]);

    const classes = useInvoiceTableFilterRowStyles();



    return (
        <TableRow>
            {props.visibleColumns.map(columnKey =>
                (TableCellRenderers[columnKey] ? TableCellRenderers[columnKey]({
                    ...props
                }) : <TableCell/>))}
            <TableCell>
                <IconButton aria-label="Aktionen" size="small" style={{padding: 0}} onClick={clearAllFilters}>
                    <Clear/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};


const NilTableCell = () => <TableCell/>;
const TitleFilterCell = (props: BankTransactionFilterRowProps) =>
    <TableCell key={"FILTER_TITLE"}><TextField
        value={props.filterTitle}
        onChange={(e) => props.setFitlerTitle(e.target.value)}
        fullWidth
        margin="dense"
    /></TableCell>;

const FromNameFilterCell = (props: BankTransactionFilterRowProps) =>
    <TableCell key={"FILTER_FROM_NAME"}><TextField
        value={props.filterFromName}
        onChange={(e) => props.setFilterFromName(e.target.value)}
        fullWidth
        margin="dense"
    /></TableCell>;

const TaxRateFilterCell = (props: BankTransactionFilterRowProps) => <TableCell>
    <TaxRate
        variant={"standard"}
        emptyAllowed
        emptyTitle={"Alle"}
        taxRateChangedCallback={props.setFilterTaxRate}
        taxRate={props.filterTaxRate}
    />
</TableCell>;

const CostTypeFilterCell = (props: BankTransactionFilterRowProps) => <TableCell>
    <BookingAccountField
        bookingAccountId={props.filterCostType}
        bookingAccountIdChangedCallback={props.setFilterCostType}
        emptyText={"Alle"}
        fullWidth
    />
</TableCell>;

const CostCenterFilterCell = (props: BankTransactionFilterRowProps) => <TableCell>
    <CostCenterField
        costCenterId={props.filterCostCenter}
        costCenterIdChangedCallback={props.setFilterCostCenter}
        emptyText={"Alle"}
        fullWidth
    />
</TableCell>;

const PartnerFilterCell = (props: BankTransactionFilterRowProps) => <TableCell>
    <PartnerField
        partnerId={props.filterPartner}
        partnerIdChangedCallback={props.setFilterPartner}
        emptyText={"Alle"}
        fullWidth
    />
</TableCell>;


const TableCellRenderers: { [id in availableColumns]: any } = {
    "NAME": TitleFilterCell,
    "FROM_NAME": FromNameFilterCell,
    "DATE": NilTableCell,
    "AMOUNT": NilTableCell,
    "TAX_RATE": TaxRateFilterCell,
    "TAX": NilTableCell,
    "COST_TYPE": CostTypeFilterCell,
    "COST_CENTER": CostCenterFilterCell,
    "PARTNER": PartnerFilterCell,
    "STATUS": NilTableCell,
    FROM_IBAN : NilTableCell,
    FROM_BIC : NilTableCell,
    "NOTICE": NilTableCell
};


export default React.memo(BankTransactionFilters);