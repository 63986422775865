import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {IconButton, InputAdornment, PropTypes, TextField} from "@material-ui/core";
import {BankAccountConsumer} from "../../../stores/bankAccount";
import isNonEmptyString from "../../../util/isNonEmptyString";
import {Edit} from "@material-ui/icons";
import {BankAccount} from "../../../models/masterData";

interface Props {
    bankAccountId?: string;
    bankAccountIdChangedCallback?: (id: string) => void;
    readonly? : boolean;
    variant?: any;

    label?: string;
    margin?: PropTypes.Margin;
    disabled?: boolean;
    className?: string;
    inputRef?: any;
}

interface InternalProps extends Props {
    bankAccounts: {
        [id: string]: BankAccount
    };
    openModal?: (func?: (id: string) => void) => void;
}

const BankAccountInternal = (props : InternalProps) => {

    useEffect(() => {
        if (!props.readonly && props.bankAccountIdChangedCallback === undefined) {
            console.error("You did not pass a prop called bankAccountIdChangedCallback when in editable mode.");
        }
    }, [props.readonly, props.bankAccountIdChangedCallback]);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [tempValue, setTempValue] = useState<string>("");
    const [error, setError] = useState<boolean>(false);


    const handleChange = useCallback((e) => {
        setEditMode(true);
        setTempValue(e.target.value);
    }, []);


    const handleBlur = useCallback(() => {
        if(editMode) {
            let account = Object.entries(props.bankAccounts).find(value => value[1].id === tempValue);

            setError(false);
            setEditMode(false);

            if(account) {

                if(props.bankAccountIdChangedCallback)
                    props.bankAccountIdChangedCallback(account[0]);
            }


        }

    }, [props.bankAccounts, props.bankAccountIdChangedCallback, tempValue, setError, editMode]);

    const handleKeyPress = useCallback((e) => {
        if(e.key === 'Enter') {
            let account = Object.entries(props.bankAccounts).find(value => value[1].id === tempValue);

            if(account) {
                setError(false);
                setEditMode(false);
                if(props.bankAccountIdChangedCallback)
                    props.bankAccountIdChangedCallback(account[0]);
            } else {
                setError(true);
            }
        }
    }, [props.bankAccounts, props.bankAccountIdChangedCallback, tempValue, setError, editMode]);


    return (
        <Fragment>
            <TextField
                inputRef={props.inputRef}
                className={props.className}
                disabled={props.disabled}
                variant={props.variant}
                label={props.label}
                margin={props.margin}
                onChange={props.readonly ? undefined : handleChange}
                onKeyPress={props.readonly ? undefined : handleKeyPress}
                onBlur={props.readonly ? undefined : handleBlur}
                error={error}
                value={editMode ? tempValue : (props.bankAccountId ? (props.bankAccounts[(props.bankAccountId)] ? props.bankAccounts[(props.bankAccountId)].title : props.bankAccountId) : "Kein Konto ausgewählt")}
                InputProps={{
                    startAdornment: isNonEmptyString(props.bankAccountId)?<InputAdornment
                        //@ts-ignore
                        position="start">{(props.bankAccounts[(props.bankAccountId)] ? props.bankAccounts[(props.bankAccountId)].externalId : "")}</InputAdornment> :undefined,
                    endAdornment: props.readonly ? undefined : <InputAdornment position={"end"}>
                        <IconButton aria-label="Konto ändern" tabIndex={-1} onClick={() => (props.openModal)? props.openModal(props.bankAccountIdChangedCallback) : {}}>
                            <Edit/>
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </Fragment>
    );
};

const BankAccountField = (props : Props) => <BankAccountConsumer>
    {({bankAccounts, openModal}) => <BankAccountInternal
        bankAccounts={bankAccounts}
        openModal={openModal}
        {...props}/>}
</BankAccountConsumer>

export default BankAccountField;