import React from 'react';
import {TableFooter, TablePagination, TableRow} from "@material-ui/core";
import {ColumnVisibilityProps} from "./types";

/**
 * BankTransactionTableFooter
 */
interface BankTransactionTableFooter extends ColumnVisibilityProps {
    totalElements: number;
    page: number;
    pageChangedCallback: (newPage: number) => void;
    rowsPerPage: number;
    rowsPerPageChangedCallback: (newRowsPerPage: number) => void;
}


const BankTransactionTableFooter = (props : BankTransactionTableFooter) => {
    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    colSpan={props.visibleColumns.length + 1}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    count={props.totalElements}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onPageChange={(event1, page) => props.pageChangedCallback(page)}
                    onChangeRowsPerPage={(event => props.rowsPerPageChangedCallback(parseInt(event.target.value)))}
                />
            </TableRow>
        </TableFooter>
    );
};

export default BankTransactionTableFooter;