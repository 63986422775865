import React, {Fragment} from 'react';
import BreadCrumbItem from "../../../molecules/breadCrumb/breadCrumbItem";
import BreadCrumb from "../../../molecules/breadCrumb";
import {createStyles, Grid, IconButton, makeStyles, Paper, Theme, Button} from "@material-ui/core";
import InvoiceContentArea, { InvoiceContentAreaProps } from '../../../organisms/invoiceContentArea';
import Document, { DocumentViewProps } from '../../../molecules/document';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import { grey } from '@material-ui/core/colors';
import clsx from "clsx";


interface Props extends  InvoiceContentAreaProps, DocumentViewProps {    
    docVisible: boolean;
    toggleDocVisible: () => void;
    canSubmitInvoice: boolean;
    invoiceSubmitCallback: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        iconButton: {
            margin: theme.spacing(0),
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        fab: {
            margin: theme.spacing(1),
        },
        button: {
            margin: theme.spacing(3),
        },
        greyBackground: {
            backgroundColor: grey["100"],
        }
    }),
);

const Index = (props: Props) => {

    const classes = useStyles();

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={11}>
                            <BreadCrumb>
                                <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                                <BreadCrumbItem to={"/invoice"} title={"Meine Belege"}/>
                                <BreadCrumbItem to={"/invoice/edit"} current title={props.title}/>
                            </BreadCrumb>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                color={props.docVisible ? "primary" : "default"}
                                aria-label="Delete" className={classes.iconButton} size="medium"
                                onClick={props.toggleDocVisible}>
                                <InsertDriveFile fontSize="inherit"/>
                            </IconButton>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12} sm={props.docVisible ? 6 : 12}>
                    <Paper className={classes.paper}>
                        <InvoiceContentArea
                            title={props.title}
                            titleChangedCallback={props.titleChangedCallback}
                            documents={props.documents}
                            fileChangedCallback={props.fileChangedCallback}
                            documentRemovedCallback={props.documentRemovedCallback}
                            date={props.date}
                            invoiceDateChangedCallback={props.invoiceDateChangedCallback}
                            invoiceNumber={props.invoiceNumber}
                            invoiceNumberChangedCallback={props.invoiceNumberChangedCallback}
                            partnerId={props.partnerId}
                            partnerIdChangedCallback={props.partnerIdChangedCallback}
                            accountingTransactions={props.accountingTransactions}
                            accountingTransactionModifiedCallback={props.accountingTransactionModifiedCallback}
                            accountingTransactionRemovedCallback={props.accountingTransactionRemovedCallback}
                            accountingTransactionAddedCallback={props.accountingTransactionAddedCallback}>
                                
                                <Grid item>
                                    <Button variant="contained"
                                        color={props.canSubmitInvoice ? "primary" : undefined}
                                        className={classes.button}
                                        onClick={props.canSubmitInvoice ? props.invoiceSubmitCallback : undefined}>
                                            Übernehmen
                                    </Button>
                                </Grid>      
                            </InvoiceContentArea>
                    </Paper>
                </Grid>
                {props.docVisible && <Grid item xs={12} sm={6}>
                    <Paper className={clsx(classes.paper, classes.greyBackground)}>
                        {props.documents.length 
                                ? <Document documents={props.documents} 
                                        shownDocumentIndex={props.shownDocumentIndex}
                                        shownDocumentIndexChanged={props.shownDocumentIndexChanged} /> 
                                : <h1>Document Not Loaded</h1>}
                    </Paper>
                </Grid>}
            </Grid>
        </Fragment>
    );
};

export default Index;