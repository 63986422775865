import React from 'react';
import {adalApiFetch} from "../../adalConfig";
import axios from "axios";


type State = {
    state: "INIT" | "READY" | "ERROR";
    userId: string,
    email: string,
    givenName: string,
    familyName: string,
    bankAccountId?: string;
    partnerId?: string;
    defaultCostCenterId?: string;
}

type Props = {
    children: any;
    fetchMethod?: any;
}

const initialState : State = {
    state: "INIT",
    userId: "...",
    email: "...",
    givenName: "...",
    familyName: "..."
}

const UserStoreContext = React.createContext<State>(initialState);

class UserStoreProvider extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state= initialState;

        console.log("initialising provider for Users.")

        let fetchMethod;

        if(props.fetchMethod) {
            fetchMethod = props.fetchMethod
        } else {
            fetchMethod = adalApiFetch(axios, "/api/user/info", {});
        }

        fetchMethod
            .then(({data} : any) => this.setState({...data, state: "READY"}))
            .catch((error : any) => this.setState({state: "ERROR"}));
    }

    render() {
        return (
            <UserStoreContext.Provider value={this.state}>
                {this.props.children}
            </UserStoreContext.Provider>
        );
    }
}

const UserStoreConsumer = UserStoreContext.Consumer;

export {UserStoreProvider, UserStoreConsumer};