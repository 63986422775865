import React from 'react';
import {adalApiFetch} from "../../adalConfig";
import axios from "axios";
import {FetchState} from "../../models/fetchState";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    TextField
} from "@material-ui/core";
import {FixedSizeList} from "react-window";
import {debounce} from "../../util/debounce";
import isNonEmptyString from "../../util/isNonEmptyString";
import {BankAccount, BookingAccount} from "../../models/masterData";

interface ContextData {
    state: FetchState;
    bankAccounts: {
        [id: string]: BankAccount
    };
    openModal?: (newCallback?: (id: string) => void) => void;
    newBankAccountCallback?: (id: string) => void;

}

interface State extends ContextData{
    modalOpen: boolean;
    searchValue: string;
    bankAccountIdArray: string[];
    filteredBankAccountIdArray: string[];
}

interface Props {
    children: any;
    fetchMethod?: any;
}

const initialContextData : ContextData = {bankAccounts: {}, state: "INIT"};

const BankAccountStoreContext = React.createContext<ContextData>(initialContextData);

class BankAccountProvider extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state= {
            ...initialContextData,
            openModal: this.openModal,
            searchValue: "",
            bankAccountIdArray: [],
            filteredBankAccountIdArray: [],
            modalOpen: false
        };

        let fetchMethod;

        if(props.fetchMethod) {
            fetchMethod = props.fetchMethod
        } else {
            fetchMethod = adalApiFetch(axios, "/api/md/bankaccount/all", {});
        }

        fetchMethod
            .then(({data} : any) => this.setState({
                bankAccounts : data,
                state: "READY",
                bankAccountIdArray: Object.keys(data)
            }))
            .catch((error : any) => this.setState({state: "ERROR"}));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevState.searchValue !== this.state.searchValue) {
            this.recalculateSearchArray();
        }
    }

    recalculateSearchArray = debounce(() => {
        if (isNonEmptyString(this.state.searchValue)) {

            const trimmedValue = this.state.searchValue.trim();

            let filteredIdArray = Object.values(this.state.bankAccounts)
                .filter((bankAccount: BookingAccount) => {
                    if (bankAccount.id === trimmedValue) {
                        return true;
                    } else if (bankAccount.title.includes(trimmedValue)) {
                        return true;
                    }
                })
                .map((object) => object.id);

            this.setState({filteredBankAccountIdArray: filteredIdArray});
        }
    }, 500);

    openModal = (callback?: (id: string) => void) => {
        this.setState({modalOpen: true, newBankAccountCallback: callback})
    }

    closeModal = () => {
        this.setState({modalOpen: false})
    }

    render() {

        const idArray = isNonEmptyString(this.state.searchValue) ? this.state.filteredBankAccountIdArray : this.state.bankAccountIdArray;

        return (
            <BankAccountStoreContext.Provider value={this.state}>
                {this.props.children}
                <Dialog onClose={this.closeModal} aria-labelledby="customized-dialog-title" open={this.state.modalOpen}>
                    <DialogTitle id="customized-dialog-title">
                        Bankkonto auswählen
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            fullWidth
                            value={this.state.searchValue}
                            onChange={(e) => this.setState({searchValue: e.target.value})}
                            margin="normal"
                        />

                        <FixedSizeList height={400} width={520} itemSize={46}
                                       itemCount={idArray.length}>
                            {({style, index}) => (<ListItem button
                                                            style={style}
                                                            key={index}
                                                            onClick={() => {
                                                                if (this.state.newBankAccountCallback) {
                                                                    this.state.newBankAccountCallback(idArray[index])
                                                                }
                                                                this.closeModal();
                                                            }}>
                                <ListItemText
                                    secondary={this.state.bankAccounts[(idArray[index])] ? this.state.bankAccounts[(idArray[index])].id : "---"}
                                    primary={this.state.bankAccounts[(idArray[index])] ? this.state.bankAccounts[(idArray[index])].title : "Nicht vorhanden"}/>
                            </ListItem>)}
                        </FixedSizeList>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeModal} color="default">
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </BankAccountStoreContext.Provider>
        );
    }
}

const BankAccountConsumer = BankAccountStoreContext.Consumer;

export {BankAccountProvider, BankAccountConsumer};
