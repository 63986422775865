import React from 'react';
import FormattedInput from '../formattedInput';

interface RenderProps {
    getInternalProps: () => object;
}

interface Props {
    loading?: boolean,
    defaultValue?: number;
    value?: number;
    error?: boolean;
    valueChangedCallback: (newValue: number) => void;
    children: (renderProps : RenderProps) => any;
};

const NumberInput = (props: Props) => {

    const internalDefaultValue = props.defaultValue !== undefined ? `${props.defaultValue}` : "0";
    const [internalValue, setInternalValue] = React.useState<string>(internalDefaultValue)

    const validateNumber = (newValue: string) => {

        return newValue.search('^-?\\d*[,.]?\\d*$') === 0;
    };

    const changeNumber = (newValue: string) => {

        if(setInternalValue) {
            setInternalValue(newValue);
        }

        const valueAsNumber: number = +newValue.replace(',', '.');
        props.valueChangedCallback(valueAsNumber);
    };

    return (
        <FormattedInput error={props.error} validator={validateNumber} valueChangedCallback={changeNumber}
                        defaultValue={props.value !== undefined ? `${props.value}` : internalValue}>
            {props.children}
        </FormattedInput>
    );
};

export default NumberInput;
