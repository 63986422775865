import React from 'react';
import {withRouter} from "react-router";
import {createStyles, Grid, makeStyles, Theme} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ReportingIcon from "@material-ui/icons/BarChart";
import AcccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DescriptionIcon from "@material-ui/icons/Description";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import SettingsIcon from "@material-ui/icons/Settings";
import {MuiLink} from "../components/atoms/MuiLink";
import {REDIRECT_URI} from "../environment";


interface Props {
    history: any
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            padding: theme.spacing(1)
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center"
        },
        icon: {
            fontSize: "400%",
            color: theme.palette.grey.A200
        }
    }),
);

function Index() {

    const classes = useStyles();

    return (
        <Grid container className={classes.grid} spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
                <MuiLink to={"/invoice"}>
                    <Paper className={classes.paper}>
                        <DescriptionIcon className={classes.icon} />
                        <Typography align={"center"} variant="h5">Belegmanagement</Typography>
                        <Typography align={"center"} variant="h6">Fremdbelege buchen</Typography>
                    </Paper>
                </MuiLink>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <MuiLink to="/banking">
                    <Paper className={classes.paper}>
                        <AcccountBalanceIcon className={classes.icon} />
                        <Typography align={"center"} variant="h5">Geschäftskonto</Typography>
                        <Typography align={"center"} variant="h6">Banktransaktionen buchen</Typography>
                    </Paper>
                </MuiLink>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <MuiLink to="/masterdata">
                    <Paper className={classes.paper}>
                        <SettingsIcon className={classes.icon} />
                        <Typography align={"center"} variant="h5">Stammdaten</Typography>
                        <Typography align={"center"} variant="h6">Stammdaten einsehen und pflegen</Typography>
                    </Paper>
                </MuiLink>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <MuiLink to="/datatransfer">
                    <Paper className={classes.paper}>
                        <ImportExportIcon className={classes.icon} />
                        <Typography align={"center"} variant="h5">Datentransfer</Typography>
                        <Typography align={"center"} variant="h6">Daten im- und exportieren</Typography>
                    </Paper>
                </MuiLink>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <a  style={{textDecoration: "none", color:"inherit"}}
                    href={ REDIRECT_URI.indexOf("localhost") === -1
                        ? "https://metabase.rocco.redoak.me"
                        : "/metabase"
                    }
                    target={"_blank"} rel={"noreferrer"}
                >
                    <Paper className={classes.paper}>
                        <ReportingIcon className={classes.icon} />
                        <Typography align={"center"} variant="h5">Auswertungen</Typography>
                        <Typography align={"center"} variant="h6">Kennzahlen und Daten</Typography>
                    </Paper>
                </a>
            </Grid>
        </Grid>
    );
}

// @ts-ignore
export default withRouter(Index);