import React, { ReactNode, useCallback } from 'react';

import { makeStyles, TextField, Theme, MenuItem, ListItemText, Grid, Divider,
    IconButton, ListItemSecondaryAction, Typography} from "@material-ui/core";
import {CloudUpload, Cancel, ArrowDropDown} from '@material-ui/icons';

import numeral from "numeral";
import {sum} from "ramda";

import InvoiceDataTable, {InvoiceDataTableProps} from "./invoiceDataTable";
import { taxAmountFromGrossAmount } from "../../../util/taxFunctions";
import PartnerField from "../../molecules/partnerField";
import { Doc } from '../../../models/doc';

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        padding: theme.spacing()
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    table: {
        minWidth: 700,
    },
    fileTextField: {},
    fileSelect: {
        padding: "0px 14px"
    },
    fileSelectButton: {
        color: "rgba(0, 0, 0, 0.54)"
    },
    fileSelectItem: {
        cursor: "default",
        minWidth: 500
    }
}));


export interface InvoiceContentAreaProps extends InvoiceDataTableProps {
    title: string;
    titleChangedCallback: (newTitle: string) => void;
    documents: Doc[];
    fileChangedCallback: (file: any) => void;
    documentRemovedCallback: (id: string) => void;
    date: string;
    invoiceDateChangedCallback: (newInvoiceDate: string) => void;
    invoiceNumber?: string;
    invoiceNumberChangedCallback: (newInvoiceNumber: string) => void;
    partnerId?: string;
    partnerIdChangedCallback: (newPartnerId?: string) => void;
}

interface Props extends InvoiceContentAreaProps {
    children?: ReactNode;
}

const InvoiceContentArea = (props: Props) => {

    const classes = useStyles();
    const {title, documents, accountingTransactions} = props;

    const titleChangedCallback = useCallback(event => 
            props.titleChangedCallback(event.target.value), [props.titleChangedCallback]);
    const fileChangedCallback = useCallback(event => 
            props.fileChangedCallback(event.currentTarget.files), [props.fileChangedCallback]);

    const fileInputRef = React.createRef<HTMLInputElement>();
    const openFileUpload = useCallback(() => {
        if(fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, [fileInputRef]);

    const fileSelectRenderValue = documents.length > 0
        ? documents.length > 1
            ? documents[0].originalFilename + ", ..."
            : documents[0].originalFilename
        : "Keine Dokumente eingereicht";

    const [fileSelectOpen, setFileSelectOpen] = React.useState<boolean>(false);
    const openFileSelect = () => setFileSelectOpen(documents.length > 1);
    const closeFileSelect = () => setFileSelectOpen(false);

    const documentRemovedCallback = (id: string) => () => {

        if(documents.length === 1) {
            closeFileSelect();
        }
        props.documentRemovedCallback(id)
    };

    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={1} className={classes.grid} justify={"space-between"}>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={titleChangedCallback}
                        value={title}
                        label={"Titel"}
                        variant="outlined"
                        placeholder="Titel"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>

                    <input type="file" accept="application/pdf" multiple style={{display: "none"}}
                            ref={fileInputRef} onChange={fileChangedCallback} />

                    <TextField className={classes.fileTextField}
                        variant="outlined" label="Dokumente" fullWidth
                        select SelectProps={{
                            className: classes.fileSelect,
                            value: 0,
                            open: fileSelectOpen,
                            onOpen: openFileSelect,
                            onClose: closeFileSelect,
                            autoWidth: true,
                            startAdornment: (
                                <IconButton size="medium" className={classes.fileSelectButton}
                                        aria-label="upload" onClick={openFileUpload} tabIndex={-1}>
                                    <CloudUpload />
                                </IconButton>
                            ),
                            renderValue: () => <Typography>{fileSelectRenderValue}</Typography>,
                            inputProps: {
                                tabIndex: -1
                            },
                            IconComponent: documents.length === 1 ? () => null : ArrowDropDown,
                            endAdornment: (
                                documents.length === 1
                                    ? <IconButton onClick={documentRemovedCallback(documents[0].id)}
                                            edge="end" aria-label="delete"><Cancel /></IconButton>
                                    : undefined
                            )
                        }}
                    >
                        {documents.map(doc => <MenuItem className={classes.fileSelectItem}>
                            <ListItemText primary={doc.originalFilename} secondary={doc.filename} />
                            <ListItemSecondaryAction>
                                <IconButton onClick={documentRemovedCallback(doc.id)}
                                    edge="end" aria-label="delete"><Cancel /></IconButton>
                            </ListItemSecondaryAction>
                        </MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Rechnungsdatum"
                        type="date"
                        variant="outlined"
                        value={props.date}
                        onChange={(e) => props.invoiceDateChangedCallback(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id="outlined-uncontrolled"
                        label="Rechnungsnummer"
                        placeholder={"Rechnungsnummer"}
                        variant="outlined"
                        value={props.invoiceNumber}
                        onChange={(e) => props.invoiceNumberChangedCallback(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <PartnerField
                        inputProps={{label: "Bezahlt von", title: "Der Gesellschafter, der diesen Beleg bezahlt hat."}}
                        partnerId={props.partnerId}
                        partnerIdChangedCallback={props.partnerIdChangedCallback}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>


                <Divider variant={"inset"}/>

                <Grid item xs={12}>
                    <InvoiceDataTable
                        accountingTransactions={accountingTransactions}
                        accountingTransactionAddedCallback={props.accountingTransactionAddedCallback}
                        accountingTransactionRemovedCallback={props.accountingTransactionRemovedCallback}
                        accountingTransactionModifiedCallback={props.accountingTransactionModifiedCallback}
                    />
                </Grid>
                <Grid container justify={"flex-end"}>
                    <Grid item>
                        <TextField
                            label="enthaltene MwSt."
                            className={classes.textField}
                            margin="normal"
                            value={numeral(props.accountingTransactions.map(item => taxAmountFromGrossAmount(item.amount, item.taxRate)).reduce((a, b) => a + b, 0)).format("0.00$")}
                            InputProps={{
                                readOnly: true,
                                inputProps: { tabIndex: -1 }
                            }}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Gesamtbetrag"
                            className={classes.textField}
                            value={numeral(sum(props.accountingTransactions.map(item => item.amount))).format("0.00$")}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                                inputProps: { tabIndex: -1 }
                            }}
                            variant="filled"
                        />
                    </Grid>
                    {props.children}
                </Grid>
            </Grid>
        </form>
    );
};

export default InvoiceContentArea;