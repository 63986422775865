import React, {ReactNode, useEffect} from 'react';

interface RenderProps {
    getInternalProps: () => object,
    //currentValue: string,
    //valid: boolean
}

interface Props {
    defaultValue? : string,
    valueChangedCallback: (newValue : any) => void,
    validator: (value: string) => boolean,
    modifier?: (value: string) => any,
    children: (renderProps : RenderProps) => any;
    error?: boolean;
}

/**
 * This component manages an internal input value and a validator. only valid values will be returned to onChange, while the user can enter any value.
 * if a invalid value is passed, the render prop "error" is passed by getInternalProps.
 *
 * @param props
 * @constructor
 */
const Index = (props : Props) => {

    const [internalValue, setInternalValue] = React.useState("");
    useEffect(() => setInternalValue(props.defaultValue ? props.defaultValue : ""), [props.defaultValue]);

    const [valid, setValid] = React.useState(true);

    return props.children({
        getInternalProps: () => ({
            onChange: (value: any) => handleInternalChange(value.target.value, internalValue, setInternalValue, props.validator, props.valueChangedCallback, setValid, props.modifier),
            error: props.error ? (props.error || !valid) : !valid,
            value: internalValue
        })});
    /*return (
        <TextField error={!valid} value={internalValue} onChange={(value) => handleInternalChange(value.target.value, internalValue, setInternalValue, props.validator, props.valueChangedCallback, setValid)}/>
    );*/
};

const handleInternalChange = (newValue: string, internalValue: string, internalValueSetter: (newValue: string) => void, validator: (value: string) => boolean,
        valueChangedCallback: (value: string) => void, setValid: (val: boolean) => void, modifier?: (value: string) => any) => {

    internalValueSetter(newValue);
    const validationResult = validator(newValue);

    if(validationResult) {
        setValid(true);

        const modifiedValue = modifier ? modifier(newValue) : newValue;
        valueChangedCallback(modifiedValue);
    } else {
        setValid(false);
    }

};

export default Index;