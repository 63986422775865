import React from 'react';
import BreadCrumbItem from "../../molecules/breadCrumb/breadCrumbItem";
import BreadCrumb from "../../molecules/breadCrumb";
import {BankAccount} from "../../../models/masterData";
import {Button, createStyles, Fab, makeStyles, Paper, Theme} from "@material-ui/core";
import BankTransactionTable from "../../organisms/bankTransactionTable";
import {Link} from "react-router-dom";
import { BankTransaction } from '../../../models/bankTransaction';


interface Props {
    bankAccountId : string;
    bankAccount: BankAccount;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(),
        },
        fab: {
            margin: theme.spacing(),
        },
        invoiceTable: {
            marginTop: theme.spacing(1)
        },
        invoiceTablePaper: {
            width: '100%',
            overflowX: 'auto',
            marginTop: theme.spacing(),
            paddingTop: theme.spacing()
        }
    }),
);


const Index = (props : Props) => {

    const classes = useStyles();

    return (
        <div>
            <BreadCrumb>
                <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                <BreadCrumbItem to={"/banking"} title={"Bankkonten"}/>
                <BreadCrumbItem to={`/banking/${props.bankAccountId}`}
                                title={props.bankAccount ? props.bankAccount.title : props.bankAccountId}/>
            </BreadCrumb>

            <Paper className={classes.invoiceTablePaper}>
                <BankTransactionTable 
                    accountId={props.bankAccountId} />
            </Paper>

            <Link to={`/banking/${props.bankAccountId}/batch`}>
                <Button variant="contained" color="primary" className={classes.button}>
                    Stapelbuchung
                </Button>
            </Link>
        </div>
    );
};

export default Index;