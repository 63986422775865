import React from 'react';
import {CircularProgress, createStyles, Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import {AxiosError} from "axios";

const responseStatusText : {[idx : number] : string} = {
    400 : "400 - Fehler bei Anfrage"
}



interface Props {
    error?: AxiosError;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            textAlign: "center"
        },
        progress: {
            margin: theme.spacing(2),
        },
    }),
);


const NotFound = (props : Props) => {

    const classes = useStyles();

    const responseStatus =
        props.error &&
        props.error.response &&
        props.error.response.status;

    return (
        <Grid className={classes.gridContainer} container direction={"column"}>
            <Grid item><CircularProgress size={50} className={classes.progress}/></Grid>
            <Grid item><Typography variant={"h5"}>{responseStatus ? responseStatusText[responseStatus] : "Fehler beim Laden der Daten"}</Typography></Grid>
        </Grid>
    );
};

export default NotFound;