import React, {Component} from 'react';
import {MuiLink} from "../../atoms/MuiLink";
import {Typography} from "@material-ui/core";

interface Props {
    to: string,
    title: string,
    current?: boolean
}

class BreadCrumbItem extends Component<Props> {
    render() {
        return (
            this.props.current ? <Typography color="textPrimary">
                {this.props.title}
            </Typography> : <MuiLink color="inherit" to={this.props.to}>
                {this.props.title}
            </MuiLink>
        );
    }
}

export default BreadCrumbItem;