import axios from "axios";
import {adalApiFetch} from "../adalConfig";
import {DatatableOptions} from "../models/datatableOptions";
import {BankTransaction, OpenTransactionData} from "../models/bankTransaction";
import {Tax} from "../models/tax";
import {toUrlParam} from "../util/encodingUtils";
import {BankTransactionAccountingTransaction} from "../models/accountingTransaction";


export type BankingDatatableColumns = "TITLE" | "FROM_NAME" | "DATUM" | "AMOUNT" | "TAX_RATE" | "COST_TYPE" | "COST_CENTER" | "PARTNER" | "FROM_IBAN" | "FROM_BIC";

export const BankingApi = {
    getTransactionDetails: async (transactionId: string) : Promise<BankTransaction> => {
        let res : any = await adalApiFetch(axios, `/api/banking/transaction/${transactionId}`, {});
        return res.data;
    }
    ,

    allocateBankTransaction: async (transactionId: string, title: string, contraAccountId: string, costCenterId: string, taxRate: Tax, partnerId?: string) => {
        let res = await adalApiFetch(axios, `/api/banking/${transactionId}/allocate`, {params : {
            title, contraAccountId, costCenterId, partnerId, taxRate
            }});
        return res.data;
    },

    getLatestImportsForBankAccounts: async () => {
        let res = await adalApiFetch(axios, `/api/banking/import/latest`, {});
        return res.data;
    },

    getLatestBookingDatesForBankAccounts: async () => {
        let res = await adalApiFetch(axios, `/api/banking/booking/latest`, {});
        return res.data;
    },

    getPaginatedBankTransactions: (accountId: string) => async (datatableOptions: DatatableOptions<BankingDatatableColumns>) => {

        let filterOptions;

        if(datatableOptions.filterOptions) {
            filterOptions= `${datatableOptions.filterOptions.map(option => toUrlParam(option)).join(",")}`
        }


        let res : any = await adalApiFetch(axios, `/api/banking/${accountId}`, {params: {
                page: datatableOptions.page,
                size: datatableOptions.size,
                searchValues: filterOptions,
                sortField: datatableOptions.sortField,
                sortDirection: datatableOptions.sortDirection
            }});
        return res.data;
    },

    getTransactionOpenData: async (accountId: string) : Promise<OpenTransactionData> => {
        let res : any = await adalApiFetch(axios, `/api/banking/${accountId}/open`, {});
        return res.data;
    },

    updateAccountingTransactions: async (transactionId: string, accountingTransactions: BankTransactionAccountingTransaction[]) : Promise<BankTransaction> => {
        let res = await adalApiFetch(axios, `/api/banking/${transactionId}/allocations`, {method: "PUT", data : accountingTransactions});

        return res.data;
    },

    resetBankTransaction: async (transactionId: string) : Promise<BankTransaction> => {
        let res = await adalApiFetch(axios, `/api/banking/${transactionId}/reset`, {});
        
        return res.data;
    },

    updateBankTransaction: async (transactionid: string, bankTransactions: BankTransaction) : Promise<BankTransaction> =>{
        let res = await adalApiFetch(axios, `/api/banking/${transactionid}`,{method: "PUT", data: bankTransactions})

        return res.data;
    },

    getPaginatedBankTransactionImports: async (datatableOptions: DatatableOptions<{}>) => {
        let res : any = await adalApiFetch(axios, `/api/banking/import`, {params: {
                page: datatableOptions.page,
                size: datatableOptions.size
            }});
        return res.data;
    }
};