import React from 'react';
import {CircularProgress, createStyles, Grid, makeStyles, Theme, Typography} from "@material-ui/core";

interface Props {
    loadingText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            textAlign: "center"
        },
        progress: {
            margin: theme.spacing(2),
        },
    }),
);


const SceneLoading = (props : Props) => {

    const classes = useStyles();

    return (
        <Grid className={classes.gridContainer} container direction={"column"}>
            <Grid item><CircularProgress size={50} className={classes.progress}/></Grid>
            <Grid item><Typography variant={"h5"}>{props.loadingText ? props.loadingText : "Daten werden geladen..."}</Typography></Grid>
        </Grid>
    );
};

export default SceneLoading;