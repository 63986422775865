import axios, { AxiosPromise } from "axios";
import {adalApiFetch} from "../adalConfig";
import { ExportStatus, DatevExport } from "../models/datevExport";
import { DatatableOptions } from "../models/datatableOptions";

export const DatevExportApi = {

    getExportStatus: async () : Promise<ExportStatus> => {
        let res: any = await adalApiFetch(axios, `/api/datev`, {});
        return res.data;
    },

    datevExport: async (datevExport: DatevExport) : Promise<ExportStatus> => {
        let res: any = await adalApiFetch(axios, "/api/datev/export", {
            method: "POST",
            data: datevExport
        });
        return res.data;
    },

    getPaginatedDatevExports: async (datatableOptions: DatatableOptions<{}>) => {
        let res : any = await adalApiFetch(axios, `/api/datev/export`, {params: {
                page: datatableOptions.page,
                size: datatableOptions.size
            }});
        return res.data;
    },
};