import { propEq, remove, findIndex } from "ramda";

export function listWithoutItemWithId(itemId: string, items: any[]): any[] {

    const itemIdx = findIndex(propEq('id', itemId), items);

    if(itemIdx !== -1) {
        return remove(itemIdx, 1, items);
    } else {
        console.error(`could not find element with id ${itemId} in array:`, items);
        return items;
    }
}