import React, {Component, Fragment} from 'react';
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme, WithStyles, withStyles, Paper, IconButton } from "@material-ui/core";

import BreadCrumb from "../../../components/molecules/breadCrumb";
import BreadCrumbItem from "../../../components/molecules/breadCrumb/breadCrumbItem";
import Datatable from '../../../components/organisms/datatable/datatablev2';
import { Column } from '../../../components/organisms/datatable';
import { DatevExportApi } from '../../../api/datev';
import { Link } from 'react-router-dom';
import { GetApp, Check } from '@material-ui/icons';
import Moment from 'react-moment';

const styles = (theme: Theme) => createStyles({
    paper: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(),
        paddingTop: theme.spacing()   
    }
});

interface Props extends WithStyles<typeof styles> {
}

interface State {
}

class Index extends Component<Props, State> {

    render() {

        const {classes} = this.props;

        return (
            <Fragment>
                <main className="section">
                    <div className="container">
                        <BreadCrumb>
                            <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                            <BreadCrumbItem to={"/datatransfer"} title={"Datentransfer"}/>
                            <BreadCrumbItem to={"/datatransfer/export"} title={"DATEV-Exports"}/>
                        </BreadCrumb>

                        <Paper className={classes.paper}>
                            <Datatable endpoint={DatevExportApi.getPaginatedDatevExports}>
                                <Column />
                                <Column title="Zeitpunkt" identifier="exportZeitpunkt">
                                    {({cellData}) => <Moment format="DD.MM.YYYY HH:mm">{cellData}</Moment>}
                                </Column>
                                <Column title="Exportiert von" identifier="exportiertVon" />
                                <Column title="Beraternummer" identifier="beraterNummer" />
                                <Column title="Mandantennummer" identifier="mandantenNummer" />
                                <Column title="Beginn" identifier="buchungsstapelBeginn">
                                    {({cellData}) => <Moment format="DD.MM.YYYY">{cellData}</Moment>}
                                </Column>
                                <Column title="Ende" identifier="buchungsstapelEnde">
                                    {({cellData}) => <Moment format="DD.MM.YYYY">{cellData}</Moment>}
                                </Column>
                                <Column title="Komplett?" identifier="fullExport">
                                    {({cellData}) => cellData && <Check />}
                                </Column>
                                <Column title="Download" identifier="id">
                                    {({cellData}) => 
                                    <Link to={`/api/datev/export/file/${cellData}`} target="_blank">
                                        <IconButton size="medium" color="default" aria-label="history">
                                            <GetApp fontSize="inherit" />
                                        </IconButton>
                                    </Link>}
                                </Column>
                                <Column />
                            </Datatable>                                
                        </Paper> 
                    </div>
                </main>
            </Fragment>
        );
    }
}

export default withStyles(styles)(Index);