import React, {ReactElement} from 'react';
import Column from "./column";
import {DatatableState} from "./types";
import {
    CircularProgress,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    WithStyles,
    withStyles,    
    Typography
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";

interface Props extends WithStyles<typeof styles> {
    datatableState : DatatableState,
    children: ReactElement<Column> | ReactElement<Column>[],
    data: {
        [key: string] : any
    }
}

const styles = (theme: Theme) => createStyles({
    table: {
        minWidth: 700,
        overflowX: 'auto',
    }
});

const DatatableView = (props: Props) => {

    const {classes} = props;
    const data = Object.values(props.data);

    return <Table className={classes.table} size="small">
        {props.datatableState === "FETCHED" && <TableHead>
            <TableRow>
                {React.Children.map<any, any>(props.children, (child: Column) =>
                    <TableCell>
                        {child.props.title ? child.props.title : child.props.identifier || ''}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>}
        {props.datatableState === "FETCHED" && <TableBody>
            {data.map((rowData:any) =>
                    <TableRow>
                        {React.Children.map<any, any>(props.children, (child: Column) => {
                            let cellData : string = child.props.identifier !== undefined ? rowData[child.props.identifier] : '';
                            return <TableCell>
                                {child.props.children
                                    ? child.props.children({cellData, rowData, expanded: false})
                                    : cellData !== undefined ? <Typography>{cellData}</Typography> : null}
                            </TableCell>;
                        })}
                    </TableRow>
                )}
        </TableBody>}
        {props.datatableState === "LOADING" && <TableBody>
            <TableRow>
                <TableCell align="center">
                    <CircularProgress size="12vh" thickness={1.6} />
                </TableCell>
            </TableRow>
        </TableBody>}
    </Table>;
};

export default withStyles(styles)(DatatableView);