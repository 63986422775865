import React from 'react';
import {makeStyles, TextField, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import {Tax} from "../../../models/tax";
import {taxAmountFromGrossAmount} from "../../../util/taxFunctions";
import numeral from "numeral";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
        }
    }),
);


interface RenderProps {
    getInternalProps: () => object
}

interface Props {
    taxRate: Tax;
    grossAmount: number;
    children?: (renderProps: RenderProps) => any;
}

const TaxField = (props: Props) => {

    const classes = useStyles();

    return (
        props.children ? props.children({getInternalProps : () => ({
            value : numeral(taxAmountFromGrossAmount(props.grossAmount, props.taxRate)).format("0.00$")
            })}) : <TextField
            id="outlined-read-only-input"
            label="enthaltene MwSt."
            className={classes.textField}
            margin="normal"
            value={numeral(taxAmountFromGrossAmount(props.grossAmount, props.taxRate)).format("0.00$")}
            InputProps={{
                readOnly: true,
            }}
            variant="filled"
        />
    );
};

export default(TaxField);