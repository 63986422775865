import React from 'react';
import BankingDetailsTemplate from "../../../components/templates/bankingDetails";
import {BankAccountConsumer} from "../../../stores/bankAccount";
import { BankTransaction } from '../../../models/bankTransaction';

interface Props {    
    accountId : string;
}

const Index = (props : Props) => {
    return (
        <BankAccountConsumer>
            {({bankAccounts}) => <BankingDetailsTemplate
                bankAccountId={props.accountId}
                bankAccount={bankAccounts[props.accountId]}
            />}
        </BankAccountConsumer>
    );
};

export default Index;