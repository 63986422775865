import {useEffect, useState} from "react";
import {AxiosError} from "axios";

export function useAPI<T>(method : any, ...params : any) : [T | undefined, boolean, AxiosError | undefined, () => void]{


    const [data, setData]           = useState<T | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError]          = useState<AxiosError |undefined>(undefined);

    const fetchData = async () => {
        setError(undefined);
        try {
            setIsLoading(true);
            setData(await method(...params));
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => { fetchData() }, []);

    return [ data, isLoading, error, fetchData ];
}