import axios, {AxiosResponse} from "axios";
import {adalApiFetch} from "../adalConfig";
import {DatatableOptions, DatatableResponse, FilterOption} from "../models/datatableOptions";
import {Invoice} from "../models/invoice";
import {toUrlParam} from "../util/encodingUtils";


export type InvoiceDatatableColumns = "TITLE" | "DATUM" | "AMOUNT" | "TAX_RATE" | "COST_TYPE" | "COST_CENTER" | "PARTNER" | "INVOICE_NUMBER" | "STATUS";

export const InvoiceApi = {

    getPaginatedInvoices: async (datatableOptions: DatatableOptions<InvoiceDatatableColumns>) => {

        let filterOptions;

        if(datatableOptions.filterOptions) {
            filterOptions= `${datatableOptions.filterOptions.map(option => toUrlParam(option)).join(",")}`
        }

        let res : AxiosResponse<DatatableResponse<Invoice>> = await adalApiFetch(axios, `/api/invoice`, {params: {
                page: datatableOptions.page,
                size: datatableOptions.size,
                searchValues: filterOptions,
                sortField: datatableOptions.sortField,
                sortDirection: datatableOptions.sortDirection
            }});

        return res.data;
    },

    getInvoice: async (id: string) => {
        let res = await adalApiFetch(axios, `/api/invoice/` + id, {});
        return res.data;
    },
};