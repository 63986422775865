import React, {useMemo, Fragment} from 'react';
import {
    createStyles,
    IconButton,
    ListItemIcon,
    makeStyles,
    Menu,
    MenuItem,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";
import {ColumnVisibilityProps} from "./types";
import {BankTransaction} from "../../../models/bankTransaction";
import {availableColumns} from "./index";
import {add, includes, uniq} from "ramda";
import {ExpansionIcon} from "../../atoms/expansionIcon";
import {allocationCountDependentRenderer} from "./renderingUtils";
import {trace} from "../../../util/trace";
import {CloudUpload, Edit, MoreHoriz, OpenInBrowser, Restore} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {Invoice} from "../../../models/invoice";
import Moment from "react-moment";
import numeral from "numeral";
import {calculateTaxesFromGrossAmount, sumInvoiceItems} from "../invoiceTable/utils";
import {taxRates} from "../../../models/tax";
import {invoiceItemCountDependentRenderer} from "../invoiceTable/renderingUtils";
import BookingAccountLabel from "../../atoms/bookingAccountLabel";
import isNonEmptyString from "../../../util/isNonEmptyString";
import {BookingAccountConsumer} from "../../../stores/bookingAccount";
import {CostCenterConsumer} from "../../../stores/costCenter";
import CostCenterLabel from "../../atoms/costCenterLabel";
import PartnerLabel from "../../atoms/partnerLabel";
import {AccountingTransaction, InvoiceAccountingTransaction} from "../../../models/accountingTransaction";
import {Link} from "react-router-dom";

interface Props extends ColumnVisibilityProps {
    isLoading?: boolean;
    elements: { [id: string]: BankTransaction };
    expansions: string[];
    toggleExpansion: (invoiceId: string) => void;
    onResetBankTransaction: (bankTransaction: BankTransaction) => void;
}

const BankTransactionTableBody = (props: Props) => {

    const elementIds = useMemo(() => Object.keys(props.elements), [props.elements]);

    return (
        <TableBody>
            {elementIds.length > 0 ? elementIds.map(elementId => <Fragment>
                <TableRow>
                    {props.visibleColumns.map(columnKey => BankTransactionCellRenderers[columnKey]({
                        id: elementId,
                        bankTransaction: props.elements[elementId],
                        toggleExpansion: props.toggleExpansion,
                        expansion: includes(elementId, props.expansions)
                    }))}

                    <MoreActionsTableCell 
                        bankTransaction={props.elements[elementId]} 
                        onResetBankTransaction={props.onResetBankTransaction} />

                </TableRow>
                {includes(elementId, props.expansions) && props.elements[elementId].transactions.map(at =>
                    <AccountingTransactionTableRow
                        key={elementId + "_" + at.id}
                        visibleColumns={props.visibleColumns}
                        bankTransaction={props.elements[elementId]}
                        accountingTransaction={at}/>)}
            </Fragment>) : <TableRow>
                <TableCell align="center" colSpan={props.visibleColumns.length + 1}>Keine Daten Vorhanden.</TableCell>
            </TableRow>}
        </TableBody>
    );
};


/**
 Table Cell Renderers
 */
const NilCell = () => <TableCell/>;

const BankTransactionTitleCell = ({id, bankTransaction, expansion, toggleExpansion}: { id: string, bankTransaction: BankTransaction, toggleExpansion: (bankTransactionId: string) => void, expansion: boolean }) =>
    <Fragment>
        {bankTransaction.transactions.length === 0 && <TableCell>{bankTransaction.reference}</TableCell>}
        {bankTransaction.transactions.length === 1 && <TableCell>{bankTransaction.transactions[0].title}</TableCell>}
        {bankTransaction.transactions.length > 1 && <TableCell style={{cursor: "pointer"}}
                                                               onClick={() => toggleExpansion(id)}>
            <ExpansionIcon expanded={expansion} disabled={false}/>&nbsp;
            {bankTransaction.reference}
        </TableCell>}
    </Fragment>;

const BankTransactionDateTableCell = ({bankTransaction}: { bankTransaction: BankTransaction }) => <TableCell><Moment
    format="DD.MM.YYYY">{bankTransaction.valueDate}</Moment></TableCell>;

const BankTransactionAmountTableCell = ({bankTransaction}: { bankTransaction: BankTransaction }) =>
    <TableCell>{numeral(bankTransaction.amount).format("0.00$")}</TableCell>;

const BankTransactionTaxRateCell = ({bankTransaction}: { bankTransaction: BankTransaction }) =>
    <Fragment>
        {bankTransaction.transactions.length === 0 && <TableCell>--</TableCell>}
        {bankTransaction.transactions.length === 1 &&
        <TableCell>{taxRates[bankTransaction.transactions[0].taxRate].label}</TableCell>}
        {bankTransaction.transactions.length > 1 &&
        <TableCell>{uniq(bankTransaction.transactions.map(bt => bt.taxRate)).map(taxRateKey => taxRates[taxRateKey].label).join(", ")}</TableCell>}
    </Fragment>;

const BankTransactionTaxCell = ({bankTransaction}: { bankTransaction: BankTransaction }) =>
    <TableCell>
        {bankTransaction.transactions.length === 0 && "--"}
        {bankTransaction.transactions.length > 0 && numeral(bankTransaction.transactions.map(at => calculateTaxesFromGrossAmount(at.amount, at.taxRate)).reduce(add)).format("0.00$")}
    </TableCell>;

const BankTransactionCostTypeDataCell = ({bankTransaction}: { bankTransaction: BankTransaction }): any => <Fragment>
    {bankTransaction.transactions.length === 0 && <TableCell>--</TableCell>}
    {bankTransaction.transactions.length === 1 &&
    <TableCell><BookingAccountLabel bookingAccountId={bankTransaction.transactions[0].contraAccountId}/></TableCell>}
    {bankTransaction.transactions.length > 1 &&
    <TableCell>{uniq(bankTransaction.transactions.map(trans => trans.contraAccountId)).length <= 1 ?
        <BookingAccountLabel bookingAccountId={bankTransaction.transactions[0].contraAccountId}/> :
        <ExternalIdBookingAccountList
            bookingAccountIds={bankTransaction.transactions.map(trans => trans.contraAccountId).filter(id => isNonEmptyString(id))}/>}</TableCell>}
</Fragment>;

const BankTransactionCostCenterDataCell = ({bankTransaction}: { bankTransaction: BankTransaction }): any => <Fragment>
    {bankTransaction.transactions.length === 0 && <TableCell>--</TableCell>}
    {bankTransaction.transactions.length === 1 &&
    <TableCell><CostCenterLabel costCenterId={bankTransaction.transactions[0].costCenterId}/></TableCell>}
    {bankTransaction.transactions.length > 1 &&
    <TableCell>{uniq(bankTransaction.transactions.map(trans => trans.costCenterId)).length <= 1 ?
        <CostCenterLabel costCenterId={bankTransaction.transactions[0].costCenterId}/> :
        <ExternalIdCostCenterList
            costCenterIds={bankTransaction.transactions.map(trans => trans.costCenterId).filter(id => isNonEmptyString(id))}/>}</TableCell>}
</Fragment>;

const BankTransactionFromBicCell = ({bankTransaction}: { bankTransaction: BankTransaction }) => <TableCell>
    {(bankTransaction.fromBic !== undefined) ? bankTransaction.fromBic : "--"}
</TableCell>;

const BankTransactionFromIbanCell = ({bankTransaction}: { bankTransaction: BankTransaction }) =>
    <TableCell>{bankTransaction.fromIban !== undefined ? bankTransaction.fromIban : "--"}</TableCell>

const BankTransactionFromNameCell = ({bankTransaction}: { bankTransaction: BankTransaction }) =>
    <TableCell>{bankTransaction.fromName !== undefined ? bankTransaction.fromName : "--"}</TableCell>

const BankTransactionNoticeCell = ({bankTransaction}: { bankTransaction: BankTransaction }) =>
    <TableCell>{bankTransaction.notice !== undefined ? bankTransaction.notice : "--"}</TableCell>

const BankTransactionPartnerDataCell = ({bankTransaction}: { bankTransaction: BankTransaction }) => <TableCell>
    {bankTransaction.transactions.length === 0 && "--"}
    {bankTransaction.transactions.length === 1 && <PartnerLabel
        partnerId={bankTransaction.transactions[0].partnerId}/>}
    {bankTransaction.transactions.length > 1 && <Fragment>
        {(uniq(bankTransaction.transactions.map(bt => bt.partnerId)).length > 1 ? "mehrere" : <PartnerLabel partnerId={bankTransaction.transactions[0].partnerId}/>)}
    </Fragment>}
</TableCell>;

interface MoreActionsTableCellProps {
    bankTransaction: BankTransaction;
    onResetBankTransaction: (bankTransaction: BankTransaction) => void;
}

const useMoreActionsTableCellStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    }),
);

const MoreActionsTableCell = (props: MoreActionsTableCellProps) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [resetDialogOpen, setResetDialogOpen] = React.useState<boolean>(false);

    const classes = useMoreActionsTableCellStyles();

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (<TableCell>
        <IconButton aria-label="Aktionen" onClick={handleClick}
                    size="small" style={{padding: 0}}>
            <MoreHoriz/>
        </IconButton>

        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <Link className={classes.link} to={{
                    pathname: `/banking/${props.bankTransaction.bankAccountId}`
                            + `/transaction/${props.bankTransaction.id}`,
                    state: { prevPath: window.location.pathname
            }}}>
                <MenuItem disabled={false}>
                    <ListItemIcon><Edit /></ListItemIcon>
                    <Typography>{props.bankTransaction.transactions.length > 0 ? "Buchung bearbeiten" : "Umsatz buchen"}</Typography>
                </MenuItem>
            </Link>
            {props.bankTransaction.transactions.length > 0 && <MenuItem disabled={false}
                    onClick={() => {
                        handleClose();
                        setResetDialogOpen(true);
                        
                    }}>
                
                <ListItemIcon><Restore /></ListItemIcon>
                <Typography>Buchung zurücksetzen</Typography>
            </MenuItem>}
        </Menu>
        
        <ResetDialog
            bankTransaction={props.bankTransaction}
            resetDialogOpen={resetDialogOpen}
            setResetDialogOpen={setResetDialogOpen}
            onResetBankTransaction={props.onResetBankTransaction} />

    </TableCell>);
};

interface ResetDialogProps {
    bankTransaction: BankTransaction;
    resetDialogOpen: boolean;
    setResetDialogOpen: (open: boolean) => void;
    onResetBankTransaction: (bankTransaction: BankTransaction) => void;
}

const ResetDialog = (props: ResetDialogProps) => {

    return (
        <Dialog
            open={props.resetDialogOpen}
            keepMounted
            onClose={() => props.setResetDialogOpen(false)}
            aria-labelledby="alert-reset-dialog-slide-title"
            aria-describedby="alert-reset-dialog-slide-description"
        >
        <DialogTitle id="alert-reset-dialog-slide-title">Buchung zurücksetzen</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-reset-dialog-slide-description">
                <p>Durch diese Aktion werden alle Positionen zu dieser Transaktion gelöscht. Das ist unwiderruflich.</p>
                <p>Möchtest du die Buchung dennoch zurücksetzen?</p>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => props.setResetDialogOpen(false)} color="primary">
            Nein
            </Button>
            <Button 
                onClick={() => {
                    props.setResetDialogOpen(false);
                    props.onResetBankTransaction(props.bankTransaction);
                }}
                color="primary">
            Ja
            </Button>
        </DialogActions>
        </Dialog>);
};

const BankTransactionCellRenderers: { [id in availableColumns]: any } = {
    "NAME": BankTransactionTitleCell,
    "FROM_NAME": BankTransactionFromNameCell,
    "DATE": BankTransactionDateTableCell,
    "AMOUNT": BankTransactionAmountTableCell,
    "TAX_RATE": BankTransactionTaxRateCell,
    "TAX": BankTransactionTaxCell,
    "COST_TYPE": BankTransactionCostTypeDataCell,
    "COST_CENTER": BankTransactionCostCenterDataCell,
    "PARTNER": BankTransactionPartnerDataCell,
    "STATUS": NilCell,
    "FROM_BIC": BankTransactionFromBicCell,
    "FROM_IBAN": BankTransactionFromIbanCell,
    "NOTICE": BankTransactionNoticeCell
}




const useBankTransactionTableAccountingTransactionRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            backgroundColor: theme.palette.grey["100"],
        },
    }),
);

interface AccountingTransactionTableRowProps extends ColumnVisibilityProps {
    accountingTransaction: InvoiceAccountingTransaction,
    bankTransaction: BankTransaction
}

const AccountingTransactionTableRow = (props: AccountingTransactionTableRowProps) => {

    const classes = useBankTransactionTableAccountingTransactionRowStyles();

    return <TableRow className={classes.row}>
        {props.visibleColumns.map(columnKey => AccountingTransactionCellRenderers[columnKey]({accountingTransaction: props.accountingTransaction, bankTransaction : props.bankTransaction}))}
        <TableCell/>
    </TableRow>;
};

const AccountingTransactionTitleCell = ({bankTransaction, accountingTransaction}: { bankTransaction: BankTransaction, accountingTransaction: AccountingTransaction }) =>
    <TableCell>{bankTransaction.reference} - <Typography variant={"subtitle1"}>{accountingTransaction.title}</Typography></TableCell>;
const AccountingTransactionAmountCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell>{numeral(accountingTransaction.amount).format("0.00$")}</TableCell>;
const AccountingTransactionTaxRateCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell>{taxRates[accountingTransaction.taxRate].label}</TableCell>;
const AccountingTransactionTaxCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell>{numeral(calculateTaxesFromGrossAmount(accountingTransaction.amount, accountingTransaction.taxRate)).format("0.00$")}</TableCell>;
const AccountingTransactionCostTypeCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell><BookingAccountLabel bookingAccountId={accountingTransaction.contraAccountId}/></TableCell>;
const AccountingTransactionCostCenterCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell><CostCenterLabel costCenterId={accountingTransaction.costCenterId}/></TableCell>;
const AccountingTransactionPartnerCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell><PartnerLabel partnerId={accountingTransaction.partnerId}/></TableCell>;



const AccountingTransactionCellRenderers: { [id in availableColumns]: any } = {
    "NAME": AccountingTransactionTitleCell,
    "FROM_NAME": BankTransactionFromNameCell,
    "DATE": NilCell,
    "AMOUNT": AccountingTransactionAmountCell,
    "TAX_RATE": AccountingTransactionTaxRateCell,
    "TAX": AccountingTransactionTaxCell,
    "COST_TYPE": AccountingTransactionCostTypeCell,
    "COST_CENTER": AccountingTransactionCostCenterCell,
    "PARTNER": AccountingTransactionPartnerCell,
    "FROM_BIC": NilCell,
    "FROM_IBAN": NilCell,
    "STATUS": NilCell,
    "NOTICE": BankTransactionNoticeCell
}





const ExternalIdBookingAccountList = ({bookingAccountIds}: { bookingAccountIds: (string | undefined)[] }) =>
    <BookingAccountConsumer>
        {({bookingAccounts}) => <Typography>
            {uniq(bookingAccountIds).map(bookingAccountId => bookingAccountId ? bookingAccounts[bookingAccountId].id : undefined).join(", ")}
        </Typography>}
    </BookingAccountConsumer>;

const ExternalIdCostCenterList = ({costCenterIds}: { costCenterIds: (string | undefined)[] }) =>
    <CostCenterConsumer>
        {({availableCostCenters}) => <TableCell>
            {uniq(costCenterIds).map(costCenterId => costCenterId ? availableCostCenters[costCenterId].id : undefined).join(", ")}
        </TableCell>}
    </CostCenterConsumer>;

export default React.memo(BankTransactionTableBody);