import React, {Fragment, Component} from 'react';
import {FetchState} from "../../models/fetchState";
import {adalApiFetch} from "../../adalConfig";
import axios from "axios";
import {Badge} from "../../models/badge";
import {debounce} from "../../util/debounce";
import isNonEmptyString from "../../util/isNonEmptyString";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    TextField
} from "@material-ui/core";
import {FixedSizeList} from "react-window";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Check} from "@material-ui/icons";

interface ContextData {
    state: FetchState;
    badges: {
        [id: string] : Badge
    };
    openModal?: (
        newCallback?: (badges: Badge[]) => void,
        selectedBadges?: Badge[]
    ) => void;
    newBadgesCallback?: (badges: Badge[]) => void;
}

interface State extends ContextData {
    modalOpen: boolean;
    searchValue: string;
    badgeIdArray: string[];
    filteredBadgeIdArray: string[];
    selectedBadgeIdArray?: string[];
}

type Props = {
    children: any;
    fetchMethod?: any;
}

const initialState : ContextData = {
    state: "INIT",
    badges: {}
}


const BadgeStoreContext = React.createContext<ContextData>(initialState);

class BadgeProvider extends Component<Props, State> {

    constructor(props : Props) {
        super(props);

        this.state = {
            ...initialState,
            openModal: this.openModal,
            modalOpen: false,
            searchValue: "",
            badgeIdArray: [],
            filteredBadgeIdArray: [],
            selectedBadgeIdArray: []
        };

        let fetchMethod;

        if(props.fetchMethod) {
            fetchMethod = props.fetchMethod
        } else {
            fetchMethod = adalApiFetch(axios, "/api/badge", {});
        }

        fetchMethod
            .then(({data} : any) => this.setState({
                badges: data,
                state: "READY",
                badgeIdArray: Object.values(data).sort((thisBadge, thatBadge) => (thisBadge as Badge).title < (thatBadge as Badge).title ? -1 : 1).map(badge => (badge as Badge).id)
            }))
            .catch((error : any) => this.setState({state: "ERROR"}));
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevState.searchValue !== this.state.searchValue) {
            this.recalculateSearchArray();
        }
    }

    recalculateSearchArray = debounce(() => {
        if (isNonEmptyString(this.state.searchValue)) {

            const simplifiedValue = this.state.searchValue.toLowerCase().trim();

            let filteredIdArray = Object.values(this.state.badges)
                .filter((badge: Badge) => badge.title
                    .toLowerCase().trim().includes(simplifiedValue))
                .map((object) => object.id);

            this.setState({filteredBadgeIdArray: filteredIdArray});
        }
    }, 500);

    openModal = (callback?: (badges: Badge[]) => void, badges?: Badge[]) => {
        this.setState({
            modalOpen: true,
            newBadgesCallback: callback,
            selectedBadgeIdArray: badges ? badges.map(badge => badge.id) : undefined
        })
    }

    closeModal = () => {
        this.setState({modalOpen: false})
    }

    render() {

        const idArray = isNonEmptyString(this.state.searchValue) ? this.state.filteredBadgeIdArray : this.state.badgeIdArray;

        return (
            <BadgeStoreContext.Provider value={this.state}>
                <Fragment>
                    {this.props.children}
                    <Dialog onClose={this.closeModal} open={this.state.modalOpen}>
                        <DialogTitle>
                            Plaketten auswählen
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField
                                fullWidth
                                value={this.state.searchValue}
                                onChange={(e) => this.setState({searchValue: e.target.value})}
                                margin="normal"
                            />

                            <FixedSizeList height={400} width={520} itemSize={46}
                                           itemCount={idArray.length}>
                                {({style, index}) => (
                                    <ListItem button
                                        style={style}
                                        key={index}
                                        onClick={() => {

                                            const selectedBadgeIdArray = this.state.selectedBadgeIdArray
                                                ? this.state.selectedBadgeIdArray.includes( idArray[index] )
                                                    ? this.state.selectedBadgeIdArray.filter(id => id !== idArray[index])
                                                    : [ ...this.state.selectedBadgeIdArray, idArray[index] ]
                                                : [ idArray[index] ]

                                            const selectedBadges = selectedBadgeIdArray.map(
                                                id => this.state.badges[id]
                                            )
                                            this.setState({
                                                selectedBadgeIdArray: selectedBadgeIdArray
                                            })

                                            if(this.state.newBadgesCallback && selectedBadgeIdArray) {
                                                this.state.newBadgesCallback(selectedBadges)
                                            }
                                        }}
                                    >
                                        <ListItemText
                                            primary={this.state.badges[(idArray[index])]
                                                ? this.state.badges[(idArray[index])].title : "Nicht vorhanden"}/>
                                        { this.state.selectedBadgeIdArray
                                            && this.state.selectedBadgeIdArray.includes(idArray[index]) && (
                                                <ListItemIcon><Check /></ListItemIcon>
                                            )
                                        }
                                    </ListItem>)}
                            </FixedSizeList>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeModal} color="default">
                                Schliessen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            </BadgeStoreContext.Provider>
        );
    }
}

const BadgeConsumer = BadgeStoreContext.Consumer;

export {BadgeProvider, BadgeConsumer};
