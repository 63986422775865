import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {createStyles, makeStyles, Table, TableHead, Theme} from "@material-ui/core";
import {Invoice, InvoiceStatus} from "../../../models/invoice";
import {InvoiceApi, InvoiceDatatableColumns} from "../../../api/invoice";
import {DatatableResponse, FilterOption, SortDirection} from "../../../models/datatableOptions";
import {append, includes, intersection, without} from 'ramda';
import {DocumentArea} from "./documentArea";
import InvoiceTableBody from "./invoiceTableBody";
import InvoiceTableFooter from "./invoiceTableFooter";
import InvoiceTableHeader from "./invoiceTableHeader";
import InvoiceTableFilterRow from "./InvoiceTableFilters";
import {useDebounce} from "../../../hooks/useDebounce";
import isNonEmptyString from "../../../util/isNonEmptyString";
import {Tax} from "../../../models/tax";
import { sumInvoiceTaxes, sumInvoiceAmounts } from './utils';
import { Doc } from '../../../models/doc';
import axios from 'axios';
import { NotificationConsumer } from '../../molecules/notification';
import { Notification } from '../../molecules/notification/types';

const useStyles = makeStyles((theme: Theme) => createStyles({   }));

export type availableColumns =
    "NAME"
    | "STATUS"
    | "DATE"
    | "AMOUNT"
    | "TAX_RATE"
    | "TAX"
    | "COST_TYPE"
    | "COST_CENTER"
    | "PARTNER"
    | "INVOICE_NUMBER";

const apiColumnMap :{[columnKey : string] : InvoiceDatatableColumns | undefined} = {
    "NAME" : "TITLE",
    "DATE" : "DATUM",
};

const sortableColumns : string[] = Object.keys(apiColumnMap);

export const allTableColumns: availableColumns[] = ["NAME", "DATE", "AMOUNT", "TAX_RATE", "TAX", "COST_TYPE", "COST_CENTER", "PARTNER", "INVOICE_NUMBER", "STATUS"];
export const initialTableOrdering: availableColumns[] = ["NAME", "DATE", "AMOUNT", "TAX_RATE", "TAX", "COST_TYPE", "COST_CENTER", "PARTNER"];


//bad place to map to backend field titles... TODO
function createFilterOptions(filterTitle: string,
                             filterDate: undefined | [string] | [string, string],
                             filterAmount: undefined | [string] | [string, string],
                             filterTaxRate: undefined | Tax,
                             filterCostType: undefined | string,
                             filterCostCenter: undefined | string,
                             filterPartner: undefined | string,
                             filterInvoiceNumber: string,
                             filterInvoiceStatus: undefined | InvoiceStatus) {

    let filterOptions: FilterOption<InvoiceDatatableColumns>[] = [];

    if (isNonEmptyString(filterTitle)) {
        filterOptions.push({filterType: "CONTAINS", filterValue: [filterTitle], field: "TITLE"});
    }

    if (filterDate) {
        filterOptions.push({
            filterType: filterDate.length === 1 ? "IS" : "BETWEEN",
            filterValue: filterDate,
            field: "DATUM"
        });
    }

    if (filterAmount) {
        filterOptions.push({
            filterType: filterAmount.length === 1 ? "IS" : "BETWEEN",
            filterValue: filterAmount,
            field: "AMOUNT"
        })
    }

    if (filterTaxRate) {
        filterOptions.push({filterType: "IS", filterValue: [filterTaxRate], field: "TAX_RATE"});
    }

    if (filterCostType) {
        filterOptions.push({filterType: "IS", filterValue: [filterCostType], field: "COST_TYPE"});
    }

    if (filterCostCenter) {
        filterOptions.push({filterType: "IS", filterValue: [filterCostCenter], field: "COST_CENTER"});
    }

    if (filterPartner) {
        filterOptions.push({filterType: "IS", filterValue: [filterPartner], field: "PARTNER"});
    }

    if (isNonEmptyString(filterInvoiceNumber)) {
        filterOptions.push({filterType: "CONTAINS", filterValue: [filterInvoiceNumber], field: "INVOICE_NUMBER"});
    }

    console.log(filterInvoiceStatus);
    if (filterInvoiceStatus) {
        filterOptions.push({filterType: "IS", filterValue: [filterInvoiceStatus], field: "STATUS"});
    }


    return filterOptions.length !== 0 ? filterOptions : undefined;
}

interface Props {
    addNotification: (notification: Notification) => void;
}

const InvoiceTableInternal = (props: Props) => {

    //styles
    const classes = useStyles();

    //Visible Columns
    const [columnOrderModified, setColumnOrderModified] = React.useState<boolean>(false);
    const [visibleColumns, setVisibleColumns] = React.useState<availableColumns[]>(initialTableOrdering);

    //toggle the visibility of a column. if the column order is user-modified, the column is appended. otherwise, it is toggled in it's original position.
    const toggleColumnVisibility: (columnKey: availableColumns) => void = useMemo(() => (columnKey: availableColumns) => {
        setVisibleColumns(includes(columnKey, visibleColumns) ?
            (without([columnKey], visibleColumns)) :
            (columnOrderModified ? append(columnKey, visibleColumns) : without(without(append(columnKey, visibleColumns), allTableColumns), allTableColumns)));
    }, [visibleColumns]);


    //expansions
    const [expansions, setExpansions] = React.useState<string[]>([]);
    const toggleExpansion: (invoiceId: string) => void = useMemo(() => (invoiceId => includes(invoiceId, expansions) ? setExpansions(without([invoiceId], expansions)) : setExpansions(append(invoiceId, expansions))), [expansions]);

    //datatable values
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [page, setPage] = React.useState(0);
    const [size, setSize] = React.useState(20);
    const [dtResponse, setDtResponse] = React.useState<DatatableResponse<Invoice>>({
        elements: {},
        elementCount: 0,
        totalElements: 0,
        page: 0,
        pageSize: 10,
        totalPages: 0
    });

    //filters
    const [filterTitle, setFilterTitle] = useState("");
    const filterTitleDebounced = useDebounce(filterTitle, 500);
    const [filterDate, setFilterDate] = useState<undefined | [string] | [string, string]>(undefined);
    const [filterAmount, setFilterAmount] = useState<undefined | [string] | [string, string]>(undefined);
    const debouncedFilterAmount = useDebounce(filterAmount, 500);
    const [filterTaxRate, setFilterTaxRate] = useState<undefined | Tax>(undefined);
    const [filterCostType, setFilterCostType] = useState<undefined | string>(undefined);
    const [filterCostCenter, setFilterCostCenter] = useState<undefined | string>(undefined);
    const [filterPartner, setFilterPartner] = useState<undefined | string>(undefined);
    const [filterInvoiceNumber, setFilterInvoiceNumber] = useState<string>("");
    const filterInvoiceNumberDebounced = useDebounce(filterInvoiceNumber, 500);
    const [filterInvoiceStatus, setFilterInvoiceStatus] = useState<InvoiceStatus | undefined>(undefined);

    const changeFilter = (callback: ((value: any) => void)) => (value: any) => {
                
        // reset page
        setPage(0);
        // set filter value
        callback(value);
    };

    const changeSize = (callback: ((newSize: number) => void)) => (newSize: number) => {

        // if size is bigger than total elements
        if(newSize > dtResponse.totalElements) {
            // reset page
            setPage(0);            
        }
        // set size
        callback(newSize);
    };

    //more actions stuff
    const documentUploadCallback = useCallback((formData: FormData, invoice: Invoice) => {

        axios.post("/api/document", formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
                .then((response) => {

                    const newDocuments = [...response.data, ...invoice.documents];

                    axios.put("/api/invoice/" + invoice.id, {
                            ...invoice,
                            documents: newDocuments
                        })
                        .then(() => {      
                            if(invoice.id && dtResponse.elements[invoice.id]) { 
                                props.addNotification({
                                    type: "SUCCESS",
                                    title: "Dokumente wurden eingereicht."
                                });
                                setDtResponse({
                                    ...dtResponse,
                                    elements: {
                                        ...dtResponse.elements,
                                        [invoice.id]: {...dtResponse.elements[invoice.id], documents: newDocuments}
                                    }
                                });                                
                            }
                        })
                        .catch(() => {
                            props.addNotification({
                                type: "ERROR",
                                title: "Dokumente konnten nicht eingereicht werden."
                            });
                        });
                })
                .catch(() => {
                    props.addNotification({
                        type: "ERROR",
                        title: "Dokumente konnten nicht hochgeladen werden."
                    });
                });

    }, [dtResponse, setDtResponse]);

    //document area stuff
    const [shownDocuments, setShownDocuments] = useState<Doc[]>([]);
    const openDocumentArea = useCallback((documents: Doc[]) => {
        setShownDocuments(documents);
    }, [shownDocuments, setShownDocuments]);

    //sort
    const [sort, setSort] = useState<undefined | [availableColumns, SortDirection]>(["DATE", "DESC"]);
    const debouncedSort = useDebounce(sort, 500);
    const toggleSort = useCallback((newSortColumn?: availableColumns) => {

        if(newSortColumn) {
            if(sort) {
                if(sort[0] === newSortColumn) {
                    if (sort[1] === "ASC") setSort(undefined);
                    if (sort[1] === "DESC") setSort([newSortColumn, "ASC"]);
                } else {
                    setSort([newSortColumn, "DESC"]);
                }
            } else {
                setSort([newSortColumn, "DESC"]);
            }
        } else {
            setSort(undefined);
        }

    }, [sort, setSort]);

    //Reload the DT Endpoint when changes to request parameters occur.
    useEffect(() => {

        setLoading(true);

        InvoiceApi.getPaginatedInvoices({
            page: page,
            size: size,
            filterOptions: createFilterOptions(filterTitleDebounced, filterDate, debouncedFilterAmount, filterTaxRate, filterCostType, filterCostCenter, filterPartner, filterInvoiceNumberDebounced, filterInvoiceStatus),
            sortDirection : (sort ? sort[1] : undefined),
            sortField : sort? apiColumnMap[sort[0]] : undefined
        }).then(data => {
            setLoading(false);
            setDtResponse(data);
        }).catch(error => {
            console.log(error);
        });
    }, [page, size, debouncedSort, filterTitleDebounced, filterDate, debouncedFilterAmount, filterTaxRate, filterCostType, filterCostCenter, filterPartner, filterInvoiceNumberDebounced, filterInvoiceStatus]);

    const totalAmount = useMemo(() => 
            sumInvoiceAmounts(Object.values(dtResponse.elements)), [dtResponse.elements]);
    const totalTax = useMemo(() => 
            sumInvoiceTaxes(Object.values(dtResponse.elements)), [dtResponse.elements]);

    return (
        <Fragment>
            <Table size={"small"}>
                <TableHead>
                    <InvoiceTableHeader
                        visibleColumns={visibleColumns}
                        toggleColumnVisibility={toggleColumnVisibility}
                        sortableColumns={sortableColumns}
                        sort={sort}
                        toggleSort={toggleSort}
                    />

                    <InvoiceTableFilterRow
                        visibleColumns={visibleColumns}
                        filterTitle={filterTitle}
                        setFitlerTitle={changeFilter(setFilterTitle)}
                        filterDate={filterDate}
                        setFilterDate={changeFilter(setFilterDate)}
                        filterAmount={filterAmount}
                        setFilterAmount={changeFilter(setFilterAmount)}
                        filterTaxRate={filterTaxRate}
                        setFilterTaxRate={changeFilter(setFilterTaxRate)}
                        filterCostType={filterCostType}
                        setFilterCostType={changeFilter(setFilterCostType)}
                        filterCostCenter={filterCostCenter}
                        setFilterCostCenter={changeFilter(setFilterCostCenter)}
                        filterPartner={filterPartner}
                        setFilterPartner={changeFilter(setFilterPartner)}
                        filterInvoiceNumber={filterInvoiceNumber}
                        setFilterInvoiceNumber={changeFilter(setFilterInvoiceNumber)}
                        filterInvoiceStatus={filterInvoiceStatus}
                        setFilterInvoiceStatus={changeFilter(setFilterInvoiceStatus)}
                    />
                </TableHead>
                <InvoiceTableBody
                    isLoading={loading}
                    visibleColumns={visibleColumns}
                    toggleExpansion={toggleExpansion}

                    elements={dtResponse.elements}
                    expansions={expansions}
                    documentUploadCallback={documentUploadCallback}
                    openDocumentAreaCallback={openDocumentArea}
                />
                <InvoiceTableFooter
                    visibleColumns={visibleColumns}
                    page={page}
                    pageChangedCallback={setPage}
                    rowsPerPage={size}
                    rowsPerPageChangedCallback={changeSize(setSize)}
                    totalElements={dtResponse.totalElements}
                    totalAmount={totalAmount}
                    totalTax={totalTax}/>
            </Table>

            <DocumentArea
                documents={shownDocuments}
                onClose={() => setShownDocuments([])} />

        </Fragment>

    );
};

const InvoiceTable = () => <NotificationConsumer>
    {({addNotification}) => <InvoiceTableInternal addNotification={addNotification} />}
</NotificationConsumer>;

export default InvoiceTable;