import React from 'react';
import {FetchState} from "../../../models/fetchState";
import {CircularProgress, createStyles, Grid, makeStyles, Paper, Theme, Typography} from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import {PartnerConsumer} from "../../../stores/partner";
import {UserStoreConsumer} from "../../../stores/user";
import {BankAccountConsumer} from "../../../stores/bankAccount";
import {BookingAccountConsumer} from "../../../stores/bookingAccount";
import {CostCenterConsumer} from "../../../stores/costCenter";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid : {
          justifyContent: "center"
        },
        paper: {
            margin: theme.spacing(1),
            padding: theme.spacing(1)
        },
        loadingWrapper: {
            padding: theme.spacing(8)
        },
        checkIcon: {
            color: theme.palette.primary.main
        },
        errorIcon: {
            color: theme.palette.error.main
        }
    }),
);


/**
 *
 * @param props
 * @constructor
 */
const LoadingBaseData = () => {

    const classes = useStyles();

    return (
        <CostCenterConsumer>
            {({state: costCenterState}) => <BookingAccountConsumer>
                {({state: bookingAccountState}) => <BankAccountConsumer>
                    {({state: accountStoreState}) => <UserStoreConsumer>
                        {({state: userState}) => <PartnerConsumer>
                            {({state: partnerState}) => <Grid container className={classes.grid}>
                                <Grid item xs={12} md={3}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="h5" component="h3" align="center">
                                            Stammdaten werden geladen
                                        </Typography>

                                        <div className={classes.loadingWrapper}>
                                            <div style={{display: "flex", justifyContent: "space-between"}}><Typography variant={"h6"}>Benutzerdaten...</Typography>
                                                {userState === "INIT" && <CircularProgress size="1em" thickness={3}/>}
                                                {userState === "READY" && <span className={classes.checkIcon}><CheckCircleOutlineIcon/></span>}
                                                {userState === "ERROR" && <span className={classes.errorIcon}><ErrorIcon/></span>}
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between"}}><Typography variant={"h6"}>Gesellschafterdaten...</Typography>
                                                {partnerState === "INIT" && <CircularProgress size="1em" thickness={3}/>}
                                                {partnerState === "READY" && <span className={classes.checkIcon}><CheckCircleOutlineIcon/></span>}
                                                {partnerState === "ERROR" && <span className={classes.errorIcon}><ErrorIcon/></span>}
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between"}}><Typography variant={"h6"}>Bankdaten...</Typography>
                                                {accountStoreState === "INIT" && <CircularProgress size="1em" thickness={3}/>}
                                                {accountStoreState === "READY" && <span className={classes.checkIcon}><CheckCircleOutlineIcon/></span>}
                                                {accountStoreState === "ERROR" && <span className={classes.errorIcon}><ErrorIcon/></span>}
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between"}}><Typography variant={"h6"}>Kontenrahmen...</Typography>
                                                {bookingAccountState === "INIT" && <CircularProgress size="1em" thickness={3}/>}
                                                {bookingAccountState === "READY" && <span className={classes.checkIcon}><CheckCircleOutlineIcon/></span>}
                                                {bookingAccountState === "ERROR" && <span className={classes.errorIcon}><ErrorIcon/></span>}
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between"}}><Typography variant={"h6"}>Kostenstellen...</Typography>
                                                {costCenterState === "INIT" && <CircularProgress size="1em" thickness={3}/>}
                                                {costCenterState === "READY" && <span className={classes.checkIcon}><CheckCircleOutlineIcon/></span>}
                                                {costCenterState === "ERROR" && <span className={classes.errorIcon}><ErrorIcon/></span>}
                                            </div>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>}
                        </PartnerConsumer>}
                    </UserStoreConsumer>}
                </BankAccountConsumer>}
            </BookingAccountConsumer>}
        </CostCenterConsumer>
    );
};

export default LoadingBaseData;