import React, { Fragment } from 'react';
import { Grid, TextField, FormControlLabel, Checkbox } from '@material-ui/core';

import FormattedInput from '../../atoms/formattedInput';
import { DatevExport } from '../../../models/datevExport';

interface Props {

    data: DatevExport;

    beraterNummerChangedCallback: (value: number) => void;
    mandantenNummerChangedCallback: (value: number) => void;
    buchungsstapelBeginnChangedCallback: (value: string) => void;
    buchungsstapelEndeChangedCallback: (value: string) => void;
    fullExportChangedCallback: () => void;
}

const DatevExportDialog = (props: Props) => {

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormattedInput
                        validator={val => val.length > 0
                            && !(isNaN(+val)) && +val > 0 && +val % 1 === 0}
                        modifier={val => +val}
                        valueChangedCallback={props.beraterNummerChangedCallback}
                        defaultValue={`${props.data.beraterNummer}`}>

                        {({getInternalProps}) => <TextField
                            {...getInternalProps()}
                            fullWidth={true}
                            variant="outlined"
                            label="Beraternummer"
                        />}
                    </FormattedInput>
                </Grid>

                <Grid item xs={12}>
                    <FormattedInput
                        validator={val => val.length > 0
                            && !(isNaN(+val)) && +val > 0 && +val % 1 === 0}
                        modifier={val => +val}
                        valueChangedCallback={props.mandantenNummerChangedCallback}
                        defaultValue={`${props.data.mandantenNummer}`}>

                        {({getInternalProps}) => <TextField
                            {...getInternalProps()}
                            fullWidth={true}
                            variant="outlined"
                            label="Mandantennummer"
                        />}
                    </FormattedInput>
                </Grid>
                
                <Grid item xs={6}>
                    <TextField
                        label="Beginn"
                        type="date"
                        variant="outlined"
                        value={props.data.buchungsstapelBeginn}
                        onChange={e => props.buchungsstapelBeginnChangedCallback(e.target.value)}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Ende"
                        type="date"
                        variant="outlined"
                        value={props.data.buchungsstapelEnde}
                        onChange={e => props.buchungsstapelEndeChangedCallback(e.target.value)}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        label="Bereits exportierte Buchungen miteinbeziehen (Status wird nicht gesetzt)"
                        control={
                            <Checkbox 
                                color="primary"
                                checked={props.data.fullExport}
                                onChange={ e => props.fullExportChangedCallback() }
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default DatevExportDialog;