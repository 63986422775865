import React, { Fragment } from 'react';
import { Snackbar, SnackbarContent, Typography, Theme, withStyles, WithStyles } from "@material-ui/core";
import { Notification } from './types';
import { green } from '@material-ui/core/colors';

interface ContextData {
    addNotification: (notification: Notification) => void;
}

interface State {
    notifications: Notification[];
}

interface Props extends WithStyles<typeof styles> {
    children: any;
}

const NotificationStoreContext = React.createContext<ContextData>({
        addNotification: console.log
});

const styles = (theme: Theme) => ({
    SUCCESS: {
        backgroundColor: green[600],
    },
    ERROR: {
        backgroundColor: theme.palette.error.dark,
    },
    title: {
        fontWeight: 600
    },
    message: {
        marginTop: 10
    }
});

class InternalNotificationProvider extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { notifications: [] };
    }

    addNotification = (notification: Notification) => setTimeout(() => this.setState({
            notifications: [...this.state.notifications, notification]
    }), 300);

    render() {

        const { classes } = this.props;

        return (
            <NotificationStoreContext.Provider value={{addNotification: this.addNotification}}>
                {this.props.children}
                {this.state.notifications.map((notification: Notification, index: number) => 
                    <Snackbar
                        open={true}
                        onClose={() => this.setState({
                            notifications: this.state.notifications
                                .filter((_value, currentIndex) => index != currentIndex)
                        })}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        autoHideDuration={notification.duration || 2500}>
                    <SnackbarContent
                        className={classes[notification.type]}
                        message={
                            <Fragment>
                                <Typography className={classes.title}>
                                    {notification.title}
                                </Typography>
                                {notification.message && <Typography className={classes.message}>
                                    {notification.message}
                                </Typography>}
                            </Fragment>
                        }
                    />
                </Snackbar>
                )}                
            </NotificationStoreContext.Provider>
        );
    }
}

const NotificationProvider = withStyles(styles)(InternalNotificationProvider);
const NotificationConsumer = NotificationStoreContext.Consumer;

export {NotificationProvider, NotificationConsumer};