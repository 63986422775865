import React, {Component, Fragment} from 'react';

interface AreaProps {
    label: string,
    children?: React.ReactElement<any, any>,
}

class Area extends Component<AreaProps> {
    render() {
        return (
            <Fragment />
        );
    }
}

export default Area;