import React, {Component} from 'react';
import BankingTemplate from "../../components/templates/banking";
import {adalApiFetch} from "../../adalConfig";
import axios from "axios";
import {BankAccountBalances, BankTransaction} from "../../models/bankTransaction";
import {BankingApi} from "../../api/banking";
import {UserStoreConsumer} from "../../stores/user";

interface Props {}

interface State {

    selectedAccountId?: string,

    accountBalances?: BankAccountBalances;

    currentTransactionPage: number;
    currentTransactionSize: number;
    transactions: {
        [id: string] : BankTransaction
    };
    totalTransactions: number;
    transactionsLoading: boolean;
    transactionFetchError?: object;
}

class Index extends Component<Props, State> {

    constructor(props : Props) {
        super(props);

        this.state = {
            currentTransactionPage: 0,
            currentTransactionSize: 10,
            transactions: {},
            totalTransactions: 0,
            transactionsLoading: false,
            transactionFetchError: undefined
        };
    }

    componentDidMount(): void {
        adalApiFetch(axios, "/api/banking/balances", {})
            .then((response) => this.setState({accountBalances: response.data}))
            .catch(console.error);
    }

    transactionPageChangedCallback = (newPage: number) => this.setState({currentTransactionPage: newPage});
    transactionsSizeChangedCallback = (newSize: number) => this.setState({currentTransactionSize: newSize});

    render() {
        return (
            <UserStoreConsumer>
                {({bankAccountId}) => <BankingTemplate
                    accountId={bankAccountId}
                    accountBalances={this.state.accountBalances}
                    currentTransactionPage={this.state.currentTransactionPage}
                    currentTransactionSize={this.state.currentTransactionSize}
                    transactions={this.state.transactions}
                    totalTransactions={this.state.totalTransactions}
                    transactionsLoading={this.state.transactionsLoading}
                    transactionFetchError={this.state.transactionFetchError}
                    transactionPageChangedCallback={this.transactionPageChangedCallback}
                    transactionSizeChangedCallback={this.transactionsSizeChangedCallback}
                />}
            </UserStoreConsumer>
        );
    }
}

export default Index;