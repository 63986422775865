import React, {Fragment} from 'react';
import BreadCrumbItem from "../../molecules/breadCrumb/breadCrumbItem";
import BreadCrumb from "../../molecules/breadCrumb";
import TaxRate from "../../atoms/taxRate";
import {Tax as TaxRateType} from "../../../models/tax";
import {
    Button,
    CircularProgress,
    createStyles, Divider,
    Grid,
    makeStyles,
    Paper,
    Table, TableBody,
    TableCell,
    TableRow, TextField,
    Theme,
    Typography
} from "@material-ui/core";
import numeral from "numeral";
import OpenTransactionsTable from "../../organisms/openTransactionsTable";
import {BankTransaction} from "../../../models/bankTransaction";
import BookingAccountField from "../../molecules/bookingAccountField";
import CostCenterSelection from "../../molecules/costCenterField";
import Moment from "react-moment";
import {BankAccount} from "../../../models/masterData";
import {MuiLink} from "../../atoms/MuiLink";
import PartnerField from "../../molecules/partnerField";

interface Props {
    bankAccountId: string;
    bankAccount?: BankAccount;

    openDataLoading: boolean;

    currentTransaction?: BankTransaction;
    title: string;
    setTitle: (newTitle: string) => void;
    contraAccountId?: string;
    setContraAccountId: (id?: string) => void;
    costCenterId?: string;
    setCostCenterId: (id?: string) => void;
    partnerId?: string;
    setPartnerId: (id?: string) => void;
    taxRate: TaxRateType;
    setTaxRate: (taxRate: TaxRateType) => void;
    canSubmit: boolean;
    submitTransactionCallback: () => void;
    jumpToInputEl: any;


    openTransactionFetchError?: object;
    openTransactionAmount: number;
    openPositiveTransactionSum: number;
    openNegativeTransactionSum: number;

    openTransactions: BankTransaction[];

}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        grid: {
            padding: theme.spacing(1)
        },

        openTransactionsItem: {
            textAlign: "center"
        },
        neg: {
            color: "red"
        },
        pos: {
            color: "green"
        },
        allocateTransactionGridItem: {
            padding: theme.spacing(2)
        },
        button: {
            margin: theme.spacing(1),
        },
        textField: {
            margin: theme.spacing(1)
        }
    }),
);


const BankingDetails = (props: Props) => {

    const classes = useStyles();

    return (
        <Fragment>
            <BreadCrumb>
                <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                <BreadCrumbItem to={"/banking"} title={"Bankkonten"}/>
                <BreadCrumbItem to={`/banking/${props.bankAccountId}`}
                                title={props.bankAccount ? props.bankAccount.title : props.bankAccountId}/>
                <BreadCrumbItem to={`/banking/${props.bankAccountId}/batch`} title={"Stapelbuchung"}/>
            </BreadCrumb>

            {props.bankAccount ? <Grid container className={classes.grid} alignItems="center" justify={"space-around"}>
                <Grid item xs={12} md={6} className={classes.openTransactionsItem}>
                    <Typography>Noch offene Buchungen:</Typography>
                    {props.openDataLoading &&
                    <Typography variant="h2"><CircularProgress size="1em" thickness={3}/></Typography>}
                    {!props.openDataLoading && !props.openTransactionFetchError &&
                    <Typography variant="h2">{props.openTransactionAmount}</Typography>}
                </Grid>
                <Grid item xs={12} md={6} className={classes.openTransactionsItem}>
                    <Typography>Nicht zugeordnete Beträge</Typography>
                    <Typography variant={"h5"}
                                className={classes.neg}>{numeral(props.openNegativeTransactionSum).format("0.00$")}</Typography>
                    <Typography variant={"h5"}
                                className={classes.pos}>{numeral(props.openPositiveTransactionSum).format("0.00$")}</Typography>
                </Grid>

                {props.currentTransaction && <Fragment>
                    <Grid item xs={12}>
                        <Divider></Divider>
                        <Typography variant="h5" align="center">Nächste offene Buchung:</Typography>
                    </Grid>

                    <Grid item xs={12} md={4} className={classes.allocateTransactionGridItem}>
                        <Table size={"small"}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Titel:</TableCell>
                                    <TableCell>{props.currentTransaction.reference}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Wertstellung:</TableCell>
                                    <TableCell>{<Moment
                                        format="DD.MM.YYYY">{props.currentTransaction.valueDate}</Moment>}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Auftraggeber:</TableCell>
                                    <TableCell>{props.currentTransaction.fromName}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid item xs={12} md={2} className={classes.allocateTransactionGridItem}>
                        <Typography variant={"h5"}
                                    className={props.currentTransaction.amount < 0 ? classes.neg : classes.pos}>{numeral(props.currentTransaction.amount).format("0.00$")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.allocateTransactionGridItem}>
                        <Grid container alignItems={"center"}>
                            <TextField fullWidth
                                inputRef={props.jumpToInputEl}
                                value={props.title}
                                onChange={(e) => props.setTitle(e.target.value)}
                            >

                            </TextField>
                            <BookingAccountField bookingAccountIdChangedCallback={props.setContraAccountId} bookingAccountId={props.contraAccountId} margin={"dense"} className={classes.textField}/>
                            <CostCenterSelection margin={"dense"} costCenterIdChangedCallback={props.setCostCenterId} costCenterId={props.costCenterId} className={classes.textField}/>
                            <PartnerField partnerId={props.partnerId} partnerIdChangedCallback={props.setPartnerId} className={classes.textField}/>
                            <TaxRate taxRate={props.taxRate} taxRateChangedCallback={props.setTaxRate} variant={"outlined"}/>
                            <Button variant="contained" color="primary" className={classes.button}
                                    disabled={!props.canSubmit}
                                    onClick={props.canSubmit ? props.submitTransactionCallback : undefined}>
                                Buchen
                            </Button>
                            {props.currentTransaction && <MuiLink
                                to={{
                                    pathname: `/banking/${props.bankAccountId}`
                                            + `/transaction/${props.currentTransaction.id}`,
                                    state: { prevPath: window.location.pathname
                            }}}>Zur Transaktion springen</MuiLink>}
                        </Grid>
                    </Grid>
                </Fragment>}


                <Grid item xs={12}>
                    <Paper>
                        <OpenTransactionsTable loading={props.openDataLoading}
                                               openTransactions={props.openTransactions}/>
                    </Paper>
                </Grid>
            </Grid> : <AccountNotFound></AccountNotFound>}
        </Fragment>
    );
};

const AccountNotFound = () => <Fragment>
    <Typography>Account nicht vorhanden.</Typography>
</Fragment>;

export default BankingDetails;