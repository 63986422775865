import React, {useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {BankTransaction} from "../../../models/bankTransaction";
import Moment from "react-moment";
import numeral from "numeral";

interface Props {
    loading: boolean;
    openTransactions: BankTransaction[];
}

type transactionTempValues = {
    [id: string] : {
        title: string;
        bookingAccountId: string;
        costCenterId: string;
    }
}

const OpenTransactionsTable = (props : Props) => {

    const [temporaryValues, setTemporaryValues] = useState<transactionTempValues>({});

    return (
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell>Werstellungsdatum</TableCell>
                    <TableCell>Auftraggeber</TableCell>
                    <TableCell>Beschreibung</TableCell>
                    <TableCell>Betrag</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.openTransactions.map(transaction => <TableRow key={transaction.id}>
                    <TableCell><Moment format="DD.MM.YYYY">{transaction.valueDate}</Moment></TableCell>
                    <TableCell>{transaction.fromName}</TableCell>
                    <TableCell>{transaction.reference}</TableCell>
                    <TableCell>{numeral(transaction.amount).format("0.00$")}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    );
};

export default OpenTransactionsTable;