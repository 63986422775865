import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import BankingDetailsTemplate from "../../../../components/templates/bankingBatch";
import {BankAccountConsumer} from "../../../../stores/bankAccount";
import {useAPI} from "../../../../hooks/useAPI";
import {BankingApi} from "../../../../api/banking";
import {BankTransaction, OpenTransactionData} from "../../../../models/bankTransaction";
import isNonEmptyString from "../../../../util/isNonEmptyString";
import {Tax} from "../../../../models/tax";
import { NotificationConsumer } from '../../../../components/molecules/notification';
import { Notification } from '../../../../components/molecules/notification/types';

interface Props {
    accountId: string;
}

interface PropsInternal extends Props {
    addNotification: (notification: Notification) => void;
}

const IndexInternal = (props : PropsInternal) => {

    const [ fetchedData, openDataLoading, openDataFetchError, retry ] = useAPI<OpenTransactionData>(BankingApi.getTransactionOpenData, props.accountId);
    const [data, setData] = useState<OpenTransactionData | undefined>(undefined);
    useEffect(() => setData(fetchedData), [fetchedData]);

    const currentTransaction : BankTransaction | undefined = useMemo(() => data ? data.openTransactions[0] : undefined,[data]);
    const [title, setTitle] = useState<string>("");
    const [contraAccountId, setContraAccountId] = useState<string | undefined>(undefined);
    const [costCenterId, setCostCenterId] = useState<string | undefined>(undefined);
    const [partnerId, setPartnerId] = useState<string | undefined>(undefined);
    const [taxRate, setTaxRate] = useState<Tax>("Z");

    const jumpToInputEl = useRef(null);

    const canSubmit = useMemo(
        () => isNonEmptyString(contraAccountId) && isNonEmptyString(costCenterId) && isNonEmptyString(title),
        [contraAccountId, costCenterId, title]
    );

    useEffect(() => setTitle(currentTransaction ? currentTransaction.reference : ""), [currentTransaction]);

    const submitCallback = useCallback(
        () => {
            if((isNonEmptyString(title) && currentTransaction !== undefined) && (contraAccountId !== undefined) && (costCenterId !== undefined) && (taxRate !== undefined) && (data !== undefined)) {
                BankingApi.allocateBankTransaction(currentTransaction.id, title, contraAccountId, costCenterId, taxRate, partnerId)
                    .then(() => {
                        props.addNotification({
                            type: "SUCCESS",
                            title: "Erfolgreich verbucht."
                        });
                        setData({
                            openTransactionAmount: data.openTransactionAmount - 1,
                            openTransactions: data.openTransactions.splice(1),
                            openTransactionSumNegative: currentTransaction.amount < 0? data.openTransactionSumNegative - currentTransaction.amount : data.openTransactionSumNegative,
                            openTransactionSumPositive: currentTransaction.amount > 0? data.openTransactionSumPositive - currentTransaction.amount : data.openTransactionSumPositive
                        });
                        setContraAccountId(undefined);
                        setCostCenterId(undefined);
                        setTaxRate("Z");
                        setPartnerId(undefined);
                        if(jumpToInputEl && jumpToInputEl.current ){
                            // @ts-ignore
                            jumpToInputEl.current.focus();
                            // @ts-ignore
                            jumpToInputEl.current.select();
                        }
                    })
                    .catch(() => props.addNotification({
                        type: "ERROR",
                        title: "Fehler bei der Buchung."
                    }));
            } else {
                console.error("One of the params was empty. This should not happen.")
            }

        },
        [contraAccountId, costCenterId, data, taxRate, title, partnerId]
    );


    return (
        <BankAccountConsumer>
            {({bankAccounts}) => <BankingDetailsTemplate
                bankAccountId={props.accountId}
                bankAccount={bankAccounts[props.accountId]}
                openDataLoading={openDataLoading}
                currentTransaction={currentTransaction}
                title={title}
                setTitle={setTitle}
                contraAccountId={contraAccountId}
                setContraAccountId={setContraAccountId}
                costCenterId={costCenterId}
                setCostCenterId={setCostCenterId}
                partnerId={partnerId}
                setPartnerId={setPartnerId}
                taxRate={taxRate}
                setTaxRate={setTaxRate}
                canSubmit={canSubmit}
                submitTransactionCallback={submitCallback}
                jumpToInputEl={jumpToInputEl}
                openTransactionFetchError={openDataFetchError}
                openTransactionAmount={data ? data.openTransactionAmount : 0}
                openNegativeTransactionSum={data ? data.openTransactionSumNegative : 0}
                openPositiveTransactionSum={data ? data.openTransactionSumPositive : 0}
                openTransactions={data ? data.openTransactions.slice(1) : []}
            />}
        </BankAccountConsumer>
    );
};

const Index = (props: Props) => <NotificationConsumer>
    {({addNotification}) => 
        <IndexInternal addNotification={addNotification} accountId={props.accountId} />}
</NotificationConsumer>;

export default Index;