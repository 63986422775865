export function formatDate(date: Date) {

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 101).toString().substring(1);
    const day = (date.getDate() + 100).toString().substring(1);

    return year + "-" + month + "-" + day;
}

export function firstDayOfLastMonth() {
    
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() - 1;

    return new Date(year, month, 1);
}

export function lastDayOfLastMonth() {
    
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    return new Date(year, month, 0);
}