import React, {Component} from 'react';
import {
    AppBar,
    Tab,
    Tabs,
    Theme,
    Typography,
    WithStyles,
    withStyles
} from '@material-ui/core';
import createStyles from "@material-ui/core/styles/createStyles";
import Area from './area';

interface TabContainerProps {
    children?: React.ReactNode;
}
  
const TabContainer = (props: TabContainerProps) => {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

interface Props extends WithStyles<typeof styles> {

}

interface State {
    value: number    
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
});

class TabsArea extends Component<Props, State> {

    readonly state: State = {
        value: 0
    };

    render() {

        const {classes} = this.props;
        const {value} = this.state;

        return (
            <div className={classes.root}>
            <AppBar position="static">
                <Tabs indicatorColor="primary" value={value} onChange={(_event, value) => this.handleChange(value)}>
                {React.Children.map<any, any>(this.props.children, (child: Area) =>
                    <Tab label={child.props.label} />
                )}
                </Tabs>
            </AppBar>
            {React.Children.map<any, any>(this.props.children, (child: Area, index: number) =>
                value === index && <TabContainer>{child.props.children}</TabContainer>
            )}            
            </div>
        );
    }

    private handleChange = (newValue: number) => {
        this.setState({value: newValue});
    }
}

export default withStyles(styles)(TabsArea);