import React, {Fragment} from "react";
import {createStyles, makeStyles, Modal, Paper, Theme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import { Doc } from "../../../models/doc";
import DocumentView from "../../molecules/document";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 900,
            height: '98%',
            padding: '25px 50px',
            boxShadow: theme.shadows[5],
            backgroundColor: grey["100"],
            top: '50%',
            left: '50%',
            overflow: 'auto',
            transform: 'translate(-50%, -50%)'
        },
    }),
);

export const DocumentArea = ({documents, onClose}:
        {documents?: Doc[], onClose: () => void }) => {

    const classes = useStyles();

    return <Fragment>
        <Modal
            open={ documents !== undefined && documents.length > 0 }
            onClose={onClose}
        >
            <Paper className={classes.paper}>
                {documents && documents.length 
                    ? <DocumentView documents={documents} 
                                    documentWrapperStyle={{height: 'calc(100% - 120px)'}} />
                    : <h1>Document Not Loaded</h1>}
            </Paper>
        </Modal>
    </Fragment>;
};