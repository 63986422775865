import React, {Fragment} from 'react';
import {CostCenterConsumer} from "../../../stores/costCenter";
import {createStyles, makeStyles, Theme, Typography} from "@material-ui/core";
import {green} from "@material-ui/core/colors";


interface Props {
    costCenterId?: string;
}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        externalId: {
            color: theme.palette.grey["600"]
        }
    }),
);


const CostCenterLabel = (props: Props) => {

    const classes = useStyles();
    return (
        <CostCenterConsumer>
            {({availableCostCenters}) => (props.costCenterId ? <Fragment>
                {availableCostCenters[props.costCenterId] ? <Typography><span className={classes.externalId}>{availableCostCenters[props.costCenterId].id}</span>&nbsp;&nbsp;{availableCostCenters[props.costCenterId].title}</Typography> : <Typography>Keine Infos zu Kostenstelle</Typography>}
            </Fragment> : <Typography>Keine Kostenstelle</Typography>)}
        </CostCenterConsumer>
    );
};

export default CostCenterLabel;