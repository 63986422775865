import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {DatatableResponse, FilterOption, SortDirection} from "../../../models/datatableOptions";
import {Invoice, InvoiceStatus} from "../../../models/invoice";
import {BankTransaction} from "../../../models/bankTransaction";
import BankTransactionTableBody from "./bankTransactionTableBody";
import {InvoiceApi, InvoiceDatatableColumns} from "../../../api/invoice";
import {BankingApi, BankingDatatableColumns} from "../../../api/banking";
import InvoiceTableBody from "../invoiceTable/invoiceTableBody";
import {append, includes, without} from "ramda";
import {useDebounce} from "../../../hooks/useDebounce";
import BankTransactionTableHeader from "./bankTransactionTableHeader";
import {Tax} from "../../../models/tax";
import BankTransactionTableFooter from "./bankTransactionTableFooter";
import InvoiceTableFooter from "../invoiceTable/invoiceTableFooter";
import isNonEmptyString from "../../../util/isNonEmptyString";
import BankTransactionFilters from "./bankTransactionFilters";
import InvoiceTableFilterRow from "../invoiceTable/InvoiceTableFilters";
import { NotificationConsumer } from '../../molecules/notification';
import { Notification } from '../../molecules/notification/types';

export type availableColumns =
    "NAME"
    | "FROM_NAME"
    | "STATUS"
    | "DATE"
    | "AMOUNT"
    | "TAX_RATE"
    | "TAX"
    | "COST_TYPE"
    | "COST_CENTER"
    | "PARTNER"
    | "FROM_IBAN"
    | "FROM_BIC"
    | "NOTICE";

const apiColumnMap :{[columnKey : string] : BankingDatatableColumns | undefined} = {
    "NAME" : "TITLE",
    "DATE" : "DATUM",
    "FROM_NAME" : "FROM_NAME"
};

const sortableColumns : string[] = Object.keys(apiColumnMap);

export const allTableColumns: availableColumns[] = ["NAME", "FROM_NAME", "STATUS", "DATE", "AMOUNT", "TAX_RATE", "TAX", "COST_TYPE", "COST_CENTER", "PARTNER", "FROM_BIC", "FROM_IBAN", "NOTICE" ];
export const initialTableOrdering: availableColumns[] = ["NAME", "FROM_NAME", "DATE", "AMOUNT", "TAX_RATE", "TAX", "COST_TYPE", "COST_CENTER"];


//bad place to map to backend field titles... TODO
function createFilterOptions(filterTitle: string,
                             filterDate: undefined | [string] | [string, string],
                             filterAmount: undefined | [string] | [string, string],
                             filterTaxRate: undefined | Tax,
                             filterCostType: undefined | string,
                             filterCostCenter: undefined | string,
                             filterPartner: undefined | string,
                             filterIban: string,
                             filterBic: string,
                             filterFromName: undefined | string) {

    let filterOptions: FilterOption<BankingDatatableColumns>[] = [];

    if (isNonEmptyString(filterTitle)) {
        filterOptions.push({filterType: "CONTAINS", filterValue: [filterTitle], field: "TITLE"});
    }

    if (filterDate) {
        filterOptions.push({
            filterType: filterDate.length === 1 ? "IS" : "BETWEEN",
            filterValue: filterDate,
            field: "DATUM"
        });
    }

    if (filterFromName) {
        filterOptions.push({filterType: "CONTAINS", filterValue: [filterFromName], field: "FROM_NAME"});
    }

    if (filterAmount) {
        filterOptions.push({
            filterType: filterAmount.length === 1 ? "IS" : "BETWEEN",
            filterValue: filterAmount,
            field: "AMOUNT"
        })
    }

    if (filterTaxRate) {
        filterOptions.push({filterType: "IS", filterValue: [filterTaxRate], field: "TAX_RATE"});
    }

    if (filterCostType) {
        filterOptions.push({filterType: "IS", filterValue: [filterCostType], field: "COST_TYPE"});
    }

    if (filterCostCenter) {
        filterOptions.push({filterType: "IS", filterValue: [filterCostCenter], field: "COST_CENTER"});
    }

    if (filterPartner) {
        filterOptions.push({filterType: "IS", filterValue: [filterPartner], field: "PARTNER"});
    }

    if (isNonEmptyString(filterBic)) {
        filterOptions.push({filterType: "CONTAINS", filterValue: [filterBic], field: "FROM_BIC"});
    }

    if (isNonEmptyString(filterIban)) {
        filterOptions.push({filterType: "IS", filterValue: [filterIban], field: "FROM_IBAN"});
    }


    return filterOptions.length !== 0 ? filterOptions : undefined;
}


interface Props {
    accountId: string;
}

interface PropsInternal extends Props {
    addNotification: (notification: Notification) => void;
}

const BankTransactionTableInternal = (props : PropsInternal) => {

    //Visible Columns
    const [columnOrderModified, setColumnOrderModified] = React.useState<boolean>(false);
    const [visibleColumns, setVisibleColumns] = React.useState<availableColumns[]>(initialTableOrdering);

    //toggle the visibility of a column. if the column order is user-modified, the column is appended. otherwise, it is toggled in it's original position.
    const toggleColumnVisibility: (columnKey: availableColumns) => void = useMemo(() => (columnKey: availableColumns) => {
        setVisibleColumns(includes(columnKey, visibleColumns) ?
            (without([columnKey], visibleColumns)) :
            (columnOrderModified ? append(columnKey, visibleColumns) : without(without(append(columnKey, visibleColumns), allTableColumns), allTableColumns)));
    }, [visibleColumns]);


    //expansions
    const [expansions, setExpansions] = React.useState<string[]>([]);
    const toggleExpansion: (invoiceId: string) => void = useMemo(() => (invoiceId => includes(invoiceId, expansions) ? setExpansions(without([invoiceId], expansions)) : setExpansions(append(invoiceId, expansions))), [expansions]);


    //datatable values
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [page, setPage] = React.useState(0);
    const [size, setSize] = React.useState(20);
    const [dtResponse, setDtResponse] = React.useState<DatatableResponse<BankTransaction>>({
        elements: {},
        elementCount: 0,
        totalElements: 0,
        page: 0,
        pageSize: 10,
        totalPages: 0
    });

    //filters
    const [filterTitle, setFilterTitle] = useState("");
    const filterTitleDebounced = useDebounce(filterTitle, 500);
    const [filterFromName, setFilterFromName] = useState("");
    const filterFromNameDebounced = useDebounce(filterFromName, 500);
    const [filterDate, setFilterDate] = useState<undefined | [string] | [string, string]>(undefined);
    const [filterAmount, setFilterAmount] = useState<undefined | [string] | [string, string]>(undefined);
    const debouncedFilterAmount = useDebounce(filterAmount, 500);
    const [filterTaxRate, setFilterTaxRate] = useState<undefined | Tax>(undefined);
    const [filterCostType, setFilterCostType] = useState<undefined | string>(undefined);
    const [filterCostCenter, setFilterCostCenter] = useState<undefined | string>(undefined);
    const [filterPartner, setFilterPartner] = useState<undefined | string>(undefined);
    const [filterIban, setFilterIban] = useState("");
    const filterIbanDebounced = useDebounce(filterIban, 500);
    const [filterBic, setFilterBic] = useState("");
    const filterBicDebounced = useDebounce(filterBic, 500);

    const changeFilter = (callback: ((value: any) => void)) => (value: any) => {
                
        // reset page
        setPage(0);
        // set filter value
        callback(value);
    };

    const changeSize = (callback: ((newSize: number) => void)) => (newSize: number) => {

        // if size is bigger than total elements
        if(newSize > dtResponse.totalElements) {
            // reset page
            setPage(0);            
        }
        // set size
        callback(newSize);
    };

    //sort
    const [sort, setSort] = useState<undefined | [availableColumns, SortDirection]>(["DATE", "DESC"]);
    const debouncedSort = useDebounce(sort, 500);
    const toggleSort = useCallback((newSortColumn?: availableColumns) => {

        if(newSortColumn) {
            if(sort) {
                if(sort[0] === newSortColumn) {
                    if (sort[1] === "ASC") setSort(undefined);
                    if (sort[1] === "DESC") setSort([newSortColumn, "ASC"]);
                } else {
                    setSort([newSortColumn, "DESC"]);
                }
            } else {
                setSort([newSortColumn, "DESC"]);
            }
        } else {
            setSort(undefined);
        }

    }, [sort, setSort]);

    //reset bank transaction
    const resetBankTransaction = useCallback((bankTransaction: BankTransaction) => {

        BankingApi.resetBankTransaction(bankTransaction.id)
            .then(data => {
                props.addNotification({
                    type: "SUCCESS",
                    title: "Buchung wurde erfolgreich zurückgesetzt."
                });
                setDtResponse({
                    ...dtResponse,
                    elements: {
                        ...dtResponse.elements,
                        [bankTransaction.id]: data
                    }
                }); 
            })
            .catch(() => props.addNotification({
                type: "ERROR",
                title: "Buchung konnte nicht zurückgesetzt werden."
            }));

    }, [dtResponse]);

    //Reload the DT Endpoint when changes to request parameters occur.
    useEffect(() => {

        setLoading(true);

        BankingApi.getPaginatedBankTransactions(props.accountId)({
            page: page,
            size: size,
            sortDirection : (debouncedSort ? debouncedSort[1] : undefined),
            sortField : sort? apiColumnMap[sort[0]] : undefined,
            filterOptions: createFilterOptions(
                filterTitleDebounced, filterDate, filterAmount,
                filterTaxRate, filterCostType, filterCostCenter,
                filterPartner,filterBicDebounced, filterIbanDebounced, filterFromName
            )
        }).then(data => {            
            setDtResponse(data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        });
    }, [
        page, size, props.accountId, debouncedSort,
        filterTitleDebounced, filterDate, filterAmount,
        filterTaxRate,filterCostType, filterCostCenter,
        filterPartner,filterBicDebounced, filterIbanDebounced, filterFromNameDebounced
    ]);

    return (
        <Fragment>
            <Table size={"small"}>
                <TableHead>
                    <BankTransactionTableHeader
                        visibleColumns={visibleColumns}
                        toggleColumnVisibility={toggleColumnVisibility}
                        sortableColumns={sortableColumns}
                        toggleSort={toggleSort}
                        sort={sort}
                    />

                    <BankTransactionFilters
                        visibleColumns={visibleColumns}
                        filterFromName={filterFromName}
                        setFilterFromName={changeFilter(setFilterFromName)}
                        filterTitle={filterTitle}
                        setFitlerTitle={changeFilter(setFilterTitle)}
                        filterDate={filterDate}
                        setFilterDate={changeFilter(setFilterDate)}
                        filterAmount={filterAmount}
                        setFilterAmount={changeFilter(setFilterAmount)}
                        filterTaxRate={filterTaxRate}
                        setFilterTaxRate={changeFilter(setFilterTaxRate)}
                        filterCostType={filterCostType}
                        setFilterCostType={changeFilter(setFilterCostType)}
                        filterCostCenter={filterCostCenter}
                        setFilterCostCenter={changeFilter(setFilterCostCenter)}
                        filterPartner={filterPartner}
                        setFilterPartner={changeFilter(setFilterPartner)}
                        filterIban={filterIban}
                        setFilterIban={changeFilter(setFilterIban)}
                        filterBic={filterBic}
                        setFilterBic={changeFilter(setFilterBic)}
                    />
                </TableHead>

                <BankTransactionTableBody
                    isLoading={loading}
                    visibleColumns={visibleColumns}
                    toggleExpansion={toggleExpansion}

                    elements={dtResponse.elements}
                    expansions={expansions}

                    onResetBankTransaction={resetBankTransaction}
                />

                <BankTransactionTableFooter
                    visibleColumns={visibleColumns}
                    page={page}
                    pageChangedCallback={setPage}
                    rowsPerPage={size}
                    rowsPerPageChangedCallback={changeSize(setSize)}
                    totalElements={dtResponse.totalElements}
                />
            </Table>
        </Fragment>
    );
};

const BankTransactionTable = (props: Props) => <NotificationConsumer>
    {({addNotification}) => <BankTransactionTableInternal
            accountId={props.accountId} addNotification={addNotification} />}
</NotificationConsumer>;

export default BankTransactionTable;