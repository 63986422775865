import {InvoiceAccountingTransaction} from "../../../models/accountingTransaction";
import {Tax, taxRates} from "../../../models/tax";
import {Invoice} from "../../../models/invoice";

const fnAdd = (accumulator: number, currentValue: number) => accumulator + currentValue;

const sumInvoiceItems = (invoiceItems: InvoiceAccountingTransaction[]) => 
        invoiceItems.map(value => value.amount).reduce(fnAdd, 0);
const sumInvoiceAmounts = (invoices: Invoice[]) => 
        invoices.map((value) => sumInvoiceItems(value.accountingTransactions)).reduce(fnAdd, 0);

const calculateTaxesFromGrossAmount = (grossAmount: number, taxRate: Tax) => 
        ((grossAmount / (1 + taxRates[taxRate].rate)) * taxRates[taxRate].rate);

const sumInvoiceItemTaxes = (invoiceItems: InvoiceAccountingTransaction[]) =>
        invoiceItems.map(value => calculateTaxesFromGrossAmount(value.amount, value.taxRate)).reduce(fnAdd, 0);
const sumInvoiceTaxes = (invoices: Invoice[]) => invoices.map((value) => 
        sumInvoiceItemTaxes(value.accountingTransactions)).reduce(fnAdd, 0);

export {fnAdd, sumInvoiceItems, sumInvoiceAmounts, calculateTaxesFromGrossAmount, sumInvoiceItemTaxes, sumInvoiceTaxes}