import React, {Component, Fragment} from 'react';
import DataTransferTemplate from "../../components/templates/datatransfer";
import {fromPairs, mergeAll, omit} from "ramda";
import {adalApiFetch} from "../../adalConfig";
import axios from "axios";
import { BankAccountConsumer } from '../../stores/bankAccount';
import { BankAccount } from '../../models/masterData';
import {BankTransactionImport, BankTransactionImportResponse} from '../../models/bankTransaction';
import { BankingApi } from "../../api/banking"
import { ExportStatus, DatevExport } from '../../models/datevExport';
import { DatevExportApi } from '../../api/datev';
import { NotificationConsumer } from '../../components/molecules/notification';
import { Notification } from '../../components/molecules/notification/types';
import { formatDate, firstDayOfLastMonth, lastDayOfLastMonth } from '../../util/dateUtil';
import BreadCrumb from '../../components/molecules/breadCrumb';
import BreadCrumbItem from '../../components/molecules/breadCrumb/breadCrumbItem';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    content: {
        width: '100%',
        marginTop: theme.spacing(),
        paddingTop: theme.spacing()       
    }
});

interface Props extends WithStyles<typeof styles> {
    addNotification: (notification: Notification) => void;
    bankAccounts: {[id: string]: BankAccount};
}

interface State {
    bankData: {
        selectedFiles: { [key: string]: File },
        selectedAccounts: { [key: string]: string },
    },
    bankImportModalOpen: boolean;

    latestImports: { [key: string]: BankTransactionImport };
    latestBookingDates: { [key: string]: string };
    importResult: { [key: string]: BankTransactionImportResponse};

    exportStatus: ExportStatus;
    exportModalOpen: boolean;
    exportModalData: DatevExport;
    exportInProgress: boolean;
}

const INITIAL_BANK_DATA = {
    selectedFiles: {},
    selectedAccounts: {}
}

const INITIAL_EXPORT_MODAL_DATA = {
    beraterNummer: 626436,
    mandantenNummer: 11111,
    buchungsstapelBeginn: formatDate( firstDayOfLastMonth() ),
    buchungsstapelEnde: formatDate( lastDayOfLastMonth() ),
    fullExport: false
}

const INITIAL_STATE: State = {
    bankData: INITIAL_BANK_DATA,
    bankImportModalOpen: false,
    latestImports: {},
    latestBookingDates: {},
    importResult: {},
    
    exportStatus: {},
    exportModalOpen: false,
    exportModalData: INITIAL_EXPORT_MODAL_DATA,
    exportInProgress: false
}

function fileListToIndexedObject(newFiles: File[]) {
    return fromPairs(newFiles.map(value => ([buildFileIdentifier(value), value])))
}

function buildFileIdentifier(file: File) {
    return `${file.name}-${file.lastModified}-${file.size}`;
}


class Index extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        this.loadLatestImportInformations();
        this.loadExportStatus();
    }

    loadLatestImportInformations = () => {

        BankingApi.getLatestImportsForBankAccounts()
            .then((dtResponse) => this.setState({
                latestImports: dtResponse,
            }))
            .catch(console.log);

        BankingApi.getLatestBookingDatesForBankAccounts()
            .then((dtResponse) => this.setState({
                latestBookingDates: dtResponse,
            }))
            .catch(console.log);
    }

    loadExportStatus = () => {

        DatevExportApi.getExportStatus()
            .then((status: ExportStatus) => this.setState({ exportStatus: status }))
            .catch(console.log);
    }

    canUploadFiles = () => {
        if (Object.keys(this.state.bankData.selectedFiles).length === 0) {
            return false;
        }

        for (const value of Object.keys(this.state.bankData.selectedFiles)) {
            if (this.state.bankData.selectedAccounts[value] === undefined) {
                return false;
            }
        }

        return true;

    };

    removeFile = (fileId: string) => this.setState({
        bankData: {
            selectedFiles: omit([fileId], this.state.bankData.selectedFiles),
            selectedAccounts: omit([fileId], this.state.bankData.selectedAccounts),

        }
    })


    addFiles = (newFiles: File[]) => {

        let newBankAccountIds = {};

        for(let file of newFiles) {
            let bankAccount = Object.values(this.props.bankAccounts).find(bankAccount =>  file.name.includes(bankAccount.id));

            if(bankAccount) {
                newBankAccountIds = {...newBankAccountIds, [buildFileIdentifier(file)] : bankAccount.id}
            }
        }        

        this.setState({
            bankData: {
                selectedFiles: mergeAll([this.state.bankData.selectedFiles, fileListToIndexedObject(newFiles)]),
                selectedAccounts: {...this.state.bankData.selectedAccounts, ...newBankAccountIds}
            }
        });
    }


    changeAccountSelectionForFile = (selectedAccountId: string, fileKey: string) => this.setState({...this.state,
        bankData: {
            ...this.state.bankData,
            selectedAccounts: {...this.state.bankData.selectedAccounts, [fileKey]: selectedAccountId}
        }
    })

    uploadFiles = () => {
        let data = new FormData();

        Object.entries(this.state.bankData.selectedFiles).forEach(
            (entry) => {
                data.append("accountId", this.state.bankData.selectedAccounts[entry[0]]);
                data.append("file", entry[1]);
            }
        );

        adalApiFetch(axios, "/api/banking/import", {
            method: "POST",
            data: data,
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then((response) => {
                this.props.addNotification({
                    type: "SUCCESS",
                    title: "Import erfolgreich durchgeführt."
                });
                this.setState({ importResult: response.data });
                this.loadLatestImportInformations();
            })
            .catch(() => this.props.addNotification({
                type: "ERROR",
                title: "Import fehlgeschlagen."
            }));        
    };
    
    setBankImportModalOpen = (open: boolean) => this.setState({ bankImportModalOpen: open });

    setExportModalOpen = (open: boolean) => this.setState({ exportModalOpen: open });
    setExportInProgress = (inProgress: boolean) => this.setState({ exportInProgress: inProgress });

    setBeraterNummer = (value: number) => this.setState({
        exportModalData: {
            ...this.state.exportModalData,
            beraterNummer: value
        }
    });

    setMandantenNummer = (value: number) => this.setState({
        exportModalData: {
            ...this.state.exportModalData,
            mandantenNummer: value
        }
    });

    setBuchungsstapelBeginn = (value: string) => this.setState({
        exportModalData: {
            ...this.state.exportModalData,
            buchungsstapelBeginn: value
        }
    });

    setBuchungsstapelEnde = (value: string) => this.setState({
        exportModalData: {
            ...this.state.exportModalData,
            buchungsstapelEnde: value
        }
    });

    toggleFullExport = () => this.setState({
        exportModalData: {
            ...this.state.exportModalData,
            fullExport: !(this.state.exportModalData.fullExport)
        }
    });

    canExport = () => {

        const { beraterNummer, mandantenNummer,
                buchungsstapelBeginn, buchungsstapelEnde } = this.state.exportModalData;
        const [ beginn, ende ] = [new Date(buchungsstapelBeginn), new Date(buchungsstapelEnde)];

        return beraterNummer > 0 && mandantenNummer > 0 && beginn < ende;
    };


    datevExport = () => {

        this.setState({
            exportModalOpen: false,
            exportInProgress: true
        });

        DatevExportApi.datevExport(this.state.exportModalData)
            .then((status: ExportStatus) => {
                this.props.addNotification({
                    type: "SUCCESS",
                    title: "Export erfolgreich durchgeführt."
                });
                this.setState({                    
                    exportStatus: status,
                    exportInProgress: false
                });                
            })
            .catch(() => {
                this.props.addNotification({
                    type: "ERROR",
                    title: "Export fehlgeschlagen."
                });
                this.setExportInProgress(false);
            });
    }

    render() {

        const { classes } = this.props;

        return (
            <Fragment>
                <main className="section">
                    <div className="container">
                        <BreadCrumb>
                            <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                            <BreadCrumbItem to={"/datatransfer"} title={"Datentransfer"}/>
                        </BreadCrumb>

                        <div className={classes.content}>
                            <DataTransferTemplate
                                bankImportProps={{
                                    importResult: this.state.importResult,
                                    selectedFiles: this.state.bankData.selectedFiles,
                                    selectedAccounts: this.state.bankData.selectedAccounts,
                                    canUploadFiles: this.canUploadFiles(),
                                    accountSelectionChangedCallback: this.changeAccountSelectionForFile,
                                    fileRemovedCallback: this.removeFile,
                                    filesAddedCallback: this.addFiles,
                                    uploadFilesClickedCallback: this.uploadFiles,
                                    bankImportModalOpen: this.state.bankImportModalOpen,
                                    setBankImportModalOpen: this.setBankImportModalOpen,
                                    bankAccounts: this.props.bankAccounts,
                                    latestImports: this.state.latestImports,
                                    latestBookingDates: this.state.latestBookingDates
                                }}
                                datevExportProps={{
                                    status: this.state.exportStatus,
                                    modalOpen: this.state.exportModalOpen,                                       
                                    setModalOpen: this.setExportModalOpen,
                                    modalData: this.state.exportModalData, 
                                    modalDataValid: this.canExport(),
                                    exportClickedCallback: this.datevExport,
                                    beraterNummerChangedCallback: this.setBeraterNummer,
                                    mandantenNummerChangedCallback: this.setMandantenNummer,
                                    buchungsstapelBeginnChangedCallback: this.setBuchungsstapelBeginn,
                                    buchungsstapelEndeChangedCallback: this.setBuchungsstapelEnde,
                                    fullExportChangedCallback: this.toggleFullExport,
                                    exportInProgress: this.state.exportInProgress
                                }}
                            />
                        </div>
                    </div>
                </main>
            </Fragment>
        );
    }
}

const IndexWithStyles = withStyles(styles)(Index);

const DataTransferSceneWrapper = () => <NotificationConsumer>
    {({addNotification}) => <BankAccountConsumer>
        {({bankAccounts}) => <IndexWithStyles addNotification={addNotification} bankAccounts={bankAccounts} />}
</BankAccountConsumer>}</NotificationConsumer>;

export default DataTransferSceneWrapper;