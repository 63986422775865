import React, {Fragment} from 'react';
import {
    Button,
    createStyles,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    TableHead,
    CircularProgress,
    Box
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import BankTransactionImportDialog from "../../organisms/bankTransactionImport";
import CloseIcon from '@material-ui/icons/Close';
import {BankTransactionImport, BankTransactionImportResponse} from '../../../models/bankTransaction';
import { BankAccount } from '../../../models/masterData';
import Moment from "react-moment"
import { ExportStatus, DatevExport } from '../../../models/datevExport';
import DatevExportDialog from '../../organisms/datevExport';
import { CloudDownload, TableChart } from '@material-ui/icons';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            textAlign: "center"
        },
        icon: {
            fontSize: "400%",
            color: theme.palette.grey.A200
        },
        button: {
            margin: theme.spacing(1),
        },
        table: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        }
    }),
);

interface Props {

    bankImportProps: {

        selectedFiles: { [key: string]: File };
        filesAddedCallback: (newFiles: File[]) => void;
        fileRemovedCallback: (fileId: string) => void;

        selectedAccounts: { [key: string]: string };
        accountSelectionChangedCallback: (selectedAccountId: string, fileKey: string) => void;

        canUploadFiles: boolean;
        uploadFilesClickedCallback: () => void;
        bankImportModalOpen: boolean;
        setBankImportModalOpen: (open: boolean) => void;

        bankAccounts: { [key : string] : BankAccount };
        latestImports: { [key : string] : BankTransactionImport };
        latestBookingDates: { [key : string] : string };
        importResult: { [key: string]: BankTransactionImportResponse};
    };
    datevExportProps: {

        status: ExportStatus;
        
        modalOpen: boolean;
        setModalOpen: (open: boolean) => void;
        
        modalData: DatevExport;
        modalDataValid: boolean;
        exportClickedCallback: () => void;        

        beraterNummerChangedCallback: (value: number) => void;
        mandantenNummerChangedCallback: (value: number) => void;
        buchungsstapelBeginnChangedCallback: (value: string) => void;
        buchungsstapelEndeChangedCallback: (value: string) => void;
        fullExportChangedCallback: () => void;

        exportInProgress: boolean;
    };
}

const DataTransferTemplate = (props: Props) => {

    const classes = useStyles();

    const { bankImportProps, datevExportProps } = props;
    const { bankAccounts, latestImports, latestBookingDates } = bankImportProps;
    const { pendingBookingEntries, lastDatevExport } = datevExportProps.status;

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Paper className={classes.paper}>                        
                        { !(datevExportProps.exportInProgress) && <Fragment>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography variant="h5">DATEV-Export</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Link to="/datatransfer/export">
                                        <IconButton size="medium" color="default" aria-label="history">
                                            <TableChart fontSize="inherit"/>
                                        </IconButton>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Table size={"small"} className={classes.table}>
                                <TableBody>                                
                                    <TableRow>
                                        <TableCell>Ausstehende Buchungen</TableCell>
                                        <TableCell>{ pendingBookingEntries !== undefined
                                            ? pendingBookingEntries
                                            : "N/A" }</TableCell>
                                    </TableRow>
                                    { lastDatevExport && <Fragment>
                                        <TableRow>
                                            <TableCell>Letzter Datenexport</TableCell>
                                            <TableCell>
                                                <Moment format="DD.MM.YYYY HH:mm">{lastDatevExport.exportZeitpunkt}</Moment>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Exportiert von</TableCell>
                                            <TableCell>{lastDatevExport.exportiertVon}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Beginn</TableCell>
                                            <TableCell>
                                                <Moment format="DD.MM.YYYY">{lastDatevExport.buchungsstapelBeginn}</Moment>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Ende</TableCell>
                                            <TableCell>
                                                <Moment format="DD.MM.YYYY">{lastDatevExport.buchungsstapelEnde}</Moment>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Letzte Exportdatei</TableCell>
                                            <TableCell>{lastDatevExport.filename}</TableCell>
                                        </TableRow>
                                    </Fragment> }                                
                                    { !(lastDatevExport) && <TableRow>
                                        <TableCell colSpan={2} align="center">Bisher wurde kein Export durchgeführt.</TableCell>
                                    </TableRow> }
                                </TableBody>
                            </Table>
                        </Fragment> }
                        { datevExportProps.exportInProgress && <Box style={{margin: 50}}>
                            <CircularProgress size={200} />
                            <Typography color="primary" style={{fontSize: 18, fontWeight: 500, marginTop: 20}}>
                                Export in Bearbeitung
                            </Typography>
                        </Box> } 
                        
                        { !(datevExportProps.exportInProgress) && <Grid container spacing={3}>
                            { lastDatevExport && <Grid item xs={6}>
                                <Link to={`/api/datev/export/file/${lastDatevExport.id}`} target="_blank">
                                    <Button variant="contained" color="default"
                                            className={classes.button}>
                                            Export herunterladen                                           
                                    </Button>
                                </Link>
                            </Grid> }
                            <Grid item xs={lastDatevExport ? 6 : 12}>
                                <Button variant="contained"
                                        color="primary" className={classes.button} onClick={() => props.datevExportProps.setModalOpen(true)}>
                                    Neuen Export erstellen
                                </Button>
                            </Grid>
                        </Grid>}       
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Typography variant="h5">Bankdaten-Import</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Link to="/datatransfer/import">
                                    <IconButton size="medium" color="default" aria-label="history">
                                        <TableChart fontSize="inherit"/>
                                    </IconButton>
                                </Link>
                            </Grid>
                        </Grid>

                        <Table size={"small"} className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Bankkonto</TableCell>
                                    <TableCell>Letzter Import</TableCell>
                                    <TableCell>Importiert von</TableCell>
                                    <TableCell>Letztes Buchungsdatum</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { Object.values(bankAccounts).map(( account ) => <TableRow>
                                    <TableCell>{account.title}</TableCell>
                                    <TableCell>{ latestImports[account.id]
                                            ? <Moment format="DD.MM.YYYY HH:mm">{latestImports[account.id].importTimestamp}</Moment>
                                            : "N/A" }</TableCell>
                                    <TableCell>{ latestImports[account.id]
                                            ? latestImports[account.id].importedBy
                                            : "N/A" }</TableCell>
                                    <TableCell>{ latestBookingDates[account.id]
                                            ? <Moment format="DD.MM.YYYY">{latestBookingDates[account.id]}</Moment>
                                            : "N/A" }</TableCell>
                                </TableRow>) }                                
                            </TableBody>
                        </Table>

                        <Button variant="contained" color="primary" className={classes.button} onClick={() => props.bankImportProps.setBankImportModalOpen(true)}>
                            Neue Umsätze importieren
                        </Button>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog open={props.bankImportProps.bankImportModalOpen} fullWidth={true} maxWidth={"lg"}
                    onClose={() => props.bankImportProps.setBankImportModalOpen(false)}>
                <DialogTitle>
                    <Typography>Umsätze importieren</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => props.bankImportProps.setBankImportModalOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <BankTransactionImportDialog
                        importResult={props.bankImportProps.importResult}
                        selectedFiles={props.bankImportProps.selectedFiles}
                        filesAddedCallback={props.bankImportProps.filesAddedCallback}
                        fileRemovedCallback={props.bankImportProps.fileRemovedCallback}

                        selectedAccounts={props.bankImportProps.selectedAccounts}
                        accountSelectionChangedCallback={props.bankImportProps.accountSelectionChangedCallback}

                        canUploadFiles={props.bankImportProps.canUploadFiles}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color={props.bankImportProps.canUploadFiles ? "primary" : undefined}
                        variant="contained"
                        onClick={props.bankImportProps.canUploadFiles ? props.bankImportProps.uploadFilesClickedCallback : undefined}
                    >
                        Hochladen
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={datevExportProps.modalOpen}
                    onClose={() => datevExportProps.setModalOpen(false)}>
                <DialogTitle>
                    <Typography>DATEV-Export erstellen</Typography>
                    <IconButton aria-label="close" className={classes.closeButton}
                            onClick={() => datevExportProps.setModalOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ height: 300 }}>
                    <DatevExportDialog
                        data={datevExportProps.modalData}
                        beraterNummerChangedCallback={datevExportProps.beraterNummerChangedCallback}
                        mandantenNummerChangedCallback={datevExportProps.mandantenNummerChangedCallback}
                        buchungsstapelBeginnChangedCallback={datevExportProps.buchungsstapelBeginnChangedCallback}
                        buchungsstapelEndeChangedCallback={datevExportProps.buchungsstapelEndeChangedCallback}
                        fullExportChangedCallback={datevExportProps.fullExportChangedCallback}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color={datevExportProps.modalDataValid ? "primary" : undefined}
                        variant="contained"
                        onClick={ datevExportProps.modalDataValid 
                                ? datevExportProps.exportClickedCallback
                                : undefined }
                    >
                        Exportieren
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default DataTransferTemplate;