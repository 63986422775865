import React from 'react';
import {
    createStyles,
    FormControl,
    InputLabel, makeStyles,
    MenuItem,
    OutlinedInput,
    Select,
    Theme,
    WithStyles
} from "@material-ui/core";
import {Tax, taxRateArray, taxRates} from "../../../models/tax";
import {withStyles} from "@material-ui/styles";



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
    }),
);

interface Props {
    emptyAllowed?: boolean;
    emptyTitle?: string;
    taxRate?: Tax;
    taxRateChangedCallback: (tax : Tax) => void;
    inputLabel?: string;
    variant?: 'standard' | 'outlined' | 'filled';
    narrow?: boolean;
}

const EMPTY_KEY = "_NONE";

const TaxRate = (props: Props) => {

    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} variant={props.variant} margin={"dense"}>
            {props.inputLabel && <InputLabel>{props.inputLabel ? props.inputLabel : "Steuersatz"}</InputLabel>}
            <Select
                style={{minWidth: props.narrow ? "0" : undefined }}
                variant={props.variant}
                autoWidth={true}
                value={props.taxRate ? props.taxRate : EMPTY_KEY}
                input={props.variant === "outlined" ? <OutlinedInput labelWidth={0} name={props.inputLabel ? props.inputLabel : "Steuersatz"}/> : undefined   }
                onChange={(event) => props.taxRateChangedCallback(event.target.value === EMPTY_KEY ? undefined : (event.target.value as any))}
            >
                {props.emptyAllowed && <MenuItem value={EMPTY_KEY}>{props.emptyTitle}</MenuItem>}
                {taxRateArray.filter(value => value.active).map(rate =>
                    <MenuItem key={rate.key} value={rate.key}>{rate.label}</MenuItem>)}
            </Select>
        </FormControl>
    );
};

export default TaxRate;
