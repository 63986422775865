import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {IconButton, InputAdornment, PropTypes, TextField} from "@material-ui/core";
import {Partner} from "../../../models/masterData";
import isNonEmptyString from "../../../util/isNonEmptyString";
import {Edit} from "@material-ui/icons";
import {PartnerConsumer} from "../../../stores/partner";


interface Props {
    partnerId?: string;
    partnerIdChangedCallback?: (id?: string) => void;
    emptyText?: string;

    fullWidth?: boolean;
    inputProps?: object;
    readonly?: boolean;
    variant?: any;
    label?: string;
    margin?: PropTypes.Margin;
    disabled?: boolean;
    className?: string;
    inputRef?: any;
}

interface InternalProps extends Props {
    partners: {
        [id: string]: Partner
    };
    openModal?: (func?: (id: string) => void) => void;
}

const PartnerFieldInternal = (props: InternalProps) => {

    useEffect(() => {
        if (!props.readonly && props.partnerIdChangedCallback === undefined) {
            console.error("You did not pass a prop called partnerIdChangedCallback when in editable mode.");
        }
    }, [props.readonly, props.partnerIdChangedCallback]);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [tempValue, setTempValue] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const handleChange = useCallback((e) => {
        setEditMode(true);
        setTempValue(e.target.value);
    }, []);

    const handleBlur = useCallback(() => {
        if (editMode) {
            let account = Object.entries(props.partners).find(value => value[1].applicantNumber === tempValue);

            setError(false);
            setEditMode(false);

            if (account) {

                if (props.partnerIdChangedCallback)
                    props.partnerIdChangedCallback(account[0]);
            }


        }

    }, [props.partners, props.partnerIdChangedCallback, tempValue, setError, editMode]);

    const handleKeyPress = useCallback((e) => {
        if (e.key === 'Enter') {
            if(isNonEmptyString(tempValue)) {
                let account = Object.entries(props.partners).find(value => value[1].applicantNumber === tempValue);

                if (account) {
                    setError(false);
                    setEditMode(false);
                    if (props.partnerIdChangedCallback)
                        props.partnerIdChangedCallback(account[0]);
                } else {
                    setError(true);
                }
            } else {
                if(props.partnerIdChangedCallback) {
                    props.partnerIdChangedCallback();
                }
            }
        }
    }, [props.partners, props.partnerIdChangedCallback, tempValue, setError, editMode]);


    return (
        <Fragment>
            <TextField
                fullWidth={props.fullWidth}
                inputRef={props.inputRef}
                className={props.className}
                disabled={props.disabled}
                variant={props.variant}
                label={props.label}
                margin={props.margin}
                onChange={props.readonly ? undefined : handleChange}
                onKeyPress={props.readonly ? undefined : handleKeyPress}
                onBlur={props.readonly ? undefined : handleBlur}
                error={error}
                value={editMode ? tempValue : (props.partnerId ? (props.partners[(props.partnerId)] ? props.partners[(props.partnerId)].partnerName : props.partnerId) : (props.emptyText ? props.emptyText : "Kein Gesellschafter gewählt"))}
                InputProps={{
                    ...props.inputProps,
                    startAdornment: isNonEmptyString(props.partnerId) ? <InputAdornment
                        //@ts-ignore
                        position="start">{(props.partners[(props.partnerId)] ? props.partners[(props.partnerId)].applicantNumber : "")}</InputAdornment> : undefined,
                    endAdornment: props.readonly ? undefined : <InputAdornment position={"end"}>
                        <IconButton aria-label="Kostenart ändern" tabIndex={-1}
                                    onClick={() => (props.openModal) ? props.openModal(props.partnerIdChangedCallback) : {}}>
                            <Edit/>
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </Fragment>
    );
};

const PartnerField = (props: Props) => <PartnerConsumer>
    {({partners, openModal}) => <PartnerFieldInternal
        partners={partners}
        openModal={openModal}
        {...props}
    />}
</PartnerConsumer>;

export default PartnerField;