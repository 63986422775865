import {Invoice} from "../../../models/invoice";
import React, {FormEvent, Fragment, useMemo} from "react";
import {
    createStyles,
    IconButton,
    ListItemIcon,
    makeStyles,
    Menu,
    MenuItem,
    TableBody,
    TableCell,
    TableRow,
    Theme
} from "@material-ui/core";
import {add, includes, uniq} from "ramda";
import axios from "axios";
import {ColumnVisibilityProps} from "./types";
import {CloudUpload, MoreHoriz, OpenInBrowser, Edit} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {ExpansionIcon} from "../../atoms/expansionIcon";
import Moment from "react-moment";
import numeral from "numeral";
import {taxRates} from "../../../models/tax";
import BookingAccountLabel from "../../atoms/bookingAccountLabel";
import isNonEmptyString from "../../../util/isNonEmptyString";
import CostCenterLabel from "../../atoms/costCenterLabel";
import PartnerLabel from "../../atoms/partnerLabel";
import {AccountingTransaction, InvoiceAccountingTransaction} from "../../../models/accountingTransaction";
import {calculateTaxesFromGrossAmount, sumInvoiceItems} from "./utils";
import {BookingAccountConsumer} from "../../../stores/bookingAccount";
import {CostCenterConsumer} from "../../../stores/costCenter";
import {invoiceItemCountDependentRenderer} from "./renderingUtils";
import {availableColumns} from "./index";
import { Link } from "react-router-dom";
import { Doc } from "../../../models/doc";

/**
 * Invoice Table Body
 */
interface InvoiceTableBodyProps extends ColumnVisibilityProps {
    isLoading?: boolean;
    elements: {
        [id: string]: Invoice
    };
    expansions: string[];
    toggleExpansion: (invoiceId: string) => void;
    documentUploadCallback: (formData: FormData, invoice: Invoice) => void;
    openDocumentAreaCallback: (documents: Doc[]) => void;
}

function generateKey(invoice: Invoice) {
    return `${invoice.id}`;
    // TODO: return `${invoice.id}_${invoice.documentId}`
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableOverlay: {
            background: theme.palette.grey.A200,
            height: "100%",
            width: "100%",
            opacity: 0.1,
            top: 0,
            left: 0,
            position: "absolute",
            padding: 0
        },
        link: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    }),
);

const InvoiceTableBody = (props: InvoiceTableBodyProps) => {

    const classes = useStyles();

    const elementIds = useMemo(() => Object.keys(props.elements), [props.elements]);

    return <TableBody style={{position: "relative"}}>
        {elementIds.length > 0 ? elementIds.map((invoiceId) => <Fragment>
            <TableRow key={generateKey(props.elements[invoiceId])}>
                {props.visibleColumns.map(columnKey => TableCellRenderers[columnKey]({
                    id: invoiceId,
                    invoice: props.elements[invoiceId],
                    toggleExpansion: props.toggleExpansion,
                    expansion: includes(invoiceId, props.expansions)
                }))}

                <MoreActionsTableCell
                    invoice={props.elements[invoiceId]}
                    documentUploadCallback={props.documentUploadCallback}
                    openDocumentAreaCallback={props.openDocumentAreaCallback}
                />
            </TableRow>

            {includes(invoiceId, props.expansions) && props.elements[invoiceId].accountingTransactions.map(accountingTransaction =>
                <AccountingTransactionTableRow
                    key={invoiceId + "_" + accountingTransaction.id}
                    visibleColumns={props.visibleColumns}
                    accountingTransaction={accountingTransaction}/>)}
        </Fragment>) : <TableRow>
            <TableCell align="center" colSpan={props.visibleColumns.length+1}>Keine Daten Vorhanden.</TableCell>
        </TableRow>}

        {props.isLoading && <div className={classes.tableOverlay} />}

        </TableBody>;
};

interface MoreActionsTableCellProps {
    invoice: Invoice;
    documentUploadCallback: (formData: FormData, invoice: Invoice) => void;
    openDocumentAreaCallback: (documents: Doc[]) => void;
}

const MoreActionsTableCell = (props: MoreActionsTableCellProps) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleFileUpload(event: FormEvent<HTMLInputElement>) {

        if (event.currentTarget.files) {

            const files = event.currentTarget.files;
            let data = new FormData();

            for(let i = 0; i < files.length; i++) {

                const fileItem = files.item(i);

                if(fileItem !== null) {                
                    data.append("file", fileItem);
                }
            }
            props.documentUploadCallback(data, props.invoice);
            handleClose();
        }
    }

    return <TableCell>
        <IconButton aria-label="Aktionen" onClick={handleClick}
                    size="small" style={{padding: 0}}>
            <MoreHoriz/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <Link className={classes.link} to={{
                        pathname: "/invoice/edit/" + props.invoice.id,
                        state: { prevPath: window.location.pathname
            }}}>
                <MenuItem>
                    <ListItemIcon><Edit/></ListItemIcon>
                    <Typography>Beleg bearbeiten</Typography>
                </MenuItem>
            </Link>
            <MenuItem disabled={props.invoice.documents.length < 1}
                      onClick={() => {
                        if (props.invoice.documents.length > 0) {
                            handleClose();
                            props.openDocumentAreaCallback(
                                props.invoice.documents
                            );
                        }
                      }}>
                <ListItemIcon><OpenInBrowser/></ListItemIcon>
                <Typography>
                    Dokumente anzeigen
                </Typography>
            </MenuItem>

            <input
                multiple
                style={{display: "none"}}
                accept="application/pdf"
                id={"invoice-" + props.invoice.id + "-document"}
                type="file"
                onChange={handleFileUpload}
            />
            <label htmlFor={"invoice-" + props.invoice.id + "-document"}>
                <MenuItem>
                    <ListItemIcon><CloudUpload/></ListItemIcon>
                    <Typography>Dokumente einreichen</Typography>
                </MenuItem>
            </label>
        </Menu>
    </TableCell>;
};


/**
 *
 * Cell Definitions
 *
 */
const InvoiceTitleTableCell = ({id, invoice, expansion, toggleExpansion}: { id: string, invoice: Invoice, toggleExpansion: (invoiceId: string) => void, expansion: boolean }) => invoiceItemCountDependentRenderer(
    <TableCell>{invoice.title}</TableCell>,
    <TableCell style={{cursor: "pointer"}} onClick={() => toggleExpansion(id)}><ExpansionIcon expanded={expansion}
                                                                                              disabled={false}/> {invoice.title}
    </TableCell>)(invoice);

const InvoiceDateTableCell = ({invoice}: { invoice: Invoice }) => <TableCell><Moment
    format="DD.MM.YYYY">{invoice.date}</Moment></TableCell>;
const InvoiceAmountTableCell = ({invoice}: { invoice: Invoice }) =>
    <TableCell>{numeral(sumInvoiceItems(invoice.accountingTransactions)).format("0.00$")}</TableCell>;
const InvoiceTaxRateTableCell = ({invoice}: { invoice: Invoice }) => invoiceItemCountDependentRenderer(
    <TableCell>{taxRates[invoice.accountingTransactions[0].taxRate].label}</TableCell>,
    <TableCell>{uniq(invoice.accountingTransactions.map((iv => iv.taxRate))).map(taxRateKey => taxRates[taxRateKey].label).join(", ")}</TableCell>)(invoice);
const InvoiceTaxTableCell = ({invoice}: { invoice: Invoice }) =>
    <TableCell>{numeral(invoice.accountingTransactions.map(accountingTransaction => calculateTaxesFromGrossAmount(accountingTransaction.amount, accountingTransaction.taxRate)).reduce(add)).format("0.00$")}</TableCell>;

const InvoiceCostTypeDataCell = ({invoice}: { invoice: Invoice }): any => invoiceItemCountDependentRenderer(
    <TableCell><BookingAccountLabel bookingAccountId={invoice.accountingTransactions[0].contraAccountId}/></TableCell>,
    <TableCell>
        {uniq(invoice.accountingTransactions.map(trans => trans.contraAccountId)).length <= 1 ?
            <BookingAccountLabel bookingAccountId={invoice.accountingTransactions[0].contraAccountId}/> :
            <ExternalIdBookingAccountList
                bookingAccountIds={invoice.accountingTransactions.map(trans => trans.contraAccountId).filter(id => isNonEmptyString(id))}/>}
    </TableCell>
)(invoice);

const InvoiceCostCenterTableCell = ({invoice}: { invoice: Invoice }) => invoiceItemCountDependentRenderer(
    <TableCell><CostCenterLabel costCenterId={invoice.accountingTransactions[0].costCenterId}/></TableCell>,
    <TableCell>
        {uniq(invoice.accountingTransactions.map(trans => trans.costCenterId)).length <= 1 ?
            <CostCenterLabel costCenterId={invoice.accountingTransactions[0].costCenterId}/> :
            <ExternalIdCostCenterList
                costCenterIds={invoice.accountingTransactions.map(trans => trans.costCenterId).filter(id => isNonEmptyString(id))}/>}
    </TableCell>
)(invoice);
const InvoicePartnerDataCell = ({invoice}: { invoice: Invoice }) => <TableCell><PartnerLabel
    partnerId={invoice.partnerId}/></TableCell>;
const InvoiceNumberDataCell = ({invoice}: { invoice: Invoice }) => <TableCell>{invoice.invoiceNumber}</TableCell>
const InvoiceStatusDataCell = ({invoice}: { invoice: Invoice }) => <TableCell><Typography><i
    className="fas fa-check-circle has-text-success"/></Typography></TableCell>;


const TableCellRenderers: { [id in availableColumns]: any } = {
    "NAME": InvoiceTitleTableCell,
    "DATE": InvoiceDateTableCell,
    "AMOUNT": InvoiceAmountTableCell,
    "TAX_RATE": InvoiceTaxRateTableCell,
    "TAX": InvoiceTaxTableCell,
    "COST_TYPE": InvoiceCostTypeDataCell,
    "COST_CENTER": InvoiceCostCenterTableCell,
    "PARTNER": InvoicePartnerDataCell,
    "INVOICE_NUMBER": InvoiceNumberDataCell,
    "STATUS": InvoiceStatusDataCell
};


/**
 * Accounting Transaciton Row
 */

const useInvoiceTableAccountingTransactionRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            backgroundColor: theme.palette.grey["100"],
        },
    }),
);

interface AccountingTransactionTableRowProps extends ColumnVisibilityProps {
    accountingTransaction: InvoiceAccountingTransaction,
}

const AccountingTransactionTableRow = (props: AccountingTransactionTableRowProps) => {

    const classes = useInvoiceTableAccountingTransactionRowStyles();

    return <TableRow className={classes.row}>
        {props.visibleColumns.map(columnKey => AccountingTransactionCellRenderers[columnKey]({accountingTransaction: props.accountingTransaction}))}
        <TableCell/>
    </TableRow>;
};

const AccountingTransactionTitleCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell>{accountingTransaction.title}</TableCell>;
const AccountingTransactionAmountCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell>{numeral(accountingTransaction.amount).format("0.00$")}</TableCell>;
const AccountingTransactionTaxRateCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell>{taxRates[accountingTransaction.taxRate].label}</TableCell>;
const AccountingTransactionTaxCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell>{numeral(calculateTaxesFromGrossAmount(accountingTransaction.amount, accountingTransaction.taxRate)).format("0.00$")}</TableCell>;
const AccountingTransactionCostTypeCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell><BookingAccountLabel bookingAccountId={accountingTransaction.contraAccountId}/></TableCell>;
const AccountingTransactionCostCenterCell = ({accountingTransaction}: { accountingTransaction: AccountingTransaction }) =>
    <TableCell><CostCenterLabel costCenterId={accountingTransaction.costCenterId}/></TableCell>;

const NilCell = () => <TableCell></TableCell>;


const AccountingTransactionCellRenderers: { [id in availableColumns]: any } = {
    "NAME": AccountingTransactionTitleCell,
    "DATE": NilCell,
    "AMOUNT": AccountingTransactionAmountCell,
    "TAX_RATE": AccountingTransactionTaxRateCell,
    "TAX": AccountingTransactionTaxCell,
    "COST_TYPE": AccountingTransactionCostTypeCell,
    "COST_CENTER": AccountingTransactionCostCenterCell,
    "PARTNER": NilCell,
    "INVOICE_NUMBER": NilCell,
    "STATUS": NilCell
}

const ExternalIdBookingAccountList = ({bookingAccountIds}: { bookingAccountIds: (string | undefined)[] }) =>
    <BookingAccountConsumer>
        {({bookingAccounts}) => <Typography>
            {uniq(bookingAccountIds).map(bookingAccountId => bookingAccountId ? bookingAccounts[bookingAccountId].id : undefined).join(", ")}
        </Typography>}
    </BookingAccountConsumer>;

const ExternalIdCostCenterList = ({costCenterIds}: { costCenterIds: (string | undefined)[] }) =>
    <CostCenterConsumer>
        {({availableCostCenters}) => <TableCell>
            {uniq(costCenterIds).map(costCenterId => costCenterId ? availableCostCenters[costCenterId].id : undefined).join(", ")}
        </TableCell>}
    </CostCenterConsumer>;



export default React.memo(InvoiceTableBody);
