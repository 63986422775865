/**
 * renders the expansion icon.
 * @param expanded state of expansion, true or false
 * @param disabled if true, the expansion icon will be displayed in a lighter, disabled color
 */
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ExpansionIcon = ({expanded, disabled}: { expanded: boolean, disabled: boolean }) => expanded ?
    <FontAwesomeIcon icon="caret-down"/> :
    <FontAwesomeIcon icon="caret-right"/>;