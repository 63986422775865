import React, {Component} from 'react';
import InvoiceNewTemplate from "../../../components/templates/invoice/new";
import {Invoice} from "../../../models/invoice";
import axios from "axios";
import {adalApiFetch} from "../../../adalConfig";
import {findIndex, propEq, remove} from "ramda";
import isNonEmptyString from "../../../util/isNonEmptyString";
import {InvoiceAccountingTransaction} from "../../../models/accountingTransaction";
import generateUUID from "../../../util/generateUuid";
import {UserStoreConsumer} from "../../../stores/user";
import { Doc } from '../../../models/doc';
import { NotificationConsumer } from '../../../components/molecules/notification';
import { Notification } from '../../../components/molecules/notification/types';

interface State {
    title: string;
    documents: Doc[];
    docVisible: boolean;
    shownDocumentIndex: number;
    date: string;
    invoiceNumber?: string;
    partnerId?: string;
    accountingTransactions: InvoiceAccountingTransaction[];
}

const NEW_ACCOUNTING_TRANSACTION : InvoiceAccountingTransaction = {
    id: generateUUID(),
    title: "Position 1",
    amount: 0,
    taxRate: "A",
    contraAccountId: undefined,
    costCenterId: undefined
}

const INITIAL_STATE = {
    title : "",
    date: "2021-01-01",
    invoiceNumber: "",
    partnerId: undefined,
    documents: []
};

interface Props {
    addNotification: (notification: Notification) => void;
    defaultCostCenterId? : string,
    partnerId? : string
}

class IndexInternal extends Component<Props, State> {

    constructor(props : Props) {
        super(props);

        this.state = {
            ...INITIAL_STATE,
            docVisible: false,
            shownDocumentIndex: 0,
            accountingTransactions : [{
                ...NEW_ACCOUNTING_TRANSACTION,
                costCenterId: props.defaultCostCenterId
            }],
            partnerId: props.partnerId
        };
    }

    resetInvoiceData = () => {
        this.setState({...INITIAL_STATE,
            documents: [],
            docVisible: false,
            partnerId: this.props.partnerId,
            accountingTransactions: [this.getNewAccountingTransaction(true)]})
    }

    getNewAccountingTransaction = (reset: boolean) => {

        const positionNumber = reset ? 1 : this.state.accountingTransactions.length + 1;

        return {
                ...NEW_ACCOUNTING_TRANSACTION,
                id: generateUUID(),
                title: "Position " + positionNumber,
                costCenterId: this.props.defaultCostCenterId
            }
    }

    uploadFile = (file: FileList) => {

        if(file.length < 1) {
            this.props.addNotification({
                type: "ERROR",
                title: "Es wurden keine Dokumente ausgewählt."
            });
        }

        let data = new FormData();        

        for(let i = 0; i < file.length; i++) {

            const fileItem = file.item(i);

            if(fileItem !== null) {                
                data.append("file", fileItem);
            }
        }
        
        const firstFile = file.item(0);
        const firstFileName = firstFile !== null 
                ? firstFile.name.substring(0, firstFile.name.lastIndexOf(".")) : "";

        adalApiFetch(axios, "/api/document", { method: "POST",
            data: data,
            headers: {'Content-Type': 'multipart/form-data'}})
            .then((response) => {
                this.props.addNotification({
                    type: "SUCCESS",
                    title: "Dokumente wurden hochgeladen."
                });
                this.setState({
                    title: this.state.title || firstFileName,
                    documents: [...response.data, ...this.state.documents],
                    docVisible: true
                });       
            })
            .catch(() => this.props.addNotification({
                type: "ERROR",
                title: "Dokumente konnten nicht hochgeladen werden."
            }));
    };

    removeDocument = (id: string) => {

        const newDocuments = listWithoutItemWithId(id, this.state.documents);

        this.setState({
            documents: newDocuments,
            docVisible: newDocuments.length > 0,
            shownDocumentIndex: newDocuments.length > 0
                ? newDocuments.length - 1
                : 0
        });
    };

    titleChangedCallback = (newTitle: string) => this.setState({title: newTitle});
    invoiceDateChangedCallback = (newInvoiceDate: string) => this.setState({date : newInvoiceDate});
    invoiceNumberChangedCallback = (newInvoiceNumber: string) => this.setState({invoiceNumber: newInvoiceNumber});
    partnerChangedCallback = (newId?:string) => this.setState({partnerId: newId});

    accountingTransactionAddedCallback = () => this.setState({accountingTransactions : [...this.state.accountingTransactions, this.getNewAccountingTransaction(false)]});
    accountingTransactionModifiedCallback = (itemId: string, field: string, newValue: any) => this.setState({accountingTransactions: modifyAccountingTransactionsInArray(itemId, field, newValue, this.state.accountingTransactions)});
    accountingTransactionRemovedCallback = (itemId : string) => this.setState({accountingTransactions : listWithoutItemWithId(itemId, this.state.accountingTransactions)});

    uploadInvoice = () => {

        const data : Invoice = {
            title: this.state.title,
            documents: this.state.documents,
            date: this.state.date,
            invoiceNumber: this.state.invoiceNumber,
            partnerId: this.state.partnerId || "",
            accountingTransactions: this.state.accountingTransactions,
            invoiceStatus: "SUBMITTED"
        };

        adalApiFetch(axios, "/api/invoice", { method: "POST",
            data: data})
            .then(() => {
                this.props.addNotification({
                    type: "SUCCESS",
                    title: "Beleg wurde eingereicht."
                });
                this.resetInvoiceData();
            })
            .catch(() => 
                this.props.addNotification({
                    type: "ERROR",
                    title: "Beleg konnte nicht eingereicht werden."
                })
            );
    };

    toggleDocVisible = () => this.setState({docVisible : !this.state.docVisible});
    showDocumentByIndex = (index: number) => this.setState({shownDocumentIndex: index});

    canSubmitInvoice = () => {
        if(!isNonEmptyString(this.state.title)) {
            return false;
        } else if (this.state.date === undefined) {
            return false;
        } else if (this.state.partnerId === undefined) {
            return false;
        } else if (this.state.accountingTransactions.length <= 0) {
            return false;
        }

        for (const value of this.state.accountingTransactions) {
            if (value.amount < 0.01) {
                return false;
            } else if (value.costCenterId === undefined) {
                return false;
            } else if (value.contraAccountId === undefined) {
                return false;
            }

        }

        return true;
    };

    render() {
        return (
            <InvoiceNewTemplate
                title={this.state.title}
                titleChangedCallback={this.titleChangedCallback}
                documents={this.state.documents}
                fileChangedCallback={this.uploadFile}
                documentRemovedCallback={this.removeDocument}
                docVisible={this.state.docVisible}
                toggleDocVisible={this.toggleDocVisible}
                shownDocumentIndex={this.state.shownDocumentIndex}
                shownDocumentIndexChanged={this.showDocumentByIndex}
                date={this.state.date}
                invoiceDateChangedCallback={this.invoiceDateChangedCallback}
                invoiceNumber={this.state.invoiceNumber}
                invoiceNumberChangedCallback={this.invoiceNumberChangedCallback}
                partnerId={this.state.partnerId}
                partnerIdChangedCallback={this.partnerChangedCallback}
                accountingTransactions={this.state.accountingTransactions}
                accountingTransactionAddedCallback={this.accountingTransactionAddedCallback}
                accountingTransactionModifiedCallback={this.accountingTransactionModifiedCallback}
                accountingTransactionRemovedCallback={this.accountingTransactionRemovedCallback}
                canSubmitInvoice={this.canSubmitInvoice()}
                invoiceSubmitCallback={this.uploadInvoice}
            />
        );
    }
}

function listWithoutItemWithId(itemId: string, items: any[]): any[] {

    const itemIdx = findIndex(propEq('id', itemId), items);

    if(itemIdx !== -1) {
        return remove(itemIdx, 1, items);
    } else {
        console.error(`could not find element with id ${itemId} in array:`, items);
        return items;
    }
}

function modifyAccountingTransactionsInArray(itemId: string, field: string, newValue: any, items: InvoiceAccountingTransaction[]) : InvoiceAccountingTransaction[] {

    const itemIdx = findIndex(propEq('id', itemId), items);

    if(itemIdx !== -1) {

        // @ts-ignore
        items[itemIdx][field] = newValue;

        return items;
    } else {
        console.error(`could not find element with id ${itemId} in array:`, items);
        return items;
    }
}

const Index = () => <NotificationConsumer>
    {({addNotification}) => <UserStoreConsumer>
        {({defaultCostCenterId, partnerId}) => 
            <IndexInternal addNotification={addNotification} 
                    defaultCostCenterId={defaultCostCenterId} partnerId={partnerId} />
        }    
    </UserStoreConsumer>}
</NotificationConsumer>;


export default Index;