import React, {Fragment} from 'react';
import {createStyles, Grid, makeStyles, Paper, Theme, Typography} from "@material-ui/core";
import BreadCrumb from "../../molecules/breadCrumb";
import BreadCrumbItem from "../../molecules/breadCrumb/breadCrumbItem";
import BankTransactionOverviewTable from "../../organisms/bankTransactionOverviewTable";
import {BankAccountBalances, BankTransaction} from "../../../models/bankTransaction";
import BankAccountBalanceView from "../../organisms/bankAccountBalanceView";
import Datatablev2 from "../../organisms/datatable/datatablev2";
import {BankingApi} from "../../../api/banking";
import Column from "../../organisms/datatable/column";
import numeral from "numeral";
import Moment from "react-moment";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(1),
            paddingTop: theme.spacing(1)
        },
        grid: {
            marginTop: theme.spacing(2),
        },
        table: {
            marginTop: theme.spacing(4)
        },
        tableCell: {
            borderBottom: 0
        }
    }),
);

interface Props {
    accountBalances?: BankAccountBalances;

    accountId?: string;

    currentTransactionPage: number;
    transactionPageChangedCallback: (newPage: number) => void;
    currentTransactionSize: number;
    transactionSizeChangedCallback: (newSize: number) => void;
    transactions: {
        [id: string] : BankTransaction
    };
    totalTransactions: number;
    transactionsLoading: boolean;
    transactionFetchError?: object;
}

const BankingTemplate = (props: Props) => {

    const classes = useStyles();

    return (
        <Fragment>
            <BreadCrumb>
                <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                <BreadCrumbItem to={"/banking"} title={"Bankkonten"}/>
            </BreadCrumb>

            <Paper className={classes.paper}>
                <Grid container className={classes.grid} alignItems={"stretch"}>
                    <Grid item xs={12} md={4} alignContent={"center"}>
                        <BankAccountBalanceView

                            accountBalances={props.accountBalances}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {
                            props.accountId ? <Datatablev2 endpoint={BankingApi.getPaginatedBankTransactions(props.accountId)}>
                                <Column identifier={"bookingDate"} title={"Buchdatum"}>{({cellData, rowData}) => <Moment format="DD.MM.YYYY">{cellData}</Moment>}</Column>
                                <Column identifier={"fromName"} title={"Auftraggeber"}></Column>
                                <Column identifier={"reference"} title={"Beschreibung"}></Column>
                                <Column identifier={"amount"} title={"Wert"}>{({cellData, rowData}) => <Fragment>{numeral(cellData).format("0.00$")}</Fragment>}</Column>
                            </Datatablev2> : <Typography>Kein Bank-Account verknüpft.</Typography>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Fragment>
    );
};

export default BankingTemplate;