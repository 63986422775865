import React, {Fragment} from 'react';
import {adalApiFetch} from "../../adalConfig";
import axios from "axios";
import {FetchState} from "../../models/fetchState";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    TextField
} from "@material-ui/core";
import {FixedSizeList} from "react-window";
import {debounce} from "../../util/debounce";
import isNonEmptyString from "../../util/isNonEmptyString";
import {BookingAccount} from "../../models/masterData";

interface ContextData {
    state: FetchState;
    bookingAccounts: {
        [id: string]: BookingAccount
    };
    openModal?: (newCallback?: (id: string) => void) => void;
    newBookingAccountCallback?: (id: string) => void;
}

interface State extends ContextData {
    modalOpen: boolean;
    searchValue: string;
    bookingAccountIdArray: string[];
    filteredBookingAccountIdArray: string[];
}

interface Props {
    children: any;
    fetchMethod?: any;
}

const initialData: ContextData = {
    state: "INIT",
    bookingAccounts: {},
}


const BookingAccountStoreContext = React.createContext<ContextData>(initialData);

class BookingAccountProvider extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            ...initialData,
            openModal: this.openModal,
            searchValue: "",
            bookingAccountIdArray: [],
            filteredBookingAccountIdArray: [],
            modalOpen: false,
        };

        let fetchMethod;

        if (props.fetchMethod) {
            fetchMethod = props.fetchMethod
        } else {
            fetchMethod = adalApiFetch(axios, "/api/md/bookingaccount/all", {});
        }

        fetchMethod
            .then(({data}: any) => this.setState({
                bookingAccounts: data,
                state: "READY",
                bookingAccountIdArray: Object.keys(data)
            }))
            .catch((error: any) => this.setState({state: "ERROR"}));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevState.searchValue !== this.state.searchValue) {
            this.recalculateSearchArray();
        }
    }

    recalculateSearchArray = debounce(() => {
        if (isNonEmptyString(this.state.searchValue)) {

            const trimmedValue = this.state.searchValue.trim();

            let filteredIdArray = Object.values(this.state.bookingAccounts)
                .filter((bookingAccount: BookingAccount) => {
                    if (bookingAccount.id === trimmedValue) {
                        return true;
                    } else if (bookingAccount.title.includes(trimmedValue)) {
                        return true;
                    }
                })
                .map((object) => object.id);

            this.setState({filteredBookingAccountIdArray: filteredIdArray});
        }
    }, 500);

    openModal = (callback?: (id: string) => void) => {
        this.setState({modalOpen: true, newBookingAccountCallback: callback})
    }

    closeModal = () => {
        this.setState({modalOpen: false})
    }

    render() {

        const idArray = isNonEmptyString(this.state.searchValue) ? this.state.filteredBookingAccountIdArray : this.state.bookingAccountIdArray;

        return (
            <BookingAccountStoreContext.Provider value={{openModal : this.state.openModal, bookingAccounts: this.state.bookingAccounts, newBookingAccountCallback: this.state.newBookingAccountCallback, state: this.state.state}}>
                <Fragment>
                    {this.props.children}
                    <Dialog onClose={this.closeModal} aria-labelledby="customized-dialog-title" open={this.state.modalOpen}>
                        <DialogTitle id="customized-dialog-title">
                            Buchungskonto auswählen
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField
                                fullWidth
                                value={this.state.searchValue}
                                onChange={(e) => this.setState({searchValue: e.target.value})}
                                margin="normal"
                            />

                            <FixedSizeList height={400} width={520} itemSize={46}
                                           itemCount={idArray.length}>
                                {({style, index}) => (<ListItem button
                                                                style={style}
                                                                key={index}
                                                                onClick={() => {
                                                                    if (this.state.newBookingAccountCallback) {
                                                                        this.state.newBookingAccountCallback(idArray[index])
                                                                    }
                                                                    this.closeModal();
                                                                }}>
                                    <ListItemText
                                        secondary={this.state.bookingAccounts[(idArray[index])] ? this.state.bookingAccounts[(idArray[index])].id : "---"}
                                        primary={this.state.bookingAccounts[(idArray[index])] ? this.state.bookingAccounts[(idArray[index])].title : "Nicht vorhanden"}/>
                                </ListItem>)}
                            </FixedSizeList>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeModal} color="default">
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            </BookingAccountStoreContext.Provider>
        );
    }


}

const BookingAccountConsumer = BookingAccountStoreContext.Consumer;

export {BookingAccountProvider, BookingAccountConsumer};
