import React from 'react';
import {
    AppBar,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import ReportingIcon from "@material-ui/icons/BarChart";
import AcccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DescriptionIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import clsx from "clsx";
import {REDIRECT_URI} from "../../../environment";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            marginLeft: drawerWidth,
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        drawerIcon: {
            paddingLeft: "8px"
        },
        drawerLink: {
            textDecoration: "none",
            color: "inherit"
        },
        drawerLinkTextActive: {
            "& span": {
                fontWeight: 500,
                color: theme.palette.primary.dark
            },
            "& p" : {
                fontWeight: 500
            }
        },
        roccoTitleContainer: {
            padding: theme.spacing(1)
        },
        roccoTitle: {
            letterSpacing: "-0.05em",
            color: theme.palette.primary.main,
            textAlign: "center",
            fontSize: "2.5em",
            fontWeight: 100
        },
        roccoSubTitle: {
            letterSpacing: "-0.05em",
            color: theme.palette.grey["700"],
            textAlign: "center",
            fontSize: "1em",
            fontWeight: 100
        }
    }),
);


function isActive(location: string) {
    return window.location.pathname.startsWith(location);
}

function isRoot() {
    return window.location.pathname.length <= 1;
}

const NavbarV3 = () => {

    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    }


    const drawer = (
        <div>
            <div className={clsx(classes.toolbar, classes.roccoTitleContainer)}>
                <Typography className={classes.roccoTitle} variant={"h3"}>Rocco</Typography>
                <Typography className={classes.roccoSubTitle} variant={"h5"}>Partner- und Mitarbeiterportal</Typography>
            </div>
            <Divider />
            <List>
                <Link to={"/invoice"} className={classes.drawerLink}>
                    <ListItem button>
                        <ListItemIcon className={classes.drawerIcon}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary="Belegmanagment" secondary="Fremdbelege buchen" className={clsx({[classes.drawerLinkTextActive] : isActive("/invoice")})} />
                    </ListItem>
                </Link>
                <Link style={{textDecoration: "none", color:"inherit"}} to={"/banking"}>
                    <ListItem button>
                        <ListItemIcon className={classes.drawerIcon}>
                            <AcccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Geschäftskonto" secondary="Banktransaktionen buchen" className={clsx({[classes.drawerLinkTextActive] : isActive("/banking")})}/>
                    </ListItem>
                </Link>
                <Link style={{textDecoration: "none", color:"inherit"}} to={"/masterdata"}>
                    <ListItem button>
                        <ListItemIcon className={classes.drawerIcon}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Stammdaten" secondary="Stammdaten einsehen" className={clsx({[classes.drawerLinkTextActive] : isActive("/masterdata")})}/>
                    </ListItem>
                </Link>
                <Link style={{textDecoration: "none", color:"inherit"}} to={"/datatransfer"} >
                    <ListItem button>
                        <ListItemIcon className={classes.drawerIcon}>
                            <ImportExportIcon />
                        </ListItemIcon>
                        <ListItemText primary="Datentransfer" secondary="Daten im- und exportieren" className={clsx({[classes.drawerLinkTextActive] : isActive("/datatransfer")})}/>
                    </ListItem>
                </Link>
                <a  style={{textDecoration: "none", color:"inherit"}}
                    href={ REDIRECT_URI.indexOf("localhost") === -1
                        ? "https://metabase.rocco.redoak.me"
                        : "/metabase"
                    }
                    target={"_blank"} rel={"noreferrer"}
                >
                    <ListItem button>
                        <ListItemIcon className={classes.drawerIcon}>
                            <ReportingIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Auswertungen" secondary="Kennzahlen und Daten" />
                    </ListItem>
                </a>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {isRoot() && "Dashboard"}
                        {isActive("/invoice") && "Belegmanagement"}
                        {isActive("/banking") && "Geschäftskonto"}
                        {isActive("/masterdata") && "Stammdaten"}
                        {isActive("/datatransfer") && "Datentransfer"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
};

export default NavbarV3;