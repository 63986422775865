import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {IconButton, InputAdornment, PropTypes, TextField} from "@material-ui/core";
import {BadgeConsumer} from "../../../stores/badge";
import isNonEmptyString from "../../../util/isNonEmptyString";
import {Edit} from "@material-ui/icons";
import {Badge} from "../../../models/badge";
import {text} from "@fortawesome/fontawesome-svg-core";

interface Props {
    selectedBadges?: Badge[];
    badgesChangedCallback?: (badges: Badge[]) => void;
    readonly? : boolean;
    variant?: any;

    label?: string;
    margin?: PropTypes.Margin;
    disabled?: boolean;
    className?: string;
    inputRef?: any;
}

interface InternalProps extends Props {
    badges: {
        [id: string]: Badge
    };
    openModal?: (
        func?: (badges: Badge[]) => void,
        selectedBadges?: Badge[]
    ) => void;
}

const BadgeInternal = (props : InternalProps) => {

    useEffect(() => {
        if (!props.readonly && props.badgesChangedCallback === undefined) {
            console.error("You did not pass a prop called badgeIdChangedCallback when in editable mode.");
        }
    }, [props.readonly, props.badgesChangedCallback]);

    const openModal = () => {
        if(props.openModal) {
            props.openModal(props.badgesChangedCallback, props.selectedBadges)
        }
    }
    const handleKeyPress = useCallback((e) => {
        if(e.key === 'Enter') {
            openModal()
        }
    }, [openModal]);
    const handleButtonClick = useCallback(openModal, [openModal]);

    const selectedBadgesLength = props.selectedBadges ? props.selectedBadges.length : 0
    const textValue = props.selectedBadges && selectedBadgesLength > 0
        ? props.selectedBadges.reduce( (text, badge, index) => (
            text + (index === selectedBadgesLength - 1 ? badge.title : `${badge.title}, `)
        ), "")
        : "Keine Plaketten ausgewählt"

    return (
        <Fragment>
            <TextField
                inputRef={props.inputRef}
                className={props.className}
                disabled={props.disabled}
                variant={props.variant}
                label={props.label}
                margin={props.margin}
                onKeyPress={props.readonly ? undefined : handleKeyPress}
                value={ textValue }
                InputProps={{
                    startAdornment: isNonEmptyString(props.selectedBadges)?<InputAdornment
                        //@ts-ignore
                        position="start">{(props.badges[(props.selectedBadges)] ? props.badges[(props.selectedBadges)].title : "")}</InputAdornment> :undefined,
                    endAdornment: props.readonly ? undefined : <InputAdornment position={"end"}>
                        <IconButton aria-label="Plaketten ändern" tabIndex={-1} onClick={ handleButtonClick }>
                            <Edit/>
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </Fragment>
    );
};

const BadgeField = (props : Props) => <BadgeConsumer>
    {({badges, openModal}) => <BadgeInternal
        badges={badges}
        openModal={openModal}
        {...props}/>}
</BadgeConsumer>

export default BadgeField;