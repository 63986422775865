import React, { Fragment } from 'react';
import BreadCrumbItem from "../../molecules/breadCrumb/breadCrumbItem";
import BreadCrumb from "../../molecules/breadCrumb";
import {BankAccount} from "../../../models/masterData";
import {BankTransaction} from "../../../models/bankTransaction";
import SceneLoading from "../../molecules/sceneLoading";
import NotFound from "../../molecules/notFound";
import {AxiosError} from "axios";
import {Button, createStyles, makeStyles, Paper, Theme, Typography} from "@material-ui/core";
import BankTransactionContentArea, {BankTransactionContentAreaProps} from "../../organisms/bankTransactionContentArea";

interface Props extends BankTransactionContentAreaProps {
    bankAccountId: string;
    bankAccount?: BankAccount;

    isEdited: boolean;

    bankTransactionId: string;
    transactionDetailsLoading: boolean;
    transactionDetailsFetchError?: AxiosError;
    bankTransaction?: BankTransaction;

    saveAllocationsCallback?: () => void;
    canSaveAllocations: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(1),
            paddingTop: theme.spacing(1)
        },
        button: {
            margin: theme.spacing(1),
        }
    }),
);

const BankTransactionDetailsTemplate = (props: Props) => {

    const classes = useStyles();

    const status = (props.bankTransaction && props.bankTransaction.bankTransactionStatus) || "IMPORTED";

    return (
        <Fragment>
            <BreadCrumb>
                <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                <BreadCrumbItem to={"/banking"} title={"Bankkonten"}/>
                <BreadCrumbItem to={`/banking/${props.bankAccountId}`}
                                title={props.bankAccount ? props.bankAccount.title : props.bankAccountId}/>
                <BreadCrumbItem to={`/banking/${props.bankAccountId}/transaction/${props.bankTransactionId}`} title={props.transactionDetailsLoading ? props.bankTransactionId : (props.bankTransaction ? `Umsatz: ${props.bankTransaction.reference}` : "")}/>
            </BreadCrumb>

            {props.transactionDetailsLoading && <SceneLoading loadingText={"Transaktionsdaten werden geladen..."} />}
            {props.transactionDetailsFetchError && <NotFound error={props.transactionDetailsFetchError}/>}

            {(!props.transactionDetailsLoading && props.transactionDetailsFetchError === undefined) && <Fragment>
                <Paper>
                    <BankTransactionContentArea
                        {...props}
                    >
                        {props.saveAllocationsCallback && props.isEdited && <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={!props.canSaveAllocations}
                            onClick={props.saveAllocationsCallback}
                        >
                            {status === "IMPORTED" && "Zuweisung Speichern"}
                            {status === "ASSIGNED" && "Änderungen speichern"}
                        </Button>}
                    </BankTransactionContentArea>
                </Paper>
            </Fragment>}

        </Fragment>
    );
};

export default BankTransactionDetailsTemplate;