import {
    createStyles,
    FormControl, IconButton,
    makeStyles,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    TextField,
    Theme
} from "@material-ui/core";
import {ColumnVisibilityProps} from "./types";
import {InvoiceStatus, InvoiceStatusArray, InvoiceStatusTranslations} from "../../../models/invoice";
import React, {useCallback} from "react";
import {availableColumns} from "./index";
import TaxRate from "../../atoms/taxRate";
import BookingAccountField from "../../molecules/bookingAccountField";
import CostCenterField from "../../molecules/costCenterField";
import PartnerField from "../../molecules/partnerField";
import {Tax} from "../../../models/tax";
import {Clear} from "@material-ui/icons";


/**
 * Invoice Table Filter Row
 */
const useInvoiceTableFilterRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
        },
    }),
);

interface InvoiceTableFilterRowProps extends ColumnVisibilityProps {

    filterTitle: string;
    setFitlerTitle: (newFilterTitle: string) => void;

    filterDate: undefined | [string] | [string, string];
    setFilterDate: (newDate: (undefined | [string] | [string, string])) => void;

    filterAmount: undefined | [string] | [string, string];
    setFilterAmount: (newAmount: undefined | [string] | [string, string]) => void;

    filterTaxRate: undefined | Tax;
    setFilterTaxRate: (newTaxRateFilter: undefined | Tax) => void;

    filterCostType: undefined | string;
    setFilterCostType: (newFilterCostType: undefined | string) => void;

    filterCostCenter: undefined | string;
    setFilterCostCenter: (newFilterCostCenter: undefined | string) => void;

    filterPartner: undefined | string;
    setFilterPartner: (newFilterPartner: undefined | string) => void;

    filterInvoiceNumber: string;
    setFilterInvoiceNumber: (newFilterInvoiceNumber: string) => void;

    filterInvoiceStatus: InvoiceStatus | undefined;
    setFilterInvoiceStatus: (filterInvoiceStatus: InvoiceStatus | undefined) => void;
}

const InvoiceTableFilterRow = (props: InvoiceTableFilterRowProps) => {

    const clearAllFilters = useCallback(() => {
        props.setFitlerTitle("");
        props.setFilterDate(undefined);
        props.setFilterAmount(undefined);
        props.setFilterTaxRate(undefined);
        props.setFilterCostType(undefined);
        props.setFilterCostCenter(undefined);
        props.setFilterPartner(undefined);
        props.setFilterInvoiceNumber("");
        props.setFilterInvoiceStatus(undefined);
    }, [
        props.setFitlerTitle,
        props.setFilterDate,
        props.setFilterAmount,
        props.setFilterTaxRate,
        props.setFilterCostType,
        props.setFilterCostCenter,
        props.setFilterPartner,
        props.setFilterInvoiceNumber,
        props.setFilterInvoiceStatus
    ]);

    const classes = useInvoiceTableFilterRowStyles();

    return <TableRow>
        {props.visibleColumns.map(columnKey =>
            (TableCellRenderers[columnKey] ? TableCellRenderers[columnKey]({
                ...props
            }) : <TableCell/>))}
        <TableCell>
            <IconButton aria-label="Aktionen" size="small" style={{padding: 0}} onClick={clearAllFilters}>
                <Clear/>
            </IconButton>
        </TableCell>
    </TableRow>;
};

const NilTableCell = () => <TableCell/>;
const TitleFilterCell = (props: InvoiceTableFilterRowProps) =>
    <TableCell key={"FILTER_TITLE"}><TextField
        value={props.filterTitle}
        onChange={(e) => props.setFitlerTitle(e.target.value)}
        fullWidth
        margin="dense"
    /></TableCell>;

const TaxRateFilterCell = (props: InvoiceTableFilterRowProps) => <TableCell>
    <TaxRate
        variant={"standard"}
        emptyAllowed
        emptyTitle={"Alle"}
        taxRateChangedCallback={props.setFilterTaxRate}
        taxRate={props.filterTaxRate}
    />
</TableCell>;

const CostTypeFilterCell = (props: InvoiceTableFilterRowProps) => <TableCell>
    <BookingAccountField
        bookingAccountId={props.filterCostType}
        bookingAccountIdChangedCallback={props.setFilterCostType}
        emptyText={"Alle"}
        fullWidth
    />
</TableCell>;

const CostCenterFilterCell = (props: InvoiceTableFilterRowProps) => <TableCell>
    <CostCenterField
        costCenterId={props.filterCostCenter}
        costCenterIdChangedCallback={props.setFilterCostCenter}
        emptyText={"Alle"}
        fullWidth
    />
</TableCell>;

const PartnerFilterCell = (props: InvoiceTableFilterRowProps) => <TableCell>
    <PartnerField
        partnerId={props.filterPartner}
        partnerIdChangedCallback={props.setFilterPartner}
        emptyText={"Alle"}
        fullWidth
    />
</TableCell>;
const InvoiceNumberFilterCell = (props: InvoiceTableFilterRowProps) => <TableCell><TextField
    value={props.filterInvoiceNumber}
    onChange={(e) => props.setFilterInvoiceNumber(e.target.value)}
    fullWidth
    margin="dense"
/></TableCell>;

const TableCellRenderers: { [id in availableColumns]: any } = {
    "NAME": TitleFilterCell,
    "DATE": NilTableCell,
    "AMOUNT": NilTableCell,
    "TAX_RATE": TaxRateFilterCell,
    "TAX": NilTableCell,
    "COST_TYPE": CostTypeFilterCell,
    "COST_CENTER": CostCenterFilterCell,
    "PARTNER": PartnerFilterCell,
    "INVOICE_NUMBER": InvoiceNumberFilterCell,
    "STATUS": NilTableCell
};

export default React.memo(InvoiceTableFilterRow);