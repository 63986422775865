import React, {ReactNode} from "react";
import {always, cond, has} from "ramda";
import {Invoice} from "../../../models/invoice";
import {TableCell} from "@material-ui/core";

const invoiceItemCountDependentRenderer = (singleItemRenderer: ReactNode, multiItemRenderer: ReactNode): any => cond([
    [(invoice: Invoice) => !has('accountingTransactions', invoice), always(<TableCell>None</TableCell>)],
    [(invoice: Invoice) => invoice.accountingTransactions.length === 1, always(singleItemRenderer)],
    [(invoice: Invoice) => invoice.accountingTransactions.length > 1, always(multiItemRenderer)]
]);

export {invoiceItemCountDependentRenderer}