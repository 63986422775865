import React, {Component} from 'react';
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme, WithStyles, withStyles, Paper } from "@material-ui/core";

import BreadCrumb from "../../../components/molecules/breadCrumb";
import BreadCrumbItem from "../../../components/molecules/breadCrumb/breadCrumbItem";
import Datatable from '../../../components/organisms/datatable/datatablev2';
import { Column } from '../../../components/organisms/datatable';
import { BankingApi } from '../../../api/banking';
import { BankAccountConsumer } from '../../../stores/bankAccount';
import Moment from 'react-moment';

const styles = (theme: Theme) => createStyles({
    paper: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(),
        paddingTop: theme.spacing()      
    },
    link: {
        width: theme.spacing(),
        height: theme.spacing(),
    }
});

interface Props extends WithStyles<typeof styles> {
}

interface State {
}

class Index extends Component<Props, State> {

    render() {

        const { classes } = this.props;

        return (
            <BankAccountConsumer>
                {({bankAccounts}) => <main className="section">
                    <div className="container">
                        <BreadCrumb>
                            <BreadCrumbItem to={"/"} title={"Dashboard"}/>
                            <BreadCrumbItem to={"/datatransfer"} title={"Datentransfer"}/>
                            <BreadCrumbItem to={"/datatransfer/import"} title={"Bankdaten-Imports"}/>
                        </BreadCrumb>

                        <Paper className={classes.paper}>
                                <Datatable endpoint={BankingApi.getPaginatedBankTransactionImports}>
                                    <Column />
                                    <Column title="Zeitpunkt" identifier="importTimestamp">
                                        {({cellData}) => <Moment format="DD.MM.YYYY HH:mm">{cellData}</Moment>}
                                    </Column>
                                    <Column title="Importiert von" identifier="importedBy" />
                                    <Column title="Bankkonto" identifier="bankAccountId">
                                        {({cellData}) => <span>{bankAccounts[cellData].title}</span>}
                                    </Column>
                                    <Column title="Dateiname" identifier="filename" />
                                    <Column />
                                </Datatable>                                
                        </Paper> 
                    </div>
                </main>}
            </BankAccountConsumer>
        );
    }
}

export default withStyles(styles)(Index);