import {createStyles, IconButton, makeStyles, Menu, MenuItem, TableCell, TableRow, Theme} from "@material-ui/core";
import {ColumnVisibilityProps, ColumnVisibilitySetterProps} from "./types";
import {ArrowDownward, ArrowUpward, ViewColumn} from "@material-ui/icons";
import React from "react";
import {allTableColumns, availableColumns} from "./index";
import {includes} from "ramda";
import {SortDirection} from "../../../models/datatableOptions";


export const columnTitle: { [columnKey in availableColumns]: string } = {
    "STATUS": "Status",
    "NAME": "Titel",
    "DATE": "Datum",
    "AMOUNT": "Betrag",
    "TAX_RATE": "Steuersatz",
    "TAX": "enthaltene MwSt.",
    "COST_TYPE": "Kostenart",
    "COST_CENTER": "Kostenstelle",
    "PARTNER": "Gesellschafter",
    "INVOICE_NUMBER": "Rechnungsnummer"
};


/**
 * Invoice Table Title Header
 */
const useInvoiceTableHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        visibleColumn: {
            color: theme.palette.primary.light
        },
        notVisibleColumn: {
            color: theme.palette.grey["400"]
        }
    }),
);

interface InvoiceTableHeaderProps extends ColumnVisibilityProps, ColumnVisibilitySetterProps {
    sortableColumns : string[];
    sort? : [availableColumns, SortDirection];
    toggleSort : (sortColumn? :availableColumns) => void;
}

const InvoiceTableHeader = (props: InvoiceTableHeaderProps) => {

    const classes = useInvoiceTableHeaderStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (<TableRow>
        {props.visibleColumns.map(columnKey =>
            <TableCell
                style={{cursor : includes(columnKey, props.sortableColumns) ? "pointer": undefined}}
                onClick={includes(columnKey, props.sortableColumns) ? () => props.toggleSort(columnKey) : undefined}>
                {columnTitle[columnKey] ? columnTitle[columnKey] : columnKey}
                {props.sort && (props.sort[0] === columnKey && (props.sort[1] === "ASC" ? <ArrowUpward style={{fontSize: "1em"}}/> : <ArrowDownward style={{fontSize: "1em"}}/>))}
            </TableCell>)}
        <TableCell>
            <IconButton aria-label="Aktionen" size="small" style={{padding: 0}} onClick={handleClick}>
                <ViewColumn/>
            </IconButton>

            <Menu
                id="table-actions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {allTableColumns.map(column => <MenuItem
                    className={props.visibleColumns.includes(column) ? classes.visibleColumn : classes.notVisibleColumn}
                    onClick={() => props.toggleColumnVisibility(column)}>
                    {columnTitle[column] ? columnTitle[column] : column}</MenuItem>)}
            </Menu>
        </TableCell>
    </TableRow>);
};

export default React.memo(InvoiceTableHeader);