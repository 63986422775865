import React, {Fragment} from 'react';
import { Button } from '@material-ui/core';

interface Props {
    totalPages: number,
    currentPage: number,
    pageSpan: number,
    pageChangeCallback: (newPage: number) => void
}

const Pagination = (props: Props) => {

    const { totalPages, currentPage, pageSpan, pageChangeCallback } = props;

    const firstVisiblePage: number = (currentPage - pageSpan) > 0
            ? (totalPages - 1 - currentPage) < pageSpan
                ? totalPages - 1 - 2 * pageSpan
                : currentPage - pageSpan
            : 0;
    const lastVisiblePage: number = (firstVisiblePage + 2 * pageSpan) < totalPages
            ? firstVisiblePage + 2 * pageSpan
            : totalPages - 1;

    let buttons = [];
    for(let page = firstVisiblePage; page <= lastVisiblePage; page++) {
        buttons.push(
            <Button variant={page != currentPage ? "text" : "contained"}
                    color={page != currentPage ? "default" : "primary"}
                    onClick={page != currentPage ? () => pageChangeCallback(page) : undefined}
                    aria-label={`Gehe zu Seite ${page+1}`}>{page+1}</Button>
        );
    }

    return (
        <Fragment>
            <Button onClick={() => (currentPage < 1) ? {} : pageChangeCallback(0)}
                    disabled={(currentPage < 1) ? true : undefined}>{"|<"}</Button>

            {buttons}

            <Button onClick={() => ((currentPage >= totalPages - 1) ? {} : pageChangeCallback(totalPages - 1))}
                    disabled={(currentPage >= totalPages - 1) ? true : undefined}>{">|"}</Button>
        </Fragment>
    );
};

export default Pagination;