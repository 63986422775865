import React, {Fragment, useCallback, useState} from 'react';
import {IconButton, InputAdornment, PropTypes, TextField} from "@material-ui/core";
import {CostCenterConsumer} from "../../../stores/costCenter";
import isNonEmptyString from "../../../util/isNonEmptyString";
import {Edit} from "@material-ui/icons";
import {CostCenter} from "../../../models/masterData";


interface Props {
    costCenterId?: string;
    costCenterIdChangedCallback?: (id?: string) => void;
    emptyText?: string;

    fullWidth?: boolean;
    variant?: any;
    readonly? : boolean;
    label?: string;
    margin?: PropTypes.Margin;
    disabled?: boolean;
    className?: string;
}

interface InternalProps extends Props{
    costCenters: {
        [id: string]: CostCenter
    };
    openModal?: (func?: (id: string) => void) => void;
}

const CostCenterFieldInternal = (props: InternalProps) => {

    const [editMode, setEditMode] = useState<boolean>(false);
    const [tempValue, setTempValue] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const handleChange = useCallback((e) => {
        setEditMode(true);
        setTempValue(e.target.value);
    }, []);

    const handleBlur = useCallback(() => {
        if(editMode) {
            let account = Object.entries(props.costCenters).find(value => value[1].id === tempValue);

            setError(false);
            setEditMode(false);

            if(account) {

                if(props.costCenterIdChangedCallback)
                    props.costCenterIdChangedCallback(account[0]);
            }


        }

    }, [props.costCenters, tempValue, setError, editMode]);

    const handleKeyPress = useCallback((e) => {
        if(e.key === 'Enter') {
            if(isNonEmptyString(tempValue)) {
                let account = Object.entries(props.costCenters).find(value => value[1].id === tempValue);

                if(account) {
                    setError(false);
                    setEditMode(false);
                    if(props.costCenterIdChangedCallback)
                        props.costCenterIdChangedCallback(account[0]);
                } else {
                    setError(true);
                }
            } else {
                if(props.costCenterIdChangedCallback) {
                    props.costCenterIdChangedCallback();
                }
            }
        }
    }, [props.costCenters, tempValue, setError, editMode]);

    return (
        <Fragment>
            <TextField
                fullWidth={props.fullWidth}
                disabled={props.disabled}
                className={props.className}
                variant={props.variant}
                label={props.label}
                margin={props.margin}
                onChange={props.readonly? undefined : handleChange}
                onKeyPress={props.readonly? undefined : handleKeyPress}
                onBlur={props.readonly? undefined : handleBlur}
                error={error}
                value={editMode ? tempValue : (props.costCenterId ? (props.costCenters[(props.costCenterId)] ? props.costCenters[(props.costCenterId)].title : props.costCenterId) : (props.emptyText ? props.emptyText : "Keine Kostenstelle gewählt"))}
                InputProps={{
                    readOnly: props.readonly,
                    startAdornment: isNonEmptyString(props.costCenterId) ? <InputAdornment
                        //@ts-ignore
                        position="start">{props.costCenters[(props.costCenterId)] ? props.costCenters[(props.costCenterId)].externalId : ""}</InputAdornment> : undefined,
                    endAdornment: props.readonly ? undefined : <InputAdornment position={"end"}>
                        <IconButton aria-label="Kostenstelle ändern" tabIndex={-1} onClick={() => (props.openModal)? props.openModal(props.costCenterIdChangedCallback) : {}}>
                            <Edit/>
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </Fragment>
    );
};


const CostCenterField = (props: Props) => <CostCenterConsumer>
    {({availableCostCenters, openModal}) => <CostCenterFieldInternal openModal={openModal} costCenters={availableCostCenters} {...props} />}
</CostCenterConsumer>

export default CostCenterField;