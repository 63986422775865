import React, {Fragment, ReactElement, useEffect, useState} from 'react';
import {
    CircularProgress, createStyles, Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, Theme
} from "@material-ui/core";
import Column from "./column";

interface Props {
    page: number;
    size: number;
    totalElements: number;

    pageChangedCallback: (newPage: number) => void;
    sizeChangedCallback: (newSize: number) => void;

    children: ReactElement<Column> | ReactElement<Column>[];
    loading: boolean;
    elements: { [id: string]: object };
    error?: object;


}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
            overflowX: 'auto',
        },
        noContentTableRow: {
        },
        loadingOverlay: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: theme.palette.grey.A100,
            opacity: 0.3,
            justifyContent: "center"
        },
        loadingOverlayContent: {
            alignSelf: "center"
        }
    })
);

const Datatablev2View = (props: Props) => {

    const classes = useStyles();

    const [data, setData] = useState<any>([]);

    useEffect(() => setData(Object.values(props.elements)), [props.elements]);


    /*

     */


    return (
        <Fragment>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        {React.Children.map<any, any>(props.children, (child: Column) =>
                            <TableCell key={child.props.identifier}>
                                {child.props.title ? child.props.title : child.props.identifier || ''}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody style={{position: "relative"}}>
                    {data.map((rowData: any, idx: number) =>
                        <TableRow key={idx}>
                            {React.Children.map<any, any>(props.children, (child: Column) => {
                                let cellData: string = child.props.identifier !== undefined
                                    ? rowData[child.props.identifier]
                                    : '';
                                return <TableCell>
                                    {child.props.children
                                        ? child.props.children({cellData, rowData, expanded: false})
                                        : cellData !== undefined ? <span>{cellData}</span> : null}
                                </TableCell>;
                            })}
                        </TableRow>
                    )}
                    {data.length === 0 && <TableRow className={classes.noContentTableRow}><TableCell
                        colSpan={Array.isArray(props.children) ? props.children.length : undefined}>
                        Keine Daten Vorhanden.
                    </TableCell></TableRow>}

                    {props.loading && <Grid container className={classes.loadingOverlay}>
                        <Grid item className={classes.loadingOverlayContent}><CircularProgress size="2em"
                                                                                               thickness={3}/></Grid>
                    </Grid>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            count={props.totalElements}
                            rowsPerPage={props.size}
                            page={props.page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={(_event, value) => props.pageChangedCallback(value)}
                            onChangeRowsPerPage={(event) => props.sizeChangedCallback(+event.target.value)}
                        />
                    </TableRow>
                </TableFooter>
            </Table>


        </Fragment>
    );
};

export default Datatablev2View;