import React, {Fragment, Component} from 'react';
import {FetchState} from "../../models/fetchState";
import {adalApiFetch} from "../../adalConfig";
import axios from "axios";
import {BookingAccount, Partner} from "../../models/masterData";
import {debounce} from "../../util/debounce";
import isNonEmptyString from "../../util/isNonEmptyString";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    TextField
} from "@material-ui/core";
import {FixedSizeList} from "react-window";

interface ContextData {
    state: FetchState;
    partners: {
        [id: string] : Partner
    };
    openModal?: (newCallback?: (id: string) => void) => void;
    newPartnerCallback?: (id: string) => void;
}

interface State extends ContextData {
    modalOpen: boolean;
    searchValue: string;
    partnerIdArray: string[];
    filteredPartnerIdArray: string[];
}

type Props = {
    children: any;
    fetchMethod?: any;
}

const initialState : ContextData = {
    state: "INIT",
    partners: {}
}


const PartnerStoreContext = React.createContext<ContextData>(initialState);

class PartnerProvider extends Component<Props, State> {


    constructor(props : Props) {
        super(props);

        this.state = {
            ...initialState,
            openModal: this.openModal,
            searchValue: "",
            partnerIdArray: [],
            filteredPartnerIdArray: [],
            modalOpen: false
        };

        let fetchMethod;

        if(props.fetchMethod) {
            fetchMethod = props.fetchMethod
        } else {
            fetchMethod = adalApiFetch(axios, "/api/md/partner/all", {});
        }

        fetchMethod
            .then(({data} : any) => this.setState({
                partners: data,
                state: "READY",
                partnerIdArray: Object.keys(data)}))
            .catch((error : any) => this.setState({state: "ERROR"}));
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevState.searchValue !== this.state.searchValue) {
            this.recalculateSearchArray();
        }
    }

    recalculateSearchArray = debounce(() => {
        if (isNonEmptyString(this.state.searchValue)) {

            const trimmedValue = this.state.searchValue.trim();

            let filteredIdArray = Object.values(this.state.partners)
                .filter((partner: Partner) => {
                    if (partner.applicantNumber === trimmedValue) {
                        return true;
                    } else if (partner.partnerName.includes(trimmedValue)) {
                        return true;
                    }
                })
                .map((object) => object.applicantNumber);

            this.setState({filteredPartnerIdArray: filteredIdArray});
        }
    }, 500);

    openModal = (callback?: (id: string) => void) => {
        console.log(callback);
        this.setState({modalOpen: true, newPartnerCallback: callback})
    }

    closeModal = () => {
        this.setState({modalOpen: false})
    }

    render() {

        const idArray = isNonEmptyString(this.state.searchValue) ? this.state.filteredPartnerIdArray : this.state.partnerIdArray;

        return (
            <PartnerStoreContext.Provider value={this.state}>
                <Fragment>
                    {this.props.children}
                    <Dialog onClose={this.closeModal} open={this.state.modalOpen}>
                        <DialogTitle>
                            Gesellschafter auswählen
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField
                                fullWidth
                                value={this.state.searchValue}
                                onChange={(e) => this.setState({searchValue: e.target.value})}
                                margin="normal"
                            />

                            <FixedSizeList height={400} width={520} itemSize={46}
                                           itemCount={idArray.length}>
                                {({style, index}) => (<ListItem button
                                                                style={style}
                                                                key={index}
                                                                onClick={() => {
                                                                    if (this.state.newPartnerCallback) {
                                                                        this.state.newPartnerCallback(idArray[index])
                                                                    }
                                                                    this.closeModal();
                                                                }}>
                                    <ListItemText
                                        secondary={this.state.partners[(idArray[index])] ? this.state.partners[(idArray[index])].applicantNumber : "---"}
                                        primary={this.state.partners[(idArray[index])] ? this.state.partners[(idArray[index])].partnerName : "Nicht vorhanden"}/>
                                </ListItem>)}
                            </FixedSizeList>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeModal} color="default">
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            </PartnerStoreContext.Provider>
        );
    }
}

const PartnerConsumer = PartnerStoreContext.Consumer;

export {PartnerProvider, PartnerConsumer};
