import React from 'react';
import {adalApiFetch} from "../../adalConfig";
import axios from "axios";
import {FetchState} from '../../models/fetchState';
import {debounce} from "../../util/debounce";
import isNonEmptyString from "../../util/isNonEmptyString";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    TextField
} from "@material-ui/core";
import {FixedSizeList} from "react-window";
import {CostCenter} from "../../models/masterData";

interface ContextData {
    state: FetchState;
    availableCostCenters: {[id: string] : CostCenter};
    openModal?: (newCallback?: (id: string) => void) => void;
    newCostCenterCallback?: (id: string) => void;
}

interface State extends ContextData {
    modalOpen: boolean;
    searchValue: string;
    costCenterIdArray: string[];
    filteredCostCenterIdArray: string[];
}

type Props = {
    children: any;
    fetchMethod?: any;
}

const initialContextData : ContextData = {availableCostCenters: {}, state: "INIT"};

const CostCenterStoreContext = React.createContext<ContextData>(initialContextData);

class CostCenterProvider extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state= {
            ...initialContextData,
            openModal: this.openModal,
            searchValue: "",
            costCenterIdArray: [],
            filteredCostCenterIdArray: [],
            modalOpen: false
        };

        let fetchMethod;

        if(props.fetchMethod) {
            fetchMethod = props.fetchMethod
        } else {
            fetchMethod = adalApiFetch(axios, "/api/md/costcenter/all", {});
        }

        fetchMethod
            .then(({data} : any) => this.setState({
                availableCostCenters : data,
                state: "READY",
                costCenterIdArray: Object.keys(data)
            }))
            .catch((error : any) => this.setState({state: "ERROR"}));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevState.searchValue !== this.state.searchValue) {
            this.recalculateSearchArray();
        }
    }

    recalculateSearchArray = debounce(() => {
        if (isNonEmptyString(this.state.searchValue)) {

            const trimmedValue = this.state.searchValue.trim();

            let filteredIdArray = Object.values(this.state.availableCostCenters)
                .filter((costCenter: CostCenter) => {
                    if (costCenter.id === trimmedValue) {
                        return true;
                    } else if (costCenter.title.includes(trimmedValue)) {
                        return true;
                    }
                })
                .map((object) => object.id);

            this.setState({filteredCostCenterIdArray: filteredIdArray});
        }
    }, 500);

    openModal = (callback?: (id: string) => void) => {
        this.setState({modalOpen: true, newCostCenterCallback: callback})
    }

    closeModal = () => {
        this.setState({modalOpen: false})
    }


    render() {

        const idArray = isNonEmptyString(this.state.searchValue) ? this.state.filteredCostCenterIdArray : this.state.costCenterIdArray;

        return (
            <CostCenterStoreContext.Provider value={this.state}>
                {this.props.children}
                <Dialog onClose={this.closeModal} aria-labelledby="customized-dialog-title" open={this.state.modalOpen}>
                    <DialogTitle id="customized-dialog-title">
                        Buchungskonto auswählen
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            fullWidth
                            value={this.state.searchValue}
                            onChange={(e) => this.setState({searchValue: e.target.value})}
                            margin="normal"
                        />

                        <FixedSizeList height={400} width={520} itemSize={46}
                                       itemCount={idArray.length}>
                            {({style, index}) => (<ListItem button
                                                            style={style}
                                                            key={index}
                                                            onClick={() => {
                                                                if (this.state.newCostCenterCallback) {
                                                                    this.state.newCostCenterCallback(idArray[index])
                                                                }
                                                                this.closeModal();
                                                            }}>
                                <ListItemText
                                    secondary={this.state.availableCostCenters[(idArray[index])] ? this.state.availableCostCenters[(idArray[index])].id : "---"}
                                    primary={this.state.availableCostCenters[(idArray[index])] ? this.state.availableCostCenters[(idArray[index])].title : "Nicht vorhanden"}/>
                            </ListItem>)}
                        </FixedSizeList>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeModal} color="default">
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </CostCenterStoreContext.Provider>
        );
    }
}

const CostCenterConsumer = CostCenterStoreContext.Consumer;

export {CostCenterProvider, CostCenterConsumer};
